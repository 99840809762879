import React from 'react';
import Svg from '../Svg';
export const SvgSkillsetModel = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M18.638 18.233c-.271-.503-1.35-.799-2.3-1.078M6.038 9.936c.255 1.174.519 1.789.351 2.26a2.5 2.5 0 01-.575.799.19.19 0 000 .28l.495.366c.216.136.52 2.5 1.35 2.484a9.752 9.752 0 002.571-.863.208.208 0 01.303.16l.24 1.15a1.733 1.733 0 01-1.78 1.501c-1.414-.056-2.803-.096-2.875 1.677"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M10.486 12.667a6.291 6.291 0 002.922 1.749 2.14 2.14 0 102.02-1.437c.487-1.486 1.685-6.14-1.597-8.097-2.603-1.597-4.974-1.054-6.444.263a4.145 4.145 0 00-.415.423A7.418 7.418 0 005 9.848l3.242-.127c.295.439.615.934.974 1.429"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M9.12 11.685s.28-1.341.974-.799c1.03.799 0 2.077 0 2.077"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <mask id="skillset-model_svg__a" maskUnits="userSpaceOnUse" x={0} y={0} width={23} height={23} fill="#000">
      <path fill="#fff" d="M0 0h23v23H0z" />
      <path d="M11 1a10 10 0 110 20 10 10 0 010-20z" />
    </mask>
    <path
      d="M11 0a1 1 0 100 2V0zM1 11H0h1zm10-9a1 1 0 100-2v2zm0 0a9 9 0 015 1.517l1.111-1.663A11 11 0 0011 0v2zm5 1.517a9 9 0 013.315 4.039l1.848-.766a11 11 0 00-4.052-4.936l-1.11 1.663zm3.315 4.039a9 9 0 01.512 5.2l1.962.39a11 11 0 00-.626-6.356l-1.848.766zm.512 5.2a9.001 9.001 0 01-2.463 4.608l1.414 1.414a11 11 0 003.01-5.632l-1.96-.39zm-2.463 4.608a9.001 9.001 0 01-4.608 2.463l.39 1.962a11 11 0 005.632-3.01l-1.414-1.415zm-4.608 2.463a9 9 0 01-5.2-.512l-.766 1.848a11 11 0 006.356.626l-.39-1.962zm-5.2-.512A9 9 0 013.516 16l-1.662 1.111a11 11 0 004.936 4.052l.766-1.848zM3.516 16A9 9 0 012 11H0a11 11 0 001.854 6.111l1.663-1.11zM2 11a9 9 0 012.636-6.364L3.222 3.222A11 11 0 000 11h2zm2.636-6.364A9 9 0 0111 2V0a11 11 0 00-7.778 3.222l1.414 1.414z"
      fill="currentColor"
      mask="url(#skillset-model_svg__a)"
    />
  </Svg>
);
SvgSkillsetModel.isIcon = true;
SvgSkillsetModel.defaultProps = {
  size: 24,
};
export default SvgSkillsetModel;
