export const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    // recurse
    else if (obj[key] == null || obj[key].length === 0) delete obj[key]; // delete
  });
};

export function removeTypename(variables) {
  return variables ? JSON.parse(JSON.stringify(variables), (k, v) => (k === '__typename' ? undefined : v)) : variables;
}

export function getPaginatedItemsFromQueryResult(key, data) {
  return data && data[key] ? data[key].items : [];
}

export function omit(obj: object, fields: string[]) {
  const shallowCopy = {
    ...obj,
  };

  for (const key of fields) {
    delete shallowCopy[key];
  }

  return shallowCopy;
}
