import { useApolloClient } from '@apollo/react-hooks';
import Auth from '@aws-amplify/auth';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { identify } from '../../analytics';
import config from '../../config/config';
import AUTH_QUERY from '../../graphql/queries/AuthQuery';
import {
  AuthCheckQuery,
  AuthCheckQuery_self_Client,
  AuthCheckQuery_self_Creator,
} from '../../graphql/queries/typings/AuthCheckQuery';
import createLogger from '../../utils/createLogger';
import buildNavItems, { getActiveNavItem, publicNavItems } from '../../routes/buildNavigationItems';
import ErrorScreen from '../../screens/ErrorScreen';

const logger = createLogger('Authenticator');

Auth.configure({
  identityPoolId: config.COGNITO_IDENTITY_POOL_ID,
  region: config.COGNITO_REGION,
  userPoolId: config.COGNITO_USER_POOL_ID,
  userPoolWebClientId: config.COGNITO_USER_POOL_WEB_ID,
  authenticationFlowType: config.COGNITO_FLOW_TYPE,
});

export const AuthContext = createContext<any>(null);
export const UserContext = createContext<any>(null);

const Authenticator: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<AuthCheckQuery_self_Client | AuthCheckQuery_self_Creator | null>(null);
  const apolloClient = useApolloClient();

  const isSuccessFromStripeHostedForms = location.search.indexOf('stripeResult=success') !== -1;

  const fetchUser = useCallback(async () => {
    const selfQuery = await apolloClient.query<AuthCheckQuery>({
      query: AUTH_QUERY,
    });
    const user = selfQuery.data?.self;
    // @ts-ignore
    setUser(user);
    return user;
  }, [apolloClient]);

  const checkAuth = useCallback(
    async (pathname, dest?) => {
      logger('checkAuth()');
      try {
        setLoading(true);
        await Auth.currentSession();
        await apolloClient.resetStore();
        const user = await fetchUser();
        identify(user?.id, {
          ...user,
        });
        logger('setUser()', user);
        setAuthenticated(true);
        setLoading(false);
        if (dest && dest !== '/app') {
          history.push(dest, { isSuccessFromStripeHostedForms });
          return;
        }

        const activeNavItem = getActiveNavItem(publicNavItems.concat(buildNavItems(user?.__typename)), pathname);

        // @ts-ignore
        if (
          !activeNavItem &&
          pathname.indexOf('app/creator') === -1 &&
          pathname.indexOf('app/post-a-job') === -1 &&
          pathname.indexOf('app/repost-a-job') === -1
        ) {
          const userType = user ? user.__typename : '';
          switch (userType) {
            case 'Client':
              history.push('/app/dashboard', {
                isSuccessFromStripeHostedForms,
              });
              break;
            case 'Creator':
              history.push('/app/job-board', {
                isSuccessFromStripeHostedForms,
              });
              break;
            default:
              break;
          }
        }

        return user;
      } catch (error) {
        logger('error: %s', error);
        if (pathname.indexOf('app/post-a-job') !== -1) {
          window.location.assign('/sign-up?dest=/app/post-a-job');
          return;
        }
        if (
          error &&
          typeof error.message === 'string' &&
          error.message.indexOf('Not Authorized to access self on type SelfResult') === -1
        ) {
          // console.error(error);
        }

        setLoading(false);

        const activeNavItem = getActiveNavItem(publicNavItems, pathname);
        console.log(activeNavItem);
        if (!activeNavItem && window.location.pathname.indexOf('sign-in') === -1) {
          const dest = `${window.location.pathname}${window.location.search}`;
          if (dest && dest.includes('/app/creator/')) {
            history.push(`/${dest.slice(5)}`);
            history.go(0);
          } else if (dest) {
            history.push(`/app/sign-in?dest=${dest}`);
          } else {
            history.push(`/app/sign-in`);
          }
        }
        return null;
      }
    },
    [apolloClient, fetchUser, history, isSuccessFromStripeHostedForms]
  );

  useEffect(() => {
    checkAuth(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccessFromStripeHostedForms) {
      logger('is from stripe');
      setTimeout(() => {
        fetchUser().then(user => {
          if (user) {
            // @ts-ignore
            setUser(user);
          }
        });
      }, 5000);
    }
  }, [fetchUser, isSuccessFromStripeHostedForms]);

  logger('render');

  if (loading) {
    return null;
    // // show full page loading indicator
    // return <div>loading</div>;
  }

  const handleSignOut = async () => {
    try {
      setLoading(true);
      await Auth.signOut({ global: true });
      setLoading(false);
      setUser(null);
      setAuthenticated(false);
      window.localStorage.clear();
      window.location.assign('/app/sign-in');
    } catch (error) {
      logger('error: %s', error);
      console.error(error);
      setLoading(false);
      window.location.assign('/app/sign-in');
    }
  };

  const handleSignIn = (username: string, password: string, dest?: string) => {
    // setLoading(true);
    return Auth.signIn(username + ''.toLowerCase(), password).then(() => {
      return checkAuth('', dest);
    });
  };

  const authContextState = {
    authenticated,
    signOut: handleSignOut,
    signIn: handleSignIn,
    requiresStripeUpdate:
      // @ts-ignore
      user?.financial?.payout?.identityStatus === 'UPDATE_REQUIRED',
    requiresPayoutUpdate:
      // @ts-ignore
      user?.financial?.payout?.status === 'UPDATE_REQUIRED' &&
      // @ts-ignore
      user?.financial?.payout?.identityStatus !== 'ONBOARDING_REQUIRED',
    requiresPayoutCreation:
      // @ts-ignore
      user?.financial?.payout?.status === 'ONBOARDING_REQUIRED' &&
      // @ts-ignore
      user?.financial?.payout?.identityStatus !== 'ONBOARDING_REQUIRED',
  };

  return (
    <AuthContext.Provider value={authContextState}>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </AuthContext.Provider>
  );
};

export const withUser = WrappedComponent => props => (
  <UserContext.Consumer>{value => <WrappedComponent {...props} user={value} />}</UserContext.Consumer>
);

type UserType = 'Creator' | 'Client';

export function withAuthorization(allowedType?: UserType) {
  return WrappedComponent => props => {
    WrappedComponent.displayName = 'WithAuthorization';

    return (
      <UserContext.Consumer>
        {user => {
          if (!user || (allowedType && user.__typename !== allowedType)) {
            return <ErrorScreen />;
          }

          return <WrappedComponent {...props} user={user} />;
        }}
      </UserContext.Consumer>
    );
  };
}

export default Authenticator;
