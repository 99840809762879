import gql from 'graphql-tag';

export default gql`
  mutation PresignedFileUploadRequest($fileNames: [String]!) {
    presignedFileUploadRequestWithContentType(fileNames: $fileNames) {
      key
      fileName
      contentType
      uploadUrl
    }
  }
`;
