import styled from 'styled-components/macro';
import Button from '../button';
import theme from '../theme';

const OutlineButton = styled(Button)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.blue6};
  border-color: ${props => props.theme.colors.blue6};
  text-shadow: none;

  &:disabled {
    cursor: default;
    border-color: #ebebeb;
    background-color: ${props => props.theme.colors.white};
    color: #d9d9d9;
    &:hover {
      border-color: #ebebeb;
      background-color: ${props => props.theme.colors.white};
      color: #d9d9d9;
    }
  }

  &:hover {
    border-color: ${props => props.theme.colors.blue6};
    background-color: #f1f6f8;
  }

  &:focus {
    background-color: #f1f6f8;
    border-color: #467a87;
  }

  &:active {
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.blue};
  }
`;

OutlineButton.defaultProps = {
  theme,
};

export default OutlineButton;
