import React from 'react';

const BookmarkIcon = props => (
  <span className="icon icon--BookmarkIcon">
    <svg viewBox="0 0 25 27" fill="none" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 25.872V1h18v25l-9-7.76-9 7.632z"
        fill="#fff"
        stroke="#3F3F3F"
        strokeOpacity={0.2}
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default BookmarkIcon;
