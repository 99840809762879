import { useMutation } from '@apollo/react-hooks';
import { Alert, Avatar, Button, notification } from 'antd';
import moment from 'moment';
import React, { FC, useContext, useState } from 'react';
import { track } from '../analytics';
import Icon from '../components-core/icon';
import IconText from '../components-core/icon-text';
import DraftEditorWithForwardedRef from '../components-core/wysiwyg/Wysiwyg';
import { UserContext } from '../components/Authenticator';
import PageHeader from '../components/PageHeader/PageHeader';
import PageLoadIndicator from '../components/PageLoadIndicator';
import SkillIcon from '../components/SkillIcon';
import cloudinaryTransformations from '../constants/cloudinaryTransformations';
import useJobQuery from '../hooks/useJobQuery';
import PortfolioMediaItem from '../PortfolioMediaItem';
import { buildCloudinaryImageUrl } from '../utils/cloudinaryUtils';
import { jobStyleTranslator } from '../utils/jobUtils';
import JobProposalForm from './JobBoardScreen/components/JobProposalForm';
import JOB_BID_MUTATION from './JobBoardScreen/mutations/JobBidSendMutation';

type CreatorBidSubmitScreenProps = import('react-router').RouteComponentProps<{
  jobId: string;
}>;

const CreatorBidSubmitScreen: FC<CreatorBidSubmitScreenProps> = ({ history, match }) => {
  const user = useContext(UserContext);
  const [showPreview, setShowPreview] = useState(true);
  const [bidPayload, setBidPayload] = useState<any>(null);
  const { jobId } = match.params;

  const [jobBidSubmit, jobBidSubmitState] = useMutation(JOB_BID_MUTATION);

  const jobPostQuery = useJobQuery({
    variables: {
      id: jobId,
    },
    skip: !jobId,
  });

  const jobPost = jobPostQuery.data?.jobPost;
  const locality = jobPost?.details?.location?.locality;
  const region = jobPost?.details?.location?.region;
  const country = jobPost?.details?.location?.country;
  const dueDate = jobPost?.details?.schedule?.dueDate ?? '';
  const dueDateFormatted = moment(new Date(parseInt(dueDate, 10))).format('MMMM Do, YYYY');

  const locationString = locality ? `${locality}${region ? `, ${region}` : `, ${country}`}` : 'Remote';

  const budgetRange = jobPost?.details?.budget?.range;

  const [submissionError, setSubmissionError] = useState<any>(null);
  const budget = jobPost?.details?.budget?.range?.min && jobPost?.details?.budget?.range?.max
    ? `$${(budgetRange.min / 100).toLocaleString()} - $${(budgetRange.max / 100).toLocaleString()}`
    : 'Needs Estimate';

  const handleSendBid = () => {
    const variables = {
      jobId: jobPost.id,
      input: bidPayload,
    };
    jobBidSubmit({
      variables,
    })
      .then(mutationResponse => {
        if (mutationResponse.data && mutationResponse.data.jobPostProposalCreate) {
          track('Bid Submitted', variables);
          notification.success({ message: 'Job Bid Sent!' });
          history.push('/app/job-board');
        }
      })
      .catch(error => {
        console.error(error);
        setShowPreview(false);
        setSubmissionError(error);
      });
  };

  if (!jobPost) {
    return <PageLoadIndicator />;
  }

  return (
    <>
      <PageHeader>
        <div className="flex items-center">
          <Button type="link" onClick={() => history.goBack()} style={{ paddingLeft: 0 }}>
            <Icon name="ArrowRight" style={{ transform: 'rotate(180deg)', color: 'black' }} />
          </Button>
          <div className="font-bold pl-2 text-2xl">Send a Bid</div>
        </div>
      </PageHeader>

      <div className="container mx-auto p-3 md:p-6">
        {submissionError && (
          <Alert type="error" className="mb-6" showIcon closable message={submissionError?.message} />
        )}

        {showPreview && bidPayload !== null ? (
          <div>
            <div className="text-center text-xl mb-6">Here’s a preview of what your bid will look like.</div>

            <div
              style={{ maxWidth: 528 }}
              className="mx-auto mb-3 bg-whiteSmoke border border-borderGray shadow-lg rounded"
            >
              <div className="flex border-b border-borderGray p-3 md:p-6">
                <Avatar
                  size={40}
                  src={buildCloudinaryImageUrl(user?.avatar?.publicId, cloudinaryTransformations.avatar)}
                  style={{
                    border: '2px solid white',
                    minWidth: 40,
                  }}
                />
                <div className="pl-3 mb-3">
                  <div className="font-bold text-grayDark mb-1">{user.name}</div>
                  <div className="text-sm mb-3">{bidPayload?.headline}</div>

                  <IconText icon={<Icon name="Location" size="1em" />}>
                    {user?.location?.locality}, {user?.location?.region}
                  </IconText>
                  <IconText icon={<Icon name="DollarCircle" size="1em" />}>
                    Bid: <span className="font-bold">${Number(bidPayload?.amount * 0.01).toLocaleString()}</span>
                  </IconText>
                  <IconText icon={<Icon name="Image" size="1em" />}>
                    Assets: <span className="font-bold">{bidPayload?.deliverables[0]?.quantity}</span>
                  </IconText>
                </div>
              </div>

              <div className="bg-whiteApple p-3 md:p-6 border-b border-borderGray">
                <div className="font-bold text-grayDark mb-1">Bid Letter</div>
                <div className="text-sm mb-3">{bidPayload?.introduction}</div>
              </div>

              <div className="p-3 md:p-6 border-b border-borderGray">
                <div className="font-bold text-grayDark mb-3">Relevant Media</div>
                <div className="grid grid-cols-3 gap-4">
                  {(bidPayload?.media ?? []).map(({ type, publicId }, i) => (
                    <div key={i}>
                      <PortfolioMediaItem type={type} publicId={publicId} />
                      {/*<img*/}
                      {/*  alt={publicId}*/}
                      {/*  className="rounded"*/}
                      {/*  src={buildCloudinaryImageUrl(*/}
                      {/*    publicId,*/}
                      {/*    cloudinaryTransformations.portfolioThumbnail*/}
                      {/*  )}*/}
                      {/*/>*/}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex p-3 md:p-6 pl-3 bg-whiteApple justify-between">
                <Button
                  type="link"
                  onClick={() => {
                    setShowPreview(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  type="primary"
                  className="uppercase font-bold px-6"
                  loading={jobBidSubmitState.loading}
                  disabled={jobBidSubmitState.loading}
                  onClick={handleSendBid}
                >
                  <span className="font-bold uppercase px-6">Send Bid</span>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-min gap-6">
            <div>
              <div className="font-bold uppercase text-xs text-gray-900 text-opacity-50 mb-3">Your bid</div>
              <JobProposalForm
                bidPayload={bidPayload}
                jobPost={jobPost}
                onShowPreview={values => {
                  setBidPayload({
                    ...values,
                  });
                  setShowPreview(true);
                }}
              />
            </div>
            <div style={{ minWidth: 320 }}>
              <div className="font-bold uppercase text-xs text-gray-900 text-opacity-50 mb-3">Job Details</div>
              <div className="bg-whiteSmoke border border-borderGray overflow-hidden rounded p-3 md:p-6 space-y-4 text-black text-opacity-75">
                <div className="text-xl font-extrabold">{jobPost?.title}</div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="font-bold">Looking for</div>
                    <div className="flex items-center">
                      <SkillIcon skill={jobPost?.skill} />
                      <span className="pl-2 capitalize">{(jobPost?.skill ?? '').toLowerCase()}</span>
                    </div>
                  </div>
                  <div>
                    <div className="font-bold">Style</div>

                    {(jobStyleTranslator(jobPost?.details?.styles, false) || []).map(({ label }) => label).join(', ')}
                  </div>
                  <div>
                    <div className="font-bold">Budget</div>
                    {budget}
                  </div>
                  <div>
                    <div className="font-bold">Due Date</div>
                    {dueDateFormatted}
                  </div>
                  <div>
                    <div className="font-bold">Location</div>
                    {locationString}
                  </div>
                </div>
                <div>
                  <div className="font-bold">Description</div>
                  <DraftEditorWithForwardedRef value={jobPost.description} readOnly />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreatorBidSubmitScreen;
