import React from 'react';
import Svg from '../Svg';
export const SvgInfo = ({ size, ...props }) => (
  <Svg viewBox="0 0 1024 1024" width={size} height={size} {...props}>
    <path
      d="M448 224a64 64 0 10128 0 64 64 0 10-128 0zm96 168h-64c-4.4 0-8 3.6-8 8v464c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V400c0-4.4-3.6-8-8-8z"
      fill="currentColor"
    />
  </Svg>
);
SvgInfo.isIcon = true;
SvgInfo.defaultProps = {
  size: 24,
};
export default SvgInfo;
