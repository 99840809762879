import gql from 'graphql-tag';

export const JobPostGuidelinesFragment = gql`
  fragment JobPostGuidelinesFragment on JobPostGuidelines {
    do
    dont
  }
`;

export default JobPostGuidelinesFragment;
