import React, { FC, forwardRef } from 'react';
import styled from 'styled-components/macro';
import { color, flexbox, layout, space, shadow, typography, width } from 'styled-system';
import Flex from '../flex';
import Icon from '../icon';
import theme from '../theme';
import { ButtonProps } from '../types';

const shape = (props: ButtonProps) =>
  props.shape === 'circle'
    ? {
        padding: 0,
        borderRadius: 9999,
        width: size(props).height,
      }
    : {};

const size = (props: ButtonProps) => {
  switch (props.size) {
    case 'small':
      return {
        fontSize: `${props.theme!.fontSizes[0]}px`,
        height: props.theme!.buttonSizes.small,
      };
    case 'medium':
      return {
        fontSize: `${props.theme!.fontSizes[1]}px`,
        height: props.theme!.buttonSizes.medium,
      };
    case 'large':
      return {
        fontSize: `${props.theme!.fontSizes[2]}px`,
        height: props.theme!.buttonSizes.large,
      };
    default:
      return {
        fontSize: `${props.theme!.fontSizes[1]}px`,
        height: props.theme!.buttonSizes.medium,
      };
  }
};

export const StyledButton = styled.button<ButtonProps>`
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: inherit;
  line-height: 1;
  cursor: pointer;
  border-radius: ${p => p.theme.radii[2]}px;
  padding: 0 15px;
  transition: background-color .3s ease, 
  opacity .3s ease, 
  box-shadow .3s ease, 
  border-color .3s ease;

  border: 1px solid ${props => props.theme.colors.blue};
  background-color: ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.white};
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);

  font-weight: ${props => props.theme.fontWeights.bold};
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
  
  & > * {
    pointer-events: none;
  }

  &:disabled {
    cursor: default;
    background-color: #ebebeb;
    border-color: #ebebeb;
    &:hover {
      background-color: #ebebeb;
      border-color: #ebebeb;
    }
  }

  &:hover {
    border-color: #90bdcf;
    background-color: #90bdcf;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    background-color: #5295af;
    border-color: #5295af;
  }

  &:active {
    background-color: #5295af;
    border-color: #5295af;
  }

  ${flexbox}
  ${color}
  ${width}
  ${size}
  ${shape}
  ${space}
  ${layout}
  ${typography}
  ${shadow}
`;

const defaultProps = {
  theme,
};

function ButtonBase(props: ButtonProps) {
  // @ts-ignore
  const { forwardedRef, loading, children, icon, href, title, ...rest } = props;
  const extras = {
    ...(href ? { as: 'a', href, title } : {}),
  };

  if (icon) {
    return (
      // @ts-ignore
      <StyledButton ref={forwardedRef} {...extras} {...rest}>
        <Flex alignItems="center" flex={1}>
          <Icon name={icon} size="1em" mr={children ? 2 : 0} css={{ flex: 1 }} />
          {children}
        </Flex>
      </StyledButton>
    );
  }

  return (
    // @ts-ignore
    <StyledButton ref={forwardedRef} {...extras} {...rest}>
      {loading ? <Icon name="Spinner" /> : children}
    </StyledButton>
  );
}

ButtonBase.defaultProps = defaultProps;

const Button: FC<ButtonProps> = forwardRef((props, ref) => {
  // @ts-ignore
  return <ButtonBase {...props} forwardedRef={ref} />;
});

Button.displayName = 'Button';

export default Button;
