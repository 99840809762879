import React from 'react';
import Svg from '../Svg';
export const SvgArrowRight = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M1 8.105h13.023M8.326 14.21l6.034-6.035a.1.1 0 000-.141L8.326 2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
SvgArrowRight.isIcon = true;
SvgArrowRight.defaultProps = {
  size: 24,
};
export default SvgArrowRight;
