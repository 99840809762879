import { ApolloProvider } from '@apollo/react-hooks';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { initAnalytics } from './analytics';
import ThemeProvider from './components-core/theme-provider/ThemeProvider';
import AnnouncementProvider from './components/Announcements/AnnouncmentContext';
import Authenticator from './components/Authenticator';
import ErrorBoundary from './components/ErrorBoundry';
import Layout from './components/Layout';
import initApollo from './initApollo';
import registerServiceWorker from './registerServiceWorker';
import AppRoutes from './routes/AppRoutes';
import StreamProvider from './screens/MessagesScreen/components/StreamProvider';

initAnalytics();
registerServiceWorker()

const apolloClient = initApollo();
const App = () => (
  <ErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider>
        <Router>
          <Authenticator>
            <AnnouncementProvider>
              <StreamProvider>
                <Layout>
                  <AppRoutes />
                </Layout>
              </StreamProvider>
            </AnnouncementProvider>
          </Authenticator>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  </ErrorBoundary>
);


export default App;
