import React from 'react';
import Svg from '../Svg';
export const SvgCheckCircle = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <circle cx={7} cy={7} r={6.5} stroke="currentColor" />
    <g clipPath="url(#check-circle_svg__clip0)">
      <path d="M9.567 5.367L6.3 9.1 4.9 7.7" stroke="currentColor" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="check-circle_svg__clip0">
        <path fill="#fff" transform="translate(3.5 3.5)" d="M0 0h7v7H0z" />
      </clipPath>
    </defs>
  </Svg>
);
SvgCheckCircle.isIcon = true;
SvgCheckCircle.defaultProps = {
  size: 24,
};
export default SvgCheckCircle;
