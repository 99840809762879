import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import truncate from 'lodash.truncate';
import React from 'react';
import { shortenLargeNumber } from './numberUtils';

export function skillToHumanReadable(name, plural = false) {
  // debug('skillToHumanReadable(%s)', name);
  let skill = '';
  if (name === 'MAKEUP_ARTIST') {
    skill = 'Makeup Artist';
  } else if (name === 'MODEL') {
    skill = 'Model';
  } else if (name === 'PHOTOGRAPHER') {
    skill = 'Photographer';
  } else if (name === 'STYLIST') {
    skill = 'Stylist';
  } else if (name === 'VIDEOGRAPHER') {
    skill = 'Videographer';
  }
  if (plural) {
    skill = `${skill}s`;
  }
  return skill;
}

export function renderSkills(creator, lineBreak = false) {
  if (!creator) {
    return null;
  }
  // debug('renderSkills(%O, %b)', creator, lineBreak);
  return (
    <React.Fragment>
      {creator.skill && <strong>{skillToHumanReadable(creator.skill.name)}</strong>}
      {creator.otherSkills && (
        <React.Fragment>
          {Array.isArray(creator.otherSkills) && (
            <React.Fragment>
              {creator.otherSkills.length > 0 && <React.Fragment>, {lineBreak && <br />}</React.Fragment>}
              {creator.otherSkills.map(skill => (skill ? skillToHumanReadable(skill.name) : '')).join(' & ')}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export function renderLocation(creator) {
  // debug('renderLocation(%O)', creator);
  return creator
    ? creator.location && creator.location.locality && (
        <span className="inline-flex items-center">
          <EnvironmentOutlined />{' '}
          <span className="pl-1">
            {truncate(`${creator.location.locality}${creator.location.region ? `, ${creator.location.region}` : ''}`, {
              length: 22,
            })}
          </span>
        </span>
      )
    : null;
}

export function renderFollowerCount(creator) {
  // debug('renderFollowerCount(%O)', creator);
  return creator
    ? typeof creator.instagramProfile !== 'undefined' &&
        creator.instagramProfile.counts &&
        creator.instagramProfile.counts.followers && (
          <span style={{}}>
            <UserOutlined /> {shortenLargeNumber(creator.instagramProfile.counts.followers)}
          </span>
        )
    : null;
}

export function creatorIsModel(creator) {
  // debug('creatorIsModel(%O)', creator);
  if (!creator || (creator && !creator.skill)) {
    return false;
  }
  return (
    creator.skill.name === 'MODEL' ||
    (creator.otherSkills.length > 0 && creator.otherSkills.findIndex(item => item.name === 'MODEL') !== -1)
  );
}
