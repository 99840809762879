const appStage = process.env.REACT_APP_STAGE || process.env.APP_STAGE;
const appEnv = process.env.NODE_ENV;

function determineHost() {
  if (appStage === 'staging') {
    return 'staging.thehhub.com';
  }
  return appEnv === 'production' ? 'thehhub.com' : 'localhost';
}

function determineCookieHost() {
  if (appStage === 'staging') {
    return '.thehhub.com';
  }
  return appEnv === 'production' ? '.thehhub.com' : 'localhost';
}

function determineAppUrl() {
  // return '/app';
  if (process.env.PUBLIC_URL) {
    return process.env.PUBLIC_URL;
  }

  if (appStage === 'staging') {
    return 'https://staging.thehhub.com/app';
  }
  return appEnv === 'production' ? 'https://thehhub.com/app' : 'http://localhost:5001';
}

function determineWebUrl() {
  if (appStage === 'staging') {
    return 'https://staging.thehhub.com';
  }
  return appEnv === 'production' ? 'https://thehhub.com' : 'http://localhost:3000';
}

function determineLogLevel() {
  if (appStage === 'production') {
    return 'ERROR';
  }
  return 'DEBUG';
}

function determineAppSyncUrlConfig() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'https://staging.thehhub.com/graphql';
  }
  return 'https://thehhub.com/graphql';
}

function determinePublicAppSyncUrl() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'https://staging.thehhub.com/graphql-public';
  }
  return 'https://thehhub.com/graphql-public';
}

function determinePublicAppSyncKey() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 's1WFO4psyQ5wYioBKGhOe98gwxJ2HcVZ9eiVmShS';
  }
  return 'VoqVfSQ5JX7WScF3E87aH6JAIZB5xcQl2DtgLZDU';
}

function getCoginitoIdentityPoolId() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'us-east-1:5bd23d1f-aa87-4909-a56c-d36a6a79feab';
  }
  return 'us-east-1:c68e2476-fcf0-4f06-bd0c-18d292007fb6';
}

function getCoginitoUserPoolId() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'us-east-1_jyqBb2v3B';
  }
  return 'us-east-1_ADqtrHrPD';
}

function getCoginitoWebPoolId() {
  if (appStage === 'staging' || appEnv === 'development') {
    return '2j12g3h6go8euee4lj5hqarmj8';
  }
  return '7p3q5iqfb6ol7dm6nd1olmsrqf';
}

function getLayerAppId() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'layer:///apps/staging/3a664d20-cfb1-11e7-a7fd-c757ffbc11ed';
  }
  return 'layer:///apps/production/3a6654e6-cfb1-11e7-a7fd-8ba1b0116a6f';
}

function getStripeApiKey() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'pk_test_DSJjJtJgulSZ0R7MNPpOqyAF';
  }
  return 'pk_live_WdZl2ZKnJZoZF08HUIsNhfTI';
}

function getSegmentId() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'TUpEBlJZVJyFcZtIEWI1Q8uCZDPoOUA3';
  }
  return 'OhpWHkun04nDe9polHgbyEx9Z5zy8n7Q';
}

function getLogRocketId() {
  if (appStage === 'staging') {
    return 'dvrpb0/the-h-hub-staging';
  }
  if (appStage === 'production') {
    return 'dvrpb0/the-h-hub-production';
  }
  return 'dvrpb0/the-h-hub-dev';
}

function getWordpressUrl() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'https://staging.thehhub.com';
  }
  return 'https://thehhub.com';
}

function getStreamApiKey() {
  if (appStage === 'staging' || appEnv === 'development') {
    // return 'bshsbnncpydb';
    // return 'qk4nn7rpcn75'; // public stream app
    // return 'uc7pbh3dp387'; // jchild3rs test w/ no auth
    return 'bbfcbm2vufy9';
  }
  return 'ucafkgs8ehuj';
}
function getSupportId() {
  if (appStage === 'staging' || appEnv === 'development') {
    return 'cre-53955'; // jchilders62@gmail.com/123123
  }
  return 'cre-6495'; //
}

export default {
  APP_URL: determineAppUrl(),
  APP_STAGE: appStage,
  APPLE_APP_ID: 'WMK2P2JB8K.com.thehhub.app',
  AUTH_GUEST_EMAIL: 'guest@thehhub.com',
  AUTH_GUEST_PASSWORD: 'thehhub',
  AWS_APPSYNC_GRAPHQL_APP_URL: determineAppSyncUrlConfig(),
  AWS_APPSYNC_GRAPHQL_PUBLIC_KEY: determinePublicAppSyncKey(),
  AWS_APPSYNC_GRAPHQL_PUBLIC_URL: determinePublicAppSyncUrl(),
  CLOUDINARY_API_UPLOAD_PRESET: 'ewxvv8jz',
  CLOUDINARY_API_UPLOAD_URL: 'https://api.cloudinary.com/v1_1/dfifzuyto/image/upload',
  CLOUDINARY_CLOUD_NAME: 'dfifzuyto',
  CLOUDINARY_SDK_SCRIPT_URL: 'https://widget.cloudinary.com/global/all.js',
  COGNITO_FLOW_TYPE: 'USER_PASSWORD_AUTH',
  COGNITO_IDENTITY_POOL_ID: getCoginitoIdentityPoolId(),
  COGNITO_MANDATORY_SIGN_IN: false,
  COGNITO_REGION: 'us-east-1',
  COGNITO_USER_POOL_ID: getCoginitoUserPoolId(),
  COGNITO_USER_POOL_WEB_ID: getCoginitoWebPoolId(),
  COOKIE_HOST: determineCookieHost(),
  GOOGLE_ANALYTICS_ID: '96921326-1',
  GOOGLE_MAPS_API_KEY: 'AIzaSyC7-S2WMd6FZFN-BdERgWpLTone_UmPBoQ',
  HOST: determineHost(),
  FILE_MAX_ATTACHMENT_SIZE: 10000000,
  FILE_UPLOAD_IMAGE_ACCEPT: 'image/*',
  INSTAGRAM_AUTH_ENDPOINT: 'https://api.instagram.com/oauth/authorize',
  INSTAGRAM_CLIENT_ID: 'e708f7b02dcb42dbabf086326b8e75e6',
  LAYER_APP_ID: getLayerAppId(),
  LOG_LEVEL: determineLogLevel(),
  LOGROCKET_ID: getLogRocketId(),
  SEGMENT_APP_ID: getSegmentId(),
  SENTRY_DSN: 'https://af3e8beddc0642f5852700102d98418b@sentry.io/1538652',
  STREAM_API_KEY: getStreamApiKey(),
  STRIPE_API_KEY: getStripeApiKey(),
  SUPPORT_LAYER_ID: 'cre-bf7aqi7jyqbxv1g',
  SUPPORT_USER_ID: getSupportId(),
  WEB_URL: determineWebUrl(),
  WP_URL: getWordpressUrl(),
};
