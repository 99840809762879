import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { animated, useChain, useTransition } from 'react-spring';
import styled from 'styled-components/macro';
import Absolute from '../../../../components-core/absolute';
import BackgroundImage from '../../../../components-core/background-image';
import Box from '../../../../components-core/box';
import Button from '../../../../components-core/button';
import Flex from '../../../../components-core/flex';
import Text from '../../../../components-core/text';
import TextButton from '../../../../components-core/text-button';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import useSelfQuery from '../../../../hooks/useSelfQuery';

const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
`;

const Img = styled.img`
  max-width: 100%;
  margin: 0 auto;
  height: auto;
`;

const Modal = styled(animated.div)`
  position: absolute;
  z-index: 9999;
  overflow: hidden;

  @media (max-width: 764px) {
    width: 100%;
  }
`;

const Arrow = props => (
  <svg width={12} height={11} fill="none" style={{ marginLeft: 7, marginTop: 0 }} {...props}>
    <path
      d="M1 5.5h9.697M6.455 10l4.473-4.429a.1.1 0 000-.142L6.455 1"
      stroke="#FEFEFE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const STEPS = [
  {
    title: 'Welcome to the Job Board!',
    subTitle: 'Let’s check out how everything works.',
    subTitleWidth: 317, // these designs are killing me
    artSize: [432, 288],
  },
  {
    title: 'Post a Job',
    subTitle:
      'Here’s where you build your creative project. Select the type of creator, describe your job and include your budget. ',
    subTitleWidth: 392,
    artSize: [432, 270],
  },
  {
    title: 'Search for Creators',
    subTitle: 'Search, discover and invite the best creators to your project.',
    subTitleWidth: 346,
    artSize: [432, 288],
  },
  {
    title: 'Get incredible, high-quality images.',
    subTitle: 'Receive work that is better, faster and more affordable. ',
    subTitleWidth: 310,
    artSize: [432, 288],
  },
  {
    title: 'Yeah, it’s that simple!',
    subTitle: 'Now, let’s get you started. Post a job now.',
    subTitleWidth: 384,
    artSize: [432, 306.5],
  },
].map((step, i) => ({
  ...step,
  bg: require(`../../../../assets/images/onboarding/bg-${i + 1}.png`),
  art: require(`../../../../assets/images/onboarding/art-${i + 1}.png`),
}));

const PostJobFormInterstitial = () => {
  const history = useHistory();
  const selfQuery = useSelfQuery();
  const jobsCount = selfQuery.data?.self?.jobsCount ?? 0;
  const hasPreviousJobs = jobsCount > 0;
  const [currentStep, setCurrentStep] = useState(0);

  const [hasSeen, setHasSeen] = useLocalStorage('__h-seen-post-job-interstitial', hasPreviousJobs);

  // fade in modal's overlay
  const overlayRef = useRef<any>();
  const overlayTransitions = useTransition(!hasPreviousJobs && !hasSeen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    ref: overlayRef,
  });

  // slide up modal container
  const modalContainerRef = useRef<any>();
  const modalContainerX = window.innerWidth > 600 ? 'calc(50vw - 265px)' : '0';
  const modalContainerY = '10vh';
  const modalContainerTransitions = useTransition(!hasPreviousJobs && !hasSeen, null, {
    from: { transform: `translate3d(${modalContainerX},100vh,0)` },
    enter: {
      transform: `translate3d(${modalContainerX},${modalContainerY},0)`,
    },
    leave: { transform: `translate3d(${modalContainerX},100vh,0)` },
    ref: modalContainerRef,
  });

  // fade in-between steps
  // const stepTransitions = useTransition(currentStep, p => p, {
  //   from: { position: 'relative', },
  //   enter: {  },
  //   leave: {  },
  //   immediate: true,
  // });

  useChain([overlayRef, modalContainerRef], [1, 1.5]);

  const isLastStep = currentStep === STEPS.length - 1;

  const handleFinished = useCallback(() => {
    setHasSeen(true);
    if (window.location.pathname.startsWith('/app/post-a-job')) {
      return;
    }
    history.push('/app/post-a-job');
  }, [history, setHasSeen]);

  const handleNextStep = useCallback(() => {
    if (isLastStep) {
      handleFinished();
    } else {
      setCurrentStep(state => state + 1);
    }
  }, [isLastStep, handleFinished]);

  return (
    <animated.div>
      {/* {STEPS.map(step => (
        <>
          <link rel="preload" href={step.bg} as="image" type="image/png" />
          <link rel="preload" href={step.art} as="image" type="image/png" />
        </>
      ))} */}
      {overlayTransitions.map(
        ({ item, key, props }) =>
          item && (
            <Overlay key={key} style={props}>
              {modalContainerTransitions.map(
                ({ item, key, props }) =>
                  item && (
                    <Modal key={key} style={props}>
                      <Box width={['auto', 'auto', 'auto', 540]} borderRadius={2} overflow="hidden" mx={3}>
                        {/*{stepTransitions.map(({ item, key, props }) => (*/}
                        {/*  <animated.div style={props} key={key}>*/}
                        <BackgroundImage
                          image={STEPS[currentStep].bg}
                          width={['auto', 'auto', 'auto', 540]}
                          height={415}
                          bg="white"
                        >
                          <Box width={['auto', 'auto', 'auto', 540]} pt={50}>
                            <Text textAlign="center">
                              <Text fontSize="24px" fontWeight={800} color={'#3f3f3f'}>
                                {STEPS[currentStep].title}
                              </Text>
                              <Text
                                fontSize={['16px', '17px', '17px', '19px']}
                                px={[3, 3, 3, 0]}
                                lineHeight="120%"
                                my="0"
                                mx="auto"
                                maxWidth={STEPS[currentStep].subTitleWidth + 10}
                                color={'#3f3f3f'}
                              >
                                {STEPS[currentStep].subTitle}
                              </Text>
                            </Text>
                          </Box>
                          <Flex
                            width={['auto', 'auto', 'auto', 540]}
                            height={415}
                            alignItems="flex-end"
                            justifyContent="center"
                          >
                            <Absolute bottom={67}>
                              <Img
                                alt={STEPS[currentStep].title}
                                src={STEPS[currentStep].art}
                                width={STEPS[currentStep].artSize[0]}
                                height={STEPS[currentStep].artSize[1]}
                              />
                            </Absolute>
                          </Flex>
                        </BackgroundImage>
                        {/*</animated.div>*/}
                        {/*))}*/}
                        <Box width={['auto', 'auto', 'auto', 540]} bg={'white'} px={[3, 3, 3, '36px']}>
                          <Flex height={73} justifyContent="space-between" alignItems="center">
                            <TextButton
                              caps
                              color="blue"
                              fontSize="12px"
                              bold
                              onClick={handleFinished}
                              style={{
                                opacity: isLastStep ? 0 : 1,
                              }}
                            >
                              Skip This
                            </TextButton>
                            <Button width={isLastStep ? 140 : 108} onClick={handleNextStep}>
                              {isLastStep ? 'Post a Job' : 'Next'}
                              <Arrow />
                            </Button>
                          </Flex>
                        </Box>
                      </Box>
                    </Modal>
                  )
              )}
            </Overlay>
          )
      )}
    </animated.div>
  );
};

export default PostJobFormInterstitial;
