// @ts-ignore
import createDOMForm from 'rc-form/lib/createDOMForm';
// @ts-ignore
import createFormField from 'rc-form/lib/createFormField';
import React, { Component } from 'react';
import Box from '../box';
import { FormComponentProps, FormCreateOption, FormProps, FormWrappedProps } from '../types';
import { FIELD_DATA_PROP, FIELD_META_PROP } from './constants';
import FormItem from './FormItem';

const FormEl = Box.withComponent('form');

class Form extends Component<FormProps> {
  static createFormField = createFormField;

  static create = <TOwnProps extends FormComponentProps>(
    options: FormCreateOption<TOwnProps> = {}
  ): FormWrappedProps<TOwnProps> =>
    createDOMForm({
      fieldNameProp: 'id',
      fieldMetaProp: FIELD_META_PROP,
      fieldDataProp: FIELD_DATA_PROP,
      validateMessages: {
        required(fieldName: string) {
          return `"${fieldName}" is required.`;
        },
      },
      ...options,
    });

  static getCleaveValueFromEvent = (event: any) => event.target.rawValue || event.target.value;

  static Item = FormItem;

  render() {
    const formProps = this.props;
    // @ts-ignore
    return <FormEl m={0} {...formProps} />;
  }
}

export default Form;
