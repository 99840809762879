import React from 'react';
import Svg from '../Svg';
export const SvgImageAdd = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 30 30" fill="none" {...props}>
    <path
      clipRule="evenodd"
      d="M10.772 12.969a2.691 2.691 0 11-5.384 0 2.693 2.693 0 015.384 0z"
      stroke="currentColor"
    />
    <path
      d="M23.336 18.125v2.836a7.257 7.257 0 01-7.264 7.264H8.463A7.257 7.257 0 011.2 20.96v-7.61a7.257 7.257 0 017.264-7.263h3.805M28.217 6H17.615M22.8 1.314v10.104"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.344 24.754l6.312-4.957 3.15 2.381 7.228-7.34 4.177 3.175"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
SvgImageAdd.isIcon = true;
SvgImageAdd.defaultProps = {
  size: 24,
};
export default SvgImageAdd;
