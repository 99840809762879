import React from 'react';
import Svg from '../Svg';
export const SvgLock = ({ size, ...props }) => (
  <Svg viewBox="0 0 20 20" fill="none" width={size} height={size} {...props}>
    <path
      d="M1.5 16V9A3.5 3.5 0 015 5.5h10A3.5 3.5 0 0118.5 9v7a3.5 3.5 0 01-3.5 3.5H5A3.5 3.5 0 011.5 16z"
      stroke="currentColor"
    />
    <path d="M14.8 5.91C14.8 3.197 12.65 1 10 1 7.349 1 5.2 3.198 5.2 5.91" stroke="currentColor" />
    <path d="M8.71 12.432a1.75 1.75 0 112.581 0" stroke="currentColor" strokeLinecap="round" />
    <path
      d="M9.275 12.563a.5.5 0 00-1 0h1zm2.45 0a.5.5 0 00-1 0h1zm-1 0V14.4h1V12.561h-1zM9.275 14.4V12.561h-1V14.4h1zm.725.725a.725.725 0 01-.725-.725h-1c0 .953.772 1.725 1.725 1.725v-1zm.725-.725c0 .4-.325.725-.725.725v1c.952 0 1.725-.772 1.725-1.725h-1z"
      fill="currentColor"
    />
  </Svg>
);
SvgLock.isIcon = true;
SvgLock.defaultProps = {
  size: 24,
};
export default SvgLock;
