import { useSubscription } from '@apollo/react-hooks';
import { useContext } from 'react';
import { UserContext } from '../components/Authenticator';
import publishedEventSubscription from '../graphql/subscriptions/PublishedEventSubscription';
import {
  PublishedEventSubscription,
  PublishedEventSubscriptionVariables,
} from '../graphql/subscriptions/typings/PublishedEventSubscription';

export default function usePublishedEventSubscription(callback) {
  const user = useContext(UserContext);
  useSubscription<PublishedEventSubscription, PublishedEventSubscriptionVariables>(publishedEventSubscription, {
    variables: {
      ownerId: user?.id,
    },
    skip: !user,
    onSubscriptionData: responseData => {
      try {
        const rawData = responseData.subscriptionData.data?.publishedEventSubscription;
        if (typeof rawData?.data === 'string') {
          rawData.data = JSON.parse(rawData?.data);
        }
        callback(rawData);
      } catch (e) {
        console.error(e);
      }
    },
  });
}
