import React from 'react';
import Svg from '../Svg';
export const SvgHelpCircle = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <path d="M13.5 7a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.523 9.219h.875v.861h-.875v-.861zM5.04 5.903c0-.596.179-1.076.537-1.439.358-.363.849-.544 1.474-.544.577 0 1.04.155 1.388.466.347.31.521.707.521 1.19 0 .292-.064.53-.191.712-.128.182-.385.45-.773.803-.281.257-.464.474-.547.652-.084.178-.126.441-.126.789H6.54c0-.395.05-.713.15-.955.1-.241.318-.518.655-.83l.352-.327a1.39 1.39 0 00.255-.293 1.042 1.042 0 00-.081-1.263c-.171-.199-.455-.298-.851-.298-.49 0-.829.17-1.017.513-.105.19-.165.465-.18.824H5.04z"
      fill="currentColor"
    />
  </Svg>
);
SvgHelpCircle.isIcon = true;
SvgHelpCircle.defaultProps = {
  size: 24,
};
export default SvgHelpCircle;
