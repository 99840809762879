import gql from 'graphql-tag';

export default gql`
  query LocationPredictionQuery($input: String!, $limit: Int) {
    locationPrediction(input: $input, limit: $limit) {
      id
      locality
      region
      country
      coords {
        lat
        lon
      }
      formatted
    }
  }
`;
