import loadable from '@loadable/component';

const CreatorApplicationPage = loadable(() =>
  import(/* webpackChunkName: "CreatorApplicationScreen" */ '../screens/CreatorApplicationScreen')
);
const JobBoardScreen = loadable(() =>
  import(/* webpackChunkName: "JobBoardScreen" */ '../screens/JobBoardScreen/JobBoardScreen')
);
const ResetPasswordScreen = loadable(() =>
  import(/* webpackChunkName: "ResetPasswordScreen" */ '../screens/ResetPasswordScreen/ResetPasswordScreen')
);
const SignInScreen = loadable(() =>
  import(/* webpackPrefetch: true */ /* webpackChunkName: "SignInScreen" */ '../screens/SignInScreen')
);
const SettingsScreen = loadable(() =>
  import(/* webpackChunkName: "SettingsScreen" */ '../screens/SettingsScreen/SettingsScreen')
);
const VerifyPasswordScreen = loadable(() =>
  import(/* webpackChunkName: "VerifyPasswordScreen" */ '../screens/VerifyPasswordScreen')
);

const appPrefix = '/app';

interface NavItem {
  label: string;
  href: string;
  icon?: string;
  badgeCount?: number | string;
  component?: any;
  divider?: boolean;
}

export const publicNavItems: NavItem[] = [
  {
    label: 'Sign In',
    href: '/app/sign-in',
    icon: 'SignIn',
    component: SignInScreen,
  },
  {
    label: 'Verify Email',
    href: '/app/auth/verify',
    icon: 'verify',
    component: VerifyPasswordScreen,
  },
  {
    label: 'Reset Password',
    href: '/app/auth/reset',
    icon: 'reset',
    component: ResetPasswordScreen,
  },
  {
    label: 'Creator Application',
    href: '/app/creator/apply',
    icon: 'application',
    component: CreatorApplicationPage,
  },
];

export function getActiveNavItem(navItems: NavItem[], pathname: string): NavItem {
  const [activeNavItem] = navItems.filter(navItem => {
    return pathname.indexOf(navItem.href) !== -1;
  });
  return activeNavItem;
}

export default function buildNavItems(
  __typename?: string,
  unreadConversations = 0,
  unreadAnnouncements = 0,
  hasAdminAccess = false
): NavItem[] {
  const creatorNav = [
    {
      label: 'Job Board',
      href: '/job-board',
      icon: 'DollarCircle',
      component: JobBoardScreen,
      // component: loadable({
      //   loader: () => import('../screens/JobBoardScreen'),
      //   loading: Loader,
      // }),
    },
    {
      label: 'My Jobs',
      href: '/my-jobs',
      icon: 'Briefcase',
      badgeCount: unreadAnnouncements,
      component: loadable(() => import(/* webpackChunkName: "CreatorMyJobsScreen" */ '../screens/CreatorMyJobsScreen')),
    },
    {
      label: 'Messages',
      href: '/messages',
      icon: 'Chat',
      badgeCount: unreadConversations,
      component: loadable(() =>
        import(/* webpackChunkName: "MessagesScreen" */ '../screens/MessagesScreen/MessagesScreen')
      ),
    },
    {
      label: 'Find Creators',
      href: '/search',
      icon: 'Search',
      component: loadable(() => import(/* webpackChunkName: "CreatorSearchScreen" */ '../screens/CreatorSearchScreen')),
    },
    {
      label: 'Bookmarks',
      href: '/bookmarks',
      icon: 'Bookmark',
      component: loadable(() => import(/* webpackChunkName: "BookmarksScreen" */ '../screens/BookmarksScreen')),
    },
    {
      label: 'My Profile',
      href: '/profile',
      icon: 'UserCard',
      component: loadable(() =>
        import(/* webpackChunkName: "CreatorProfileScreen" */ '../screens/CreatorPrivateProfileScreen')
      ),
    },
    {
      label: null,
      href: null,
      icon: null,
      component: null,
      divider: true,
    },

    {
      label: 'Critique Corner',
      href: '/critique-corner',
      icon: 'Star',
      component: loadable(() =>
        import(/* webpackChunkName: "CritCornerScreen" */ '../screens/CritCornerScreen/CritCornerScreen')
      ),
      divider: false,
    },
    {
      label: 'Contact Support',
      // label: 'How it Works',
      // href: 'https://knowledge.thehhub.com',
      href: 'https://thehhub.typeform.com/contactsupport',
      icon: 'Lightbulb',
      component: null,
    },
    {
      label: 'My Settings',
      href: '/settings',
      icon: 'Settings',
      component: SettingsScreen,
    },
    ...(hasAdminAccess
      ? [
          {
            label: 'Admin',
            href: '/admin',
            icon: 'Lock',
          },
        ]
      : []),
    { label: 'Sign Out', href: '/sign-out', icon: 'SignOut' },
  ];

  const clientNav = [
    {
      label: 'Dashboard',
      href: '/dashboard',
      icon: 'Dashboard',
      component: loadable(() =>
        import(/* webpackChunkName: "ClientDashboardScreen" */ '../screens/ClientDashboardScreen/ClientDashboardScreen')
      ),
    },
    {
      label: 'My Jobs',
      href: '/my-jobs',
      icon: 'SkillsetPhotographer',
      badgeCount: unreadAnnouncements,
      component: loadable(() => import(/* webpackChunkName: "ClientMyJobsScreen" */ '../screens/ClientMyJobsScreen')),
    },
    // {
    //   label: 'My Jobs',
    //   href: '/my-jobs/manage/:section',
    //   icon: 'SkillsetPhotographer',
    //   badgeCount: unreadAnnouncements,
    //   component: loadable({
    //     loader: () => import('../screens/ClientMyJobsScreen'),
    //     loading: Loader,
    //   }),
    // },
    {
      label: 'Messages',
      href: '/messages',
      icon: 'Chat',
      badgeCount: unreadConversations,
      component: loadable(() =>
        import(/* webpackChunkName: "MessagesScreen" */ '../screens/MessagesScreen/MessagesScreen')
      ),
    },
    {
      label: 'Hire Creators',
      href: '/hire-creators',
      icon: 'Search',
      component: loadable(() =>
        import(/* webpackChunkName: "AdvancedClientHireCreatorsScreen" */ '../screens/AdvancedClientHireCreatorsScreen')
      ),
    },
    {
      label: 'Bookmarks',
      href: '/bookmarks',
      icon: 'Bookmark',
      component: loadable(() =>
        import(/* webpackChunkName: "ClientBookmarksScreen" */ '../screens/ClientBoomarksScreen/ClientBookmarksScreen')
      ),
    },
    {
      label: null,
      href: null,
      icon: null,
      component: null,
      divider: true,
    },
    {
      label: 'Contact Support',
      // label: 'How it Works',
      // href: 'https://knowledge.thehhub.com',
      href: 'https://thehhub.typeform.com/contactsupport',
      icon: 'Lightbulb',
      component: null,
    },
    {
      label: 'My Settings',
      href: '/settings',
      icon: 'Settings',
      component: SettingsScreen,
    },
    ...(hasAdminAccess
      ? [
          {
            label: 'Admin',
            href: '/admin',
            icon: 'Lock',
          },
        ]
      : []),
    { label: 'Sign Out', href: '/sign-out', icon: 'SignOut' },
  ];

  // @ts-ignore
  return (__typename && __typename === 'Client' ? clientNav : creatorNav).map(navItem => {
    // @ts-ignore
    if (navItem && navItem.href && !navItem.href.startsWith('http')) {
      navItem.href = `${appPrefix}${navItem.href}`;
    }
    return navItem;
  });
}
