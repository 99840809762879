import styled from 'styled-components/macro';
import { color, fontSize, fontWeight, lineHeight, space, textAlign, textStyle } from 'styled-system';
import Text from '../text';

const TextButton = styled(Text)`
  border: 0;
  padding: 0;
  background: transparent;
  font-size: inherit;
  ${textStyle}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${space}
  ${color}
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export default TextButton;
