import merge from 'lodash.merge';
import React from 'react';
import Box from '../../../../components-core/box';
import Container from '../../../../components-core/container';
import FlexInline from '../../../../components-core/flex-inline';
import { FormItem } from '../../../../components-core/form';
import Icon from '../../../../components-core/icon';
import Text from '../../../../components-core/text';
import InputList from '../../../../components/InputList';

const DosAndDontsStep = ({ payload, setPayload, className }: any) => {
  const handleChange = key => value => {
    const [entry] = value;
    // setPayload(prev =>
    //   merge(prev, { details: { guidelines: { [key]: value || null } } })
    // );
    if (value.length === 0 || entry === '') {
      setPayload(prev => {
        // merge(prev, { details: { guidelines: { do: undefined } } })
        const updated = prev;
        delete updated.details.guidelines[key];
        return updated;
      });
    } else {
      setPayload(prev => merge(prev, { details: { guidelines: { [key]: value || null } } }));
    }
  };

  return (
    <Container maxWidth={540} pb={3} className={className}>
      <Text textAlign="center" mb={4}>
        <Text fontSize="18px" bold>
          Include specific do’s and dont’s
        </Text>
      </Text>

      <Text mb={1}>
        <strong>
          <FlexInline alignItems="center">
            <Icon color="green" name="CheckCircle" size="1em" mr={1} /> Do's
          </FlexInline>
        </strong>
      </Text>
      <FormItem>
        <Box mb={4}>
          <InputList
            value={payload?.details?.guidelines?.do ?? []}
            onChange={handleChange('do')}
            placeholder="Enter a do"
          />
          {/*<Text mt={2} color={'#6f6f6f'} fontSize={1}>e.g., "Pay attention to detail."</Text>*/}
        </Box>
      </FormItem>
      {/* <Divider my={3} /> */}
      <Text mb={1} mt={3}>
        <FlexInline alignItems="center">
          <Icon color="red" name="CloseCircle" size="1em" mr={1} /> <strong>Dont's</strong>
        </FlexInline>
      </Text>
      <FormItem mb={4}>
        <Box>
          <InputList
            value={payload?.details?.guidelines?.dont ?? []}
            onChange={handleChange('dont')}
            placeholder="Enter a dont"
          />
          {/*<Text mt={2} color={'#6f6f6f'} fontSize={1}>e.g., "Don't be an ass."</Text>*/}
        </Box>
      </FormItem>
    </Container>
  );
};

export default DosAndDontsStep;
