import gql from 'graphql-tag';
import JobPostDetailsFragment from './JobPostDetailsFragment';

export default gql`
  ${JobPostDetailsFragment}
  fragment JobPostFragment on JobPost {
    id
    createdAt
    details {
      ...JobPostDetailsFragment
    }
    ownerProfile {
      ... on ProtectedCreator {
        id
        layerId
      }
      ... on ProtectedClient {
        id
        avatar {
          publicId
        }
        name
        layerId
        slug
        tier
        user {
          id
          givenName
          familyName
        }
      }
    }
    visibility {
      public
      listed
      creatorFilters {
        creatorIds
        tags
        tiers
        internalRating {
          min
          max
        }
        styles
      }
    }
    slug
    status
    title
    description
    skill
    updatedAt
    callout {
      type
      description
    }
    creatorMatch {
      name
      attributes
    }
  }
`;
