import { useQuery } from '@apollo/react-hooks';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import CHAT_IDENTITY_QUERY from '../../../graphql/queries/ChatIdentity';
import withStreamChatLib from '../../../hoc/withStreamChatLib';
import withStreamChatReactLib from '../../../hoc/withStreamChatReactLib';
import { uuid } from '../../../utils/layerUtils';
import { UserContext } from '../../../components/Authenticator';
import 'react-file-utils/dist/index.css';
import 'emoji-mart/css/emoji-mart.css';
import '../theme/index.scss';
import { ChatIdentity } from '../../../graphql/queries/typings/ChatIdentity';
import createLogger from '../../../utils/createLogger';

export const StreamContext = createContext<any>(null);

const logger = createLogger('StreamChat');
const channelLogger = createLogger('StreamChat:useChannelQuery');

export function useChannelQuery(conversationId, jobPostSlug, memberId, onNotFound?) {
  channelLogger(`channelLogger(conversationId, jobPostSlug, memberId)`, conversationId, jobPostSlug, memberId);
  const { client, ready } = useContext(StreamContext);
  const [channel, setChannel] = useState<any>(null);

  useEffect(() => {
    if (!ready) {
      return;
    }
    if (!conversationId) {
      channelLogger(`no conversation id`);
      return;
    }
    const conversationIdWithoutPrefix = uuid(conversationId);
    const filter = {
      $or: [
        { $and: [{ jobPostSlug }, { members: { $in: [memberId] } }] },
        {
          layer_conversation_id: `layer:///conversations/${conversationIdWithoutPrefix}`,
        },
        { id: conversationIdWithoutPrefix },
      ],
    };
    const sort = { last_message_at: -1 };
    const options = { watch: true, state: true };

    client
      .queryChannels(filter, sort, options)
      .then(channels => {
        const [c] = channels || [];
        if (c) {
          setChannel(c);
        } else {
          if (typeof onNotFound === 'function') {
            return onNotFound().then(c => {
              if (c) {
                setChannel(c);
              }
            });
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
    // eslint-disable-next-line
  }, [conversationId, ready]);

  return channel;
}

function StreamProvider({ children, chatClient, StreamChatReact: { Chat } }) {
  const user = useContext(UserContext);

  const chatIdentityQuery = useQuery<ChatIdentity>(CHAT_IDENTITY_QUERY, {
    skip: !user,
    onCompleted: data => {
      logger('chatIdentity query success %O', data);
    },
    onError: error => {
      console.error(error);
      // bubble up to ErrorBoundry to show error page
      // throw error;
    },
  });

  const [numFilteredChannels, setNumFilterChannels] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [streamUser, setStreamUser] = useState<any>(chatClient.user || null);
  const [error, setError] = useState<any>(null);

  async function createChannel(id = v4(), data) {
    const channel = await chatClient.channel('messaging', id, data);
    await channel.create();
    return channel;
  }

  // Authenticate
  useEffect(() => {
    if (!user || !chatClient) {
      return;
    }
    const userId = chatIdentityQuery.data?.chatIdentity?.userId;
    const userToken = chatIdentityQuery.data?.chatIdentity?.token;
    // @ts-ignore
    if (error || streamUser || !!chatClient.userID || !userId || !userToken) {
      return;
    }

    chatClient
      .setUser({ id: userId }, userToken)
      .then(result => {
        logger('`setUser` esult: %O', result);
        const streamUser = result?.me;
        if (streamUser) {
          setStreamUser(streamUser);
        }

        const filters = {
          type: 'messaging',
          members: { $in: [user.id] },
        };
        const sort = {
          last_message_at: -1,
        };
        const options = { state: true, watch: false, presence: false };
        return chatClient.queryChannels(filters, sort, options);
      })
      .then(channels => {
        let unreadCount = 0;
        let filteredCount = 0;
        channels.forEach(channel => {
          if (user.__typename === 'Client') {
            if (channel.state.messages.length === 1 && typeof channel.data.jobPostTitle === 'string') {
              filteredCount++;
            }
          } else {
            unreadCount += channel.countUnread();
          }
        });
        setUnreadCount(unreadCount);
        setNumFilterChannels(filteredCount);
        logger(channels);
      })
      .catch(error => {
        console.error('`setUser` error: %O', error);
        setError(error);
      });
  }, [user, chatIdentityQuery, error, streamUser, chatClient]);

  // Handle live updating of badge counts
  useEffect(() => {
    if (!chatClient) {
      return;
    }

    function catchAllEvents(event) {
      if (event.total_unread_count != null) {
        logger(`unread messages count is now: ${event.total_unread_count}`);
        setUnreadCount(event.total_unread_count - numFilteredChannels);
      }
    }
    // @ts-ignore
    chatClient.on(catchAllEvents);

    return () => {
      // @ts-ignore
      chatClient.off(catchAllEvents);
    };
  }, [chatClient, numFilteredChannels]);

  return (
    <StreamContext.Provider
      value={{
        client: chatClient,
        user: streamUser,
        streamUser,
        ready: !!streamUser,
        unreadCount,
        createChannel,
        getUnreadCount: () => unreadCount,
      }}
    >
      <Chat client={chatClient} theme={`messaging light`}>
        {children}
      </Chat>
    </StreamContext.Provider>
  );
}

export default withStreamChatReactLib(withStreamChatLib(StreamProvider));
