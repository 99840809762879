import styled from 'styled-components/macro';
import Box from '../box';
import { BackgroundImageProps } from '../types';

const image = (props: BackgroundImageProps) => (props.image ? { backgroundImage: `url(${props.image})` } : null);

const size = (props: BackgroundImageProps) => ({
  backgroundSize: props.size ? props.size : 'cover',
});
const position = (props: BackgroundImageProps) =>
  position
    ? {
        backgroundPosition: props.position,
      }
    : null;

const BackgroundImage = styled(Box)`
  background-repeat: no-repeat;
  ${image}
  ${size}
  ${position}
`;

BackgroundImage.displayName = 'BackgroundImage';

export default BackgroundImage;
