import React from 'react';
import Svg from '../Svg';
export const SvgChat = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 15 15" fill="none" {...props}>
    <path
      clipRule="evenodd"
      d="M7.842 10.95h4.894c.698 0 1.264-.553 1.264-1.233V2.233C14 1.553 13.434 1 12.736 1H2.264C1.566 1 1 1.552 1 2.233v7.484c0 .68.566 1.232 1.264 1.232h1.362V14l2.608-3.05h1.608z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.568 5.806a.811.811 0 01-.816.807.812.812 0 01-.818-.807c0-.445.366-.806.818-.806.45 0 .816.361.816.806zM8.305 5.806a.811.811 0 01-.816.807.812.812 0 01-.818-.807c0-.445.366-.806.818-.806.45 0 .816.361.816.806zM5.063 5.806a.811.811 0 01-.816.807.812.812 0 01-.817-.807c0-.445.365-.806.817-.806.45 0 .816.361.816.806z"
      fill="currentColor"
    />
  </Svg>
);
SvgChat.isIcon = true;
SvgChat.defaultProps = {
  size: 24,
};
export default SvgChat;
