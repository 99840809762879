import React from 'react';
import Svg from '../Svg';
export const SvgPlus = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 17 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.532 1.9a1.031 1.031 0 00-2.063 0v5.569H1.9a1.031 1.031 0 000 2.062h5.57V15.1a1.031 1.031 0 002.062 0V9.53H15.1a1.031 1.031 0 000-2.062H9.532v-5.57z"
      fill="currentColor"
    />
  </Svg>
);
SvgPlus.isIcon = true;
SvgPlus.defaultProps = {
  size: 24,
};
export default SvgPlus;
