import moment from 'moment';
import {removeTypename} from '../../utils/objectUtils';

export default function buildJobSubmitVariables(values) {
  const variables = {
    skill: values.skill,
    visibility: {
      public: true,
      listed: true,
      ...values.visibility
    },
    title: values.title,
    description: values.description,
    details: {
      styles: [],
      schedule: {
        dueDate: null
      },
      budget: {}
    }
  };
  // console.log(values);
  if (
    values?.details?.location &&
    (values.details.location.locality ||
      values.details.location.region ||
      values.details.location.country ||
      values.details.location.coords)
  ) {
    // @ts-ignore
    variables.details.location = values.details.location;
  }
  if (values?.details?.schedule) {
    let momentInstance = values?.details?.schedule?.dueDate;
    // console.log(momentInstance);
    if (typeof momentInstance === 'string') {
      momentInstance = moment(momentInstance);
    }
    // @ts-ignore
    variables.details.schedule.dueDate = momentInstance.unix() * 1000 + '';
  }

  if (values?.details?.usage?.type) {
    // @ts-ignore
    variables.details.usage = values?.details?.usage;
  }
  if (values?.details?.guidelines) {
    // @ts-ignore
    variables.details.guidelines = values.details.guidelines;
  }

  if (values?.details.styles) {
    // // @ts-ignore
    // const styleStrings: any = get(values, 'details.styles', []);
    // const styles: any = [];
    // styleStrings.map(item =>
    //   JSON.parse(item).forEach(style => styles.push(style))
    // );
    // @ts-ignore
    variables.details.styles = JSON.parse(values?.details.styles);
  }

  // @ts-ignore
  variables.details = variables.details || {};

  if (values?.details?.files) {
    // @ts-ignore
    variables.details.files = values.details.files;
  }

  const moodBoard = values?.details.moodBoard ?? [];
  // @ts-ignore
  variables.details.moodBoard = removeTypename(moodBoard.filter(i => i !== null));

  const budgetRange = values?.details?.budget?.range ?? {};
  if (budgetRange) {
    const {min, max} = budgetRange;
    if (max) {
      // @ts-ignore
      variables.details.budget.range = {
        min: min * 100,
        max: max * 100
      };
    }
  }

  return variables;
}
