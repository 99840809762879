import gql from 'graphql-tag';
import JobPostFragment from './JobPostFragment';
import { JobPostProposalProtectedCreatorFragment } from './JobProposalProtectedCreatorFragment';

export default gql`
  ${JobPostFragment}
  ${JobPostProposalProtectedCreatorFragment}
  fragment JobPostContractFragment on JobPostContract {
    id
    charge {
      totalChargeAmount
      totalToCreator
    }
    assets {
      zipUrl
      photos {
        key
        name
        url
        sampleUrl
        size
        mimeType
        thumbnailUrl
      }
    }
    usage {
      type
      description
    }
    contractNum
    jobPost {
      ...JobPostFragment
    }
    ownerProfile {
      ... on ProtectedCreator {
        id
        layerId
      }
      ... on ProtectedClient {
        id
        avatar {
          publicId
        }
        name
        layerId
        slug
        user {
          id
          givenName
          familyName
        }
      }
    }
    creator {
      ...JobPostProposalProtectedCreatorFragment
    }
    conversationId
    amount
    currency
    dueDate
    deliverables {
      type
      quantity
    }
    discountCode
    discountPercentage
    scopeOfWork
    status
    fingerprint
    acceptedAt
  }
`;
