import React from 'react';
import Svg from '../Svg';
export const SvgRadioActive = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx={8} cy={8} r={7.5} stroke="currentColor" />
    <circle cx={8} cy={8} r={5} fill="currentColor" />
  </Svg>
);
SvgRadioActive.isIcon = true;
SvgRadioActive.defaultProps = {
  size: 24,
};
export default SvgRadioActive;
