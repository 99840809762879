import React from 'react';
import Svg from '../Svg';
export const SvgCloseCircle = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <path d="M7 13.5a6.5 6.5 0 110-13 6.5 6.5 0 010 13z" stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.146 4.146a.5.5 0 000 .708l2.122 2.12-2.122 2.122a.5.5 0 00.708.707l2.12-2.121 2.122 2.121a.5.5 0 00.707-.707L7.682 6.975l2.121-2.121a.5.5 0 10-.707-.708L6.975 6.268 4.854 4.146a.5.5 0 00-.708 0z"
      fill="currentColor"
    />
  </Svg>
);
SvgCloseCircle.isIcon = true;
SvgCloseCircle.defaultProps = {
  size: 24,
};
export default SvgCloseCircle;
