import gql from 'graphql-tag';
import JobPostDetailsFragment from '../fragments/JobPostDetailsFragment';

export default gql`
  ${JobPostDetailsFragment}
  query JobPostRepostQuery($id: ID) {
    jobPost(id: $id) {
      id
      details {
        ...JobPostDetailsFragment
      }
      status
      title
      description
      skill
      updatedAt
    }
  }
`;
