import React, { ChangeEvent, useState } from 'react';
import Box from '../../components-core/box';
import Button from '../../components-core/button';
import Flex from '../../components-core/flex';
import FlexInline from '../../components-core/flex-inline';
import Icon from '../../components-core/icon';
import Input from '../../components-core/input';
import OutlineButton from '../../components-core/outline-button';
import Text from '../../components-core/text';
import createLogger from '../../utils/createLogger';

const logger = createLogger('InputList');

export type InputListValue = string[];
const AddIcon = props => (
  <svg width={15} height={15} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 7.5a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zM7.5 2.91a.5.5 0 01.5.5V7h3.591a.5.5 0 010 1h-3.59v3.59a.5.5 0 01-1 0V8H3.408a.5.5 0 010-1H7V3.41a.5.5 0 01.5-.5z"
      fill="#FEFEFE"
    />
  </svg>
);

export interface InputListProps {
  placeholder?: string;
  onChange?: (entries: InputListValue) => void;
  value?: InputListValue;
  forwardedRef?: React.Ref<any>;
}

function InputList({ placeholder, onChange, value, forwardedRef }: InputListProps) {
  // decouple list from entries so full render doesnt fire on every change
  const [list, setList] = useState<InputListValue>(value || []);
  const [entries, setEntries] = useState<InputListValue>(value || []);
  const [entry, setEntry] = useState<string>('');

  const handleAdd = () => {
    if (!entry) {
      return;
    }
    const next = entries.concat([entry]);
    setList(next);
    setEntries(next);
    if (onChange) {
      onChange(next);
    }
    setEntry('');
  };

  const handleRemove = (index: number) => () => {
    const next = entries.filter((_item, i) => i !== index);
    setList(next);
    setEntries(next);
    if (onChange) {
      onChange(next);
    }
  };

  const handleEntryChange = (e: ChangeEvent<HTMLInputElement>) => {
    logger('handleEntryChange');
    const value = e.target.value;
    setEntry(value);

    // if (onChange) {
    //   onChange([value]);
    // }
  };

  const handleEntriesChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    logger('handleEntriesChange');
    const entryValue = e.target.value;
    setEntries(prev => {
      const next = [...prev];
      next[index] = entryValue;
      return next;
    });
  };

  return (
    <>
      {list.map((_item, index) => (
        <Flex key={index} mb={3}>
          <Box flex={1}>
            <Input
              size="large"
              css={{
                borderRadius: '5px 0 0 5px',
                flex: '1',
                borderRight: 0,
                paddingLeft: '8px',
                '> div': {
                  height: 'auto',
                },
              }}
              value={list[index]}
              onChange={handleEntriesChange(index)}
              disabled
            />
          </Box>
          <OutlineButton
            type="button"
            color="red"
            css={{
              minWidth: '35px',
              // width: 35,
              width: 81,
              height: 42,
              padding: 0,
              borderRadius: '0 5px 5px 0px',
              borderColor: 'rgba(217, 217, 217, 0.4) !important',
              borderLeft: '0 !important',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.03)',
              },
            }}
            // icon="delete"
            onClick={handleRemove(index)}
          >
            {/*<Relative top="-2px">*/}
            {/*  <Text color="blue" bold fontSize="22px" lineHeight="100%">*/}
            {/*    -*/}
            {/*  </Text>*/}
            {/*</Relative>*/}
            <FlexInline alignItems="center">
              <Icon name="Close" size={12} color="red" />
              <Text ml={1} as="span" fontSize={0} caps bold color="red">
                Remove
              </Text>
            </FlexInline>
          </OutlineButton>
        </Flex>
      ))}
      <Flex>
        <Box flex={1}>
          <Input
            flex={1}
            mr={3}
            placeholder={placeholder}
            value={entry}
            onChange={handleEntryChange}
            ref={forwardedRef}
            onPressEnter={e => {
              e.preventDefault();
              handleAdd();
            }}
            size="large"
            css={{
              borderRadius: '5px 0 0 5px',
              flex: 1,
              '> div': {
                height: 'auto',
              },
            }}
          />
        </Box>

        <Button
          // ml={3}
          type="button"
          disabled={!entry}
          // icon="plus"
          onClick={handleAdd}
          css={{
            minWidth: '35px',
            // width: 35,
            width: 81,
            height: 42,
            padding: 0,
            borderRadius: '0 5px 5px 0px',
          }}
          // shape="circle"
        >
          {/*<Relative as="span" top="-2px">*/}
          {/*  <Text as="span" bold fontSize="22px" lineHeight="100%">*/}
          {/*    +*/}
          {/*  </Text>*/}
          {/*</Relative>*/}
          <FlexInline alignItems="center">
            <AddIcon />
            <Text ml={2} as="span">
              Add
            </Text>
          </FlexInline>
        </Button>
      </Flex>
    </>
  );
}

// export default InputList;

// const InputList: React.FC<InputListProps & any> = forwardRef((props, ref) => (
//   <InputListFC {...props} forwardedRef={ref} />
// ));

InputList.displayName = 'InputList';

export default InputList;
