import styled from 'styled-components/macro';
import { typography, variant } from 'styled-system';
import Box from '../box';
import theme from '../theme';
import { TextProps } from '../types';

const italic = (props: TextProps): any => (props.italic ? { fontStyle: 'italic' } : null);

const bold = (props: TextProps): any => (props.bold ? { fontWeight: 'bold' } : null);

const semibold = (props: TextProps): any => (props.semibold ? { fontWeight: 700 } : null);

const caps = (props: TextProps): any => (props.caps ? { textTransform: 'uppercase' } : null);

const strikeThrough = (props: TextProps): any => (props.strikeThrough ? { textDecoration: 'line-through' } : null);

const titleCommons = {
  color: 'black',
  textTransform: 'uppercase',
  fontWeight: 'bold',
};

const Text = styled(Box)<TextProps>(typography, bold, semibold, italic, caps, strikeThrough, () =>
  variant({
    variants: {
      title1: {
        ...titleCommons,
        fontSize: '72px',
        lineHeight: '98%',
        fontWeight: 900,
      },
      title2: {
        ...titleCommons,
        fontFamily: 'heading',
        fontSize: '64px',
        lineHeight: '100%',
      },
      title3: {
        ...titleCommons,
        fontFamily: 'heading',
        fontSize: '45px',
        lineHeight: '100%',
      },
      title4: {
        ...titleCommons,
        fontFamily: 'heading',
        fontSize: '24px',
        lineHeight: '100%',
      },
      title5: {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '22px',
      },
      large: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '29px',
        letterSpacing: '-0.015em',
      },
      body1: {
        fontSize: '21px',
        lineHeight: '150%',
      },
      body2: {
        fontSize: '17px',
        lineHeight: '150%',
      },
      3: {
        fontSize: '14px',
        lineHeight: '150%',
      },
      mini: {
        ...titleCommons,
        fontSize: '12px',
        lineHeight: '15px',
      },
    },
  })
);

Text.defaultProps = {
  theme,
};

export default Text;
