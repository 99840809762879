import React from 'react';
import Svg from '../Svg';
export const SvgHeartCircle = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 26 26" fill="none" {...props}>
    <g stroke="currentColor">
      <circle cx={13} cy={13} r={12.5} />
      <path d="M19 11.37c0 .982-.545 1.732-.545 1.732-.3.412-.839 1.044-1.2 1.404l-3.6 3.601c-.36.36-.95.36-1.31 0l-3.6-3.601c-.36-.36-.9-.992-1.2-1.404 0 0-.545-.75-.545-1.732a3.37 3.37 0 015.806-2.33l.068.072c.069.083.183.083.252 0l.068-.072A3.37 3.37 0 0119 11.37z" />
    </g>
  </Svg>
);
SvgHeartCircle.isIcon = true;
SvgHeartCircle.defaultProps = {
  size: 24,
};
export default SvgHeartCircle;
