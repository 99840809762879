import gql from 'graphql-tag';

export default gql`
  mutation CreatorUpdate(
    $avatar: ImageInput
    $bio: String
    $social: SocialMediaHandlesInput
    $website: String
    $settings: SettingsInput
    $media: [MediaItemInput]
    $skill: CreatorSkillInput
    $otherSkills: [CreatorSkillInput]
    $gear: [String]
    $pro: ProfessionalInput
    $location: LocationInput
    $gender: Gender
    $birthdate: String
    $phoneNumber: String
    $hairColor: HairColor
    $eyeColor: EyeColor
    $measurements: BodyMeasurementsInput
    $race: [Race]
    $instagramToken: String
  ) {
    creatorUpdate(
      avatar: $avatar
      bio: $bio
      social: $social
      website: $website
      settings: $settings
      media: $media
      skill: $skill
      otherSkills: $otherSkills
      gear: $gear
      pro: $pro
      location: $location
      gender: $gender
      birthdate: $birthdate
      phoneNumber: $phoneNumber
      hairColor: $hairColor
      eyeColor: $eyeColor
      measurements: $measurements
      race: $race
      instagramToken: $instagramToken
    ) {
      avatar {
        publicId
      }
      bio
      createdAt
      gear
      id
      location {
        coords {
          lat
          lon
        }
        country
        locality
        region
        postalCode
      }
      media(limit: 100) {
        items {
          publicId
        }
      }
      name
      otherSkills {
        details
        name
      }
      instagramProfile {
        token
        id
        userName
        profilePicture
        verified
        updatedAt
        counts {
          followers
          engagement
          avgLikes
          avgComments
        }
      }
      pro {
        level
        modelingStyle
        nudity
        published
        signed
        sponsoredPosts {
          pay
          product
        }
        testShoots
      }
      promoImages {
        publicId
      }
      rating {
        overall
        internal
      }
      segmentAnonymousId
      segmentDeviceId
      settings {
        notification {
          promoEmails
          pushNotifications
        }
        privacy {
          publicProfile
        }
      }
      skill {
        name
      }
      slug
      social {
        facebook
        instagram
        linkedIn
        pinterest
        snapchat
        twitter
        youtube
        vimeo
      }
      styles {
        name
      }
      updateRequired
      updatedAt
      user {
        id
        legacyId
        email
        givenName
        familyName
        name
        gender
        birthdate
        phoneNumber
        measurements {
          bust
          waist
          hips
          dress
          pants
          height
        }
        race
        updatedAt
      }
      website
    }
  }
`;
