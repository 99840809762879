import gql from 'graphql-tag';
import JobPostDetailsFragment from './JobPostDetailsFragment';
import JobPostProposalsFragment from './JobPostProposalsFragment';
import JobPostContractFragment from './JobPostContractFragment';

export default gql`
  ${JobPostDetailsFragment}
  ${JobPostProposalsFragment}
  ${JobPostContractFragment}
  fragment ClientJobPostFragment on JobPost {
    proposals {
      ...JobPostProposalsFragment
    }
    contract {
      ...JobPostContractFragment
    }
    id
    jobCreatedAt: createdAt
    details {
      ...JobPostDetailsFragment
    }
    creatorFeedback {
      createdAt
      ratings {
        overall
      }
    }
    proposalsRequested
    ownerProfile {
      ... on ProtectedCreator {
        id
        layerId
      }
      ... on ProtectedClient {
        id
        avatar {
          publicId
        }
        name
        layerId
        slug
        user {
          id
          givenName
          familyName
        }
      }
    }
    visibility {
      public
      listed
    }
    jobSlug: slug
    status
    title
    description
    skill
    updatedAt
    proposalsCount
  }
`;
