import React from 'react';
import Svg from '../Svg';
export const SvgLightbulb = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 15 15" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.736 14.245V14.169a16.274 16.274 0 00-.028-.928c-.033-.587-.1-1.157-.201-1.438-.176-.49-.398-.738-.808-1.197l-.163-.184c-.82-.926-1.3-1.733-1.566-2.489-.268-.76-.303-1.42-.303-2.002C1.667 2.671 4.253 0 7.479 0c3.227 0 5.813 2.672 5.813 5.931 0 1.245-.433 2.827-1.666 4.219-.365.412-.657.95-.908 1.578-.115.287-.193.872-.233 1.48a17.3 17.3 0 00-.036.961v.078A.751.751 0 019.7 15H5.486a.75.75 0 01-.75-.755zm.75 0h-.75.75v.005H9.7l.75-.003h-.75v-.02c0-.072.002-.298.013-.597H5.476a18.95 18.95 0 01.01.615zm0 0l4.213.005v-.004l-4.213-.001zm-.057-1.449h4.336a7.84 7.84 0 01.133-.924H5.302c.06.273.1.6.127.924zm4.768-1.758H4.98c-.196-.347-.437-.614-.72-.931a36.997 36.997 0 01-.163-.182c-1.546-1.746-1.68-2.9-1.68-3.994C2.417 3.07 4.684.75 7.479.75c2.796 0 5.063 2.32 5.063 5.181 0 1.078-.377 2.48-1.477 3.721-.352.398-.633.873-.868 1.386z"
      fill="currentColor"
    />
  </Svg>
);
SvgLightbulb.isIcon = true;
SvgLightbulb.defaultProps = {
  size: 24,
};
export default SvgLightbulb;
