import React from 'react';
import Svg from '../Svg';
export const SvgBriefcase = ({ size, ...props }) => (
  <Svg width={size} height={size} fill="currentColor" aria-hidden="true" viewBox="0 0 20 20" {...props}>
    <rect
      fill="transparent"
      x={0.75}
      y={4.75}
      width={18.5}
      height={14.5}
      rx={1.25}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      fill="transparent"
      d="M6 5V3a2 2 0 012-2h4a2 2 0 012 2v2m5.33 4a15.914 15.914 0 01-7.83 2.93M.67 9a15.913 15.913 0 007.83 2.93"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      fill="transparent"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
      d="M8.75 10.25h2.5v3h-2.5z"
    />
  </Svg>
);
SvgBriefcase.isIcon = true;
SvgBriefcase.defaultProps = {
  size: 24,
};
export default SvgBriefcase;
