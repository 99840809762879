import styled from 'styled-components/macro';
import { borderColor, space, width } from 'styled-system';
import theme from '../theme';
import { DividerProps } from '../types';

const Divider = styled.hr<DividerProps>`
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  
  ${space} 
  ${width} 
  ${borderColor};
`;

Divider.displayName = 'Divider';

Divider.defaultProps = {
  borderColor: 'borderGray',
  theme,
  ml: 0,
  mr: 0,
};

export default Divider;
