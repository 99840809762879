import React from 'react';
import Svg from '../Svg';
export const SvgEnvelope = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 13 10" fill="none" {...props}>
    <path d="M.5 9V1A.5.5 0 011 .5h11a.5.5 0 01.5.5v8a.5.5 0 01-.5.5H1A.5.5 0 01.5 9z" stroke="currentColor" />
    <path d="M12 1L6.5 6 1 1" stroke="currentColor" strokeLinejoin="round" />
  </Svg>
);
SvgEnvelope.isIcon = true;
SvgEnvelope.defaultProps = {
  size: 24,
};
export default SvgEnvelope;
