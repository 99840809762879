import React from 'react';
import Svg from '../Svg';
export const SvgLocation = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M12 5.6c0 .769-.267 1.653-.713 2.57-.442.913-1.044 1.824-1.674 2.636a17.788 17.788 0 01-1.806 2.01 6.829 6.829 0 01-.664.56A2.041 2.041 0 017 13.47a2.043 2.043 0 01-.143-.094 6.829 6.829 0 01-.664-.56 17.788 17.788 0 01-1.806-2.01c-.63-.812-1.232-1.723-1.674-2.635C2.266 7.253 2 6.369 2 5.6 2 2.775 4.247.5 7 .5s5 2.275 5 5.1zm-5.08 7.911a.037.037 0 010 0zm.156-.001l.004.001-.004-.001z"
      stroke="currentColor"
    />
    <path
      d="M8.906 5.95C8.906 7.035 8.044 7.9 7 7.9s-1.906-.865-1.906-1.95S5.956 4 7 4s1.906.865 1.906 1.95z"
      stroke="currentColor"
    />
  </Svg>
);
SvgLocation.isIcon = true;
SvgLocation.defaultProps = {
  size: 24,
};
export default SvgLocation;
