import React from 'react';
import loadable from '@loadable/component';

const StreamChatReactLib = loadable.lib(() =>
  import(/* webpackPreload: true */ /* webpackChunkName: "StreamChatReact" */ 'stream-chat-react')
);

const withStreamChatReactLib = Component => props => (
  <StreamChatReactLib>
    {StreamChatReact => <Component {...props} StreamChatReact={StreamChatReact} />}
  </StreamChatReactLib>
);

export default withStreamChatReactLib;
