import config from '../config';
import jobPostPresignedContractAssetFileUploadRequestMutation from '../graphql/mutations/JobPostPresignedContractAssetFileUploadRequest';
import PresignedFileUploadRequest from '../graphql/mutations/PresignedFileUploadRequest';
import createLogger from './createLogger';
import gql from 'graphql-tag';

const logger = createLogger('UploadUtils');

export function putFile(url, file, onProgress?, xhrInst?, contentType?) {
  logger('putFile', url, file, onProgress, xhrInst);
  return new Promise((resolve, reject) => {
    const xhr = xhrInst || new XMLHttpRequest();
    // const formData = new FormData();
    // formData.append(file.name, file);

    if (xhr.upload) {
      xhr.upload.onabort = reject;
      xhr.upload.onload = resolve;
      xhr.upload.onprogress = function (e) {
        const done = e.loaded;
        const total = e.total;
        if (onProgress) {
          onProgress(Math.floor((done / total) * 1000) / 10, done, total);
        }
      };
    }

    xhr.open('PUT', url);
    if (contentType) {
      xhr.setRequestHeader('x-amz-meta-content-type', contentType || '');
    }

    xhr.setRequestHeader('Content-Type', '');
    xhr.send(file);
  });
  // return fetch(url, {
  //   method: 'PUT',
  //   body: file,
  //   headers: {
  //     'Content-Type': '',
  //   },
  // });
}

const shareFileMutation = gql`
  mutation shareFile($fileNames: [String]!) {
    presignedFileUploadRequestWithContentType(fileNames: $fileNames) {
      fileName
      key
      uploadUrl
      contentType
      url
    }
  }
`;

export async function shareFile(file, apolloClient) {
  const fileName = file.name;
  const {
    data: { presignedFileUploadRequestWithContentType },
  } = await apolloClient.mutate({
    mutation: shareFileMutation,
    variables: {
      fileNames: [fileName],
    },
  });

  const [presignedFileUpload] = presignedFileUploadRequestWithContentType;
  if (!presignedFileUpload) {
    logger('[warn] Error parsing presigned upload URL.');
    return null;
  }
  try {
    await putFile(presignedFileUpload.uploadUrl, file, null, null, presignedFileUpload?.contentType);
  } catch (error) {
    console.error(error);
  }

  return presignedFileUpload.url;
}

export async function handleContractAssetUpload(contractId, file, apolloClient, onProgress?) {
  logger('handleContractAssetUpload(%s, %O)', contractId, file);

  const fileName = file.name;
  const {
    data: { jobPostPresignedContractAssetFileUploadRequest },
  } = await apolloClient.mutate({
    mutation: jobPostPresignedContractAssetFileUploadRequestMutation,
    variables: {
      fileNames: [fileName],
      contractId,
    },
  });

  if (!jobPostPresignedContractAssetFileUploadRequest) {
    logger('[warn] Error fetching presigned upload URL.');
    return null;
  }

  const [presignedFileUpload] = jobPostPresignedContractAssetFileUploadRequest;
  if (!presignedFileUpload) {
    logger('[warn] Error parsing presigned upload URL.');
    return null;
  }
  //
  // const formData = new FormData();
  // formData.append(fileName, file);

  try {
    await putFile(presignedFileUpload.uploadUrl, file, onProgress);
  } catch (error) {
    console.error(error);
    // await putFile(presignedFileUpload.uploadUrl, file);
  }

  // return new File([file], file.name);

  return {
    size: file.size,
    key: presignedFileUpload.key,
    name: file.name,
    mimeType: file.type,
    type: file.type,
  };
}

export async function handleS3Upload(file, apolloClient) {
  const fileName = file.name;
  const {
    data: { presignedFileUploadRequestWithContentType },
  } = await apolloClient.mutate({
    mutation: PresignedFileUploadRequest,
    variables: {
      fileNames: [fileName],
    },
  });

  if (!presignedFileUploadRequestWithContentType) {
    logger('[warn] Error fetching presigned upload URL.');
    return;
  }

  const [presignedFileUpload] = presignedFileUploadRequestWithContentType;
  if (!presignedFileUpload) {
    logger('[warn] Error parsing presigned upload URL.');
    return;
  }

  // const formData = new FormData();
  // formData.append(fileName, file);

  try {
    await putFile(presignedFileUpload.uploadUrl, file);
  } catch (error) {
    console.error(error);
    await putFile(presignedFileUpload.uploadUrl, file);
  }

  return {
    size: file.size,
    key: presignedFileUpload.key,
    name: file.name,
    mimeType: file.type,
    type: file.type,
  };
}

export async function handleCloudinaryUpload(image: File) {
  logger('uploadImage(%O)', image);
  const url = config.CLOUDINARY_API_UPLOAD_URL;
  const formData = new FormData();
  formData.append('file', image);
  formData.append('upload_preset', config.CLOUDINARY_API_UPLOAD_PRESET);

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

export const cloundiaryResponseToMediaImageInput = data => ({
  publicId: data.public_id || data.publicUrl || data.publicId,
  // displayUrl: data.secure_url || data.displayUrl,
  // thumbnailUrl: data.secure_url || data.thumbnailUrl,
  type: 'IMAGE',
});
