import { useMutation, useQuery } from '@apollo/react-hooks';
import { notification } from 'antd';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Text from '../../components-core/text';
import ALL_ANNOUNCEMENT_READ_MUTATION from '../../graphql/mutations/AllAnnouncementRead';
import ANNOUNCEMENT_READ_MUTATION from '../../graphql/mutations/AnnouncementRead';
import ANNOUNCEMENTS_QUERY from '../../graphql/queries/AnnouncementsQuery';
import usePublishedEventSubscription from '../../hooks/usePublishedEventSubscription';
import { UserContext } from '../Authenticator';
import buildAnnouncementTitle from './buildAnnouncementTitle';
import { Announcements, Announcements_announcements_items } from '../../graphql/queries/typings/Announcements';

interface AnnouncementStore {
  announcements: (Announcements_announcements_items | null)[];
  loading: boolean;
  removeAnnouncement: (id?: string | null) => Promise<any>;
  removeAllAnnouncements: () => Promise<any>;
}

export const AnnouncementContext = React.createContext<AnnouncementStore>({
  announcements: [],
  removeAnnouncement: () => Promise.resolve(null),
  removeAllAnnouncements: () => Promise.resolve(null),
  loading: true,
});

export const AnnouncementProvider = ({ children }) => {
  const history = useHistory();
  const user = useContext(UserContext);
  const announcementQuery = useQuery<Announcements>(ANNOUNCEMENTS_QUERY, {
    skip: !user,
  });
  const [announcementRead, announcementReadState] = useMutation(ANNOUNCEMENT_READ_MUTATION);
  const [allAnnouncementRead, allAnnouncementReadState] = useMutation(ALL_ANNOUNCEMENT_READ_MUTATION);
  const announcements = announcementQuery.data?.announcements?.items ?? [];

  const removeAnnouncement = (id?: string | null) => {
    return announcementRead({
      variables: { id },
      refetchQueries: [{ query: ANNOUNCEMENTS_QUERY }],
      awaitRefetchQueries: true,
    });
  };
  const removeAllAnnouncements = () => {
    return allAnnouncementRead({
      refetchQueries: [{ query: ANNOUNCEMENTS_QUERY }],
      awaitRefetchQueries: true,
    });
  };

  const key = 'activity-notification';
  usePublishedEventSubscription(
    useCallback(
      response => {
        if (response?.type === 'AnnouncementCreated') {
          const { heading } = buildAnnouncementTitle(user?.__typename, response?.data?.metadata);
          notification.open({
            key,
            message: <Text bold>{heading}</Text>,
            description: response?.data?.message,
            duration: 0,
            onClick: () => {
              history.push(`/app/my-jobs/manage/redirect/${response?.data?.metadata?.jobPostSlug}`);
              notification.close(key);
            },
          });
          announcementQuery.refetch();
        }
      },
      [announcementQuery, history, user]
    )
  );

  return (
    <AnnouncementContext.Provider
      value={{
        announcements,
        removeAnnouncement,
        removeAllAnnouncements,
        loading: announcementReadState.loading || allAnnouncementReadState.loading,
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  );
};

// @ts-ignore
export default React.memo(AnnouncementProvider);
