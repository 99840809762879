import { useApolloClient } from '@apollo/react-hooks';
import merge from 'lodash.merge';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone-h';
import Absolute from '../../../../components-core/absolute';
import Box from '../../../../components-core/box';
import CloseButton from '../../../../components-core/close-button';
import Container from '../../../../components-core/container';
import Flex from '../../../../components-core/flex';
import { FormItem } from '../../../../components-core/form';
import Icon from '../../../../components-core/icon';
import Input from '../../../../components-core/input';
import OutlineButton from '../../../../components-core/outline-button';
import Relative from '../../../../components-core/relative';
import Text from '../../../../components-core/text';
import TextButton from '../../../../components-core/text-button';
import Editor from '../../../../components-core/wysiwyg/Wysiwyg';
// import SimpleTooltip from '../../../../components/SimpleTooltip';
import BlackTooltip from '../../../../components/SimpleTooltip/BlackTooltip';
import { EMAIL_PHONE_URL_HANDLE_MATCHER } from '../../../../constants';
import { determineFileIcon } from '../../../../utils/fileUtils';
import { handleS3Upload } from '../../../../utils/uploadUtils';
import { SubLabel } from './styles';

const JobDetailsStep = ({
  getFieldDecorator,
  form,
  currentStep,
  payload,
  jobStrength,
  handleNext,
  toggleFooter,
  setPayload,
  showWarning,
  setShowWarning,
  forceHideWarning,
  setForceHideWarning,
}: any) => {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setLoading(true);
      Promise.all(acceptedFiles.map(file => handleS3Upload(file, apolloClient)))
        .then(uploadedFiles => {
          return uploadedFiles.map(file => ({
            key: file?.key,
            name: file?.name,
            mimeType: file?.mimeType,
            size: file?.size,
          }));
        })
        .then(assembledFiles => {
          setPayload(prev => ({
            ...prev,
            details: {
              ...(prev?.details ?? {}),
              files: (prev?.details?.files ?? []).concat(assembledFiles),
            },
          }));
        })
        .finally(() => {
          setLoading(false);
        });
      /*

input FileInput {
  key: ID!
  name: String!
  extension: String
  mimeType: String
  size: Int
}

*/
    },
  });
  const getTextFromRawEditor = value => {
    if (!value) {
      return 0;
    }
    try {
      const rawEditorState = JSON.parse(value);
      const text = rawEditorState.blocks.map(block => block.text.trim()).join('');
      return text.length;
    } catch (error) {
      console.error(error);
    }
  };
  const [charCount, setCharCount] = useState(getTextFromRawEditor(payload.description));

  return (
    <Container maxWidth={540} id="job-details-step">
      <Text fontSize="18px" bold mb={3}>
        Congrats on your Job Post 🎉
      </Text>
      <Text fontSize="16px" bold color="purple">
        Important ⤵
      </Text>
      <Text fontSize="14px" mb={3}>
        Make sure your <i>Job Title, Description, Moodboard</i>, and other details below match your vision for this project
      </Text>
      <FormItem
        label={
          <>
            <SubLabel bold fontSize="16px">
              Job title{' '}
              <BlackTooltip>Tell us what type of creator you need & what job you’re hiring for!</BlackTooltip>
            </SubLabel>
          </>
        }
        mb={4}
      >
        {getFieldDecorator('title', {
          initialValue: payload.title,
          rules: [
            {
              required: currentStep === 1,
              message: "Whoops, don't forget to provide a job title!",
            },
          ],
          onChange: e => setPayload(prev => merge(prev, { title: e.target.value })),
        })(
          <Input
            size="large"
            data-test-id="title"
            placeholder="Enter a job title"
            aria-describedby="job-name-help"
            // autoFocus
          />
        )}
      </FormItem>
      <Relative>
        <FormItem
          label={
            <>
              <SubLabel bold fontSize="16px">
                Description{' '}
                <BlackTooltip>
                  The more information you include, the better! What type of shoot is this? Is the location important to
                  you? Give us all the details!
                </BlackTooltip>
              </SubLabel>

              {showWarning && !forceHideWarning && (
                <Relative
                  p={3}
                  bg="yellow3"
                  color="yellow7"
                  my={3}
                  pr={5}
                  borderColor="yellow3"
                  borderStyle="solid"
                  borderWidth="1px"
                >
                  <Absolute top={2} right={2}>
                    <CloseButton type="button" onClick={() => setForceHideWarning(true)} />
                  </Absolute>
                  <Box>
                    <Text bold fontSize={2} mb={2}>
                      Taking your conversation off the Hub?
                    </Text>
                    <Text fontSize={1}>
                      Including personal contact information goes against our Terms Of Use. Stay safe and secure by
                      keeping all of your messaging on the Hub.
                    </Text>
                  </Box>
                </Relative>
              )}
            </>
          }
          mb={4}
        >
          {getFieldDecorator('description', {
            initialValue: payload.description,

            validate: [
              {
                trigger: 'onBlur',
                rules: [
                  {
                    validator: (field, value, callback) => {
                      if (currentStep !== 1) {
                        return callback();
                      }
                      const count = getTextFromRawEditor(value || payload.description);
                      setCharCount(count);
                      if (count === 0) {
                        return callback();
                      }
                      if (count < 10) {
                        return callback(<>Must be at least 10 characters.</>);
                      }
                      return callback();
                    },
                  },
                ],
              },
            ],
          })(
            <Editor
              onChange={value => {
                // console.log(value)
                if (value) {
                  const matches = value.match(EMAIL_PHONE_URL_HANDLE_MATCHER);
                  if (matches && matches.length > 0) {
                    setShowWarning(true);
                  } else if (showWarning) {
                    setShowWarning(false);
                  }

                  const count = getTextFromRawEditor(value || payload.description);
                  try {
                    setCharCount(count);
                  } catch (error) {
                    console.error(error);
                  }

                  if (count >= 150 && form.getFieldError('description')) {
                    form.validateFields(['description'], {
                      force: true,
                    });
                  }
                } else if (charCount !== 0) {
                  setCharCount(0);
                }
                setPayload(prev => merge(prev, { description: value }));

                // form.validateFields(['description'], {
                //   force: true,
                // });
              }}
              placeholder="Enter a brief overview of your job"
            />
          )}
        </FormItem>
        {form.getFieldError('description') && (
          <Absolute right="10px" top="100%" mt="-55px">
            <Text bold caps color={'#d9d9d9'} fontSize="12px">
              {charCount}
            </Text>
          </Absolute>
        )}
      </Relative>

      <Relative>
        <SubLabel>Upload job briefs and guides so creators understand your project (Optional)</SubLabel>
        <span {...getRootProps()}>
          <input type="file" {...getInputProps()} />
          <OutlineButton type="button" disabled={loading} icon={loading ? 'Spinner' : 'Upload'}>
            Upload
          </OutlineButton>
        </span>
        <Box mt={3}>
          {(payload?.details?.files || []).map((file, i) => (
            <Flex alignItems="center" justifyContent="space-between" mb={2} key={i}>
              <Flex alignItems="center">
                <Icon name={determineFileIcon(file.mimeType)} /> <Text ml={1}>{file.name}</Text>
              </Flex>
              <TextButton
                color="red"
                onClick={() => {
                  setPayload(prev => ({
                    ...prev,
                    details: {
                      ...(prev?.details ?? {}),
                      files: (prev?.details?.files ?? []).filter(f => f.key !== file.key),
                    },
                  }));
                }}
              >
                Delete
              </TextButton>
            </Flex>
          ))}
        </Box>
      </Relative>

      {/*<Flex>*/}
      {/*  <Box width={[1 / 2]} pr={2}>*/}
      {/*    <FormItem label={<SubLabel>Location</SubLabel>} mb={4}>*/}
      {/*      {getFieldDecorator('details.location', {*/}
      {/*        initialValue: get(payload, 'details.location'),*/}
      {/*        onChange: value => {*/}
      {/*          setPayload(prev =>*/}
      {/*            merge(prev, { details: { location: value } })*/}
      {/*          );*/}
      {/*        },*/}
      {/*        rules: [*/}
      {/*          {*/}
      {/*            validator: (rule, value, callback) => {*/}
      {/*              if (currentStep !== 2) {*/}
      {/*                callback();*/}
      {/*                return;*/}
      {/*              }*/}
      {/*              if (!value) {*/}
      {/*                callback();*/}
      {/*              } else {*/}
      {/*                if (!value.locality || !value.region || !value.country) {*/}
      {/*                  callback('Please enter a more specific location');*/}
      {/*                } else {*/}
      {/*                  callback();*/}
      {/*                }*/}
      {/*              }*/}
      {/*            },*/}
      {/*          },*/}
      {/*        ],*/}
      {/*      })(<StyledLocationSelect />)}*/}
      {/*    </FormItem>*/}
      {/*  </Box>*/}
      {/*  <Box width={[1 / 2]} pl={2}>*/}
      {/*    <FormItem*/}
      {/*      label={*/}
      {/*        <SubLabel>*/}
      {/*          Due date*/}
      {/*          <Text as="span" color="red">*/}
      {/*            **/}
      {/*          </Text>*/}
      {/*        </SubLabel>*/}
      {/*      }*/}
      {/*      mb={4}*/}
      {/*    >*/}
      {/*      {getFieldDecorator('details.schedule.dueDate', {*/}
      {/*        initialValue: get(payload, 'details.schedule.dueDate')*/}
      {/*          ? moment(get(payload, 'details.schedule.dueDate'))*/}
      {/*          : null,*/}
      {/*        onChange: dueDate => {*/}
      {/*          setPayload(prev =>*/}
      {/*            merge(prev, { details: { schedule: { dueDate } } })*/}
      {/*          );*/}
      {/*        },*/}
      {/*        rules: [*/}
      {/*          {*/}
      {/*            validator: (field, value, callback) => {*/}
      {/*              if (currentStep !== 2) {*/}
      {/*                return callback();*/}
      {/*              }*/}
      {/*              if (!value) {*/}
      {/*                return callback('Please enter a due date.');*/}
      {/*              }*/}
      {/*              return callback();*/}
      {/*            },*/}
      {/*          },*/}
      {/*        ],*/}
      {/*      })(*/}
      {/*        <DatePicker*/}
      {/*          format="MM/DD/YYYY"*/}
      {/*          style={{ width: '100%' }}*/}
      {/*          // @ts-ignore*/}
      {/*          disabledDate={current => current && current < moment()}*/}
      {/*          showToday={false}*/}
      {/*          // calendarProps={{*/}
      {/*          //   disabledDate: current => current && current < moment(),*/}
      {/*          //   showToday: false,*/}
      {/*          // }}*/}
      {/*          // inputProps={{*/}
      {/*          //   id: 'due-date-calendar-input',*/}
      {/*          //   size: 'large',*/}
      {/*          //   onBlur: () => {*/}
      {/*          //     // if (currentStep === 1) {*/}
      {/*          //     //   form.validateFields(['details.schedule.dueDate'], {*/}
      {/*          //     //     force: currentStep === 1,*/}
      {/*          //     //   });*/}
      {/*          //     // }*/}
      {/*          //   },*/}
      {/*          //   prefix: <Icon name="Calendar" size="1em" />,*/}
      {/*          // }}*/}
      {/*        />*/}
      {/*        // <Input*/}
      {/*        //   size="large"*/}
      {/*        //   placeholder="MM/DD/YYYY"*/}
      {/*        //   prefix={<Icon name="Calendar" size="1em" />}*/}
      {/*        //   cleaveOptions={{*/}
      {/*        //     date: true,*/}
      {/*        //   }}*/}
      {/*        // />*/}
      {/*      )}*/}
      {/*    </FormItem>*/}
      {/*  </Box>*/}
      {/*</Flex>*/}
    </Container>
  );
};

JobDetailsStep.defaultProps = {
  setShowWarning: () => null,
};

export default JobDetailsStep;
