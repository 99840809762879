import gql from 'graphql-tag';

export const JobPostBudgetFragment = gql`
  fragment JobPostBudgetFragment on JobPostBudget {
    fixed
    range {
      min
      max
    }
  }
`;

export default JobPostBudgetFragment;
