import styled from 'styled-components';
import { background, border, color, flex, layout, position, shadow, space, typography, opacity } from 'styled-system';
import theme from '../theme';
import { BoxProps } from '../types';

const Box = styled('div')<BoxProps>(
  background,
  border,
  color,
  flex,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography
);

Box.displayName = 'Box';

Box.defaultProps = {
  theme,
};

export default Box;
