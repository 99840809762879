import gql from 'graphql-tag';
import MediaItemFragment from './MediaItemFragment';

export default gql`
  ${MediaItemFragment}
  fragment CreatorProfileSummaryFragment on Creator {
    adminLevel
    avatar {
      publicId
    }
    financial {
      payout {
        identityStatus
        status
      }
    }
    media(limit: 100) {
      items {
        ...MediaItemFragment
      }
    }
    id
    layerId
    location {
      region
      locality
      country
    }
    name
    promoImages {
      publicId
    }
    skill {
      name
    }
    bio
    user {
      id
      familyName
      givenName
      gender
    }
    social {
      instagram
      twitter
      facebook
      vimeo
      youtube
    }
  }
`;
