import styled from 'styled-components/macro';
import { flexbox } from 'styled-system';
import Box from '../box';
import theme from '../theme';
import { FlexProps } from '../types';

const Flex = styled(Box)<FlexProps>({ display: 'flex' }, flexbox);

Flex.displayName = 'Flex';

Flex.defaultProps = {
  theme,
};

export default Flex;
