import classNames from 'classnames';
import React from 'react';
// import './SkillIcon.less';

// import SkillsetMakupIcon from '../../assets/svg/SkillsetMakup.svg';
const SkillsetMakupIcon = props => (
  <svg width={24} height={24} viewBox="0 0 32 32" fill="none">
    <path
      d="M16 31.5c8.56 0 15.5-6.94 15.5-15.5C31.5 7.44 24.56.5 16 .5 7.44.5.5 7.44.5 16c0 8.56 6.94 15.5 15.5 15.5z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M22.236 21.75l-1.611 2.648c-2.675 3.512-7.014 3.512-9.69 0L9.324 21.75l.593-.582c.465-.454.924-.919 1.36-1.419 1.946-2.232 2.896-.511 4.503-.511 1.607 0 2.637-1.652 4.503.511.434.504.897.965 1.36 1.42l.593.58z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.324 21.75s5.806 3.13 12.913 0M26.29 9.354a4.055 4.055 0 01-8.108 0M22.236 13.408v2.232M19.484 12.33l-1.543 1.544M24.988 12.33l1.544 1.544"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.322 16.12a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"
      fill="currentColor"
    />
  </svg>
);
// import SkillsetModelIcon from '../../assets/svg/SkillsetModel.svg';

const SkillsetModelIcon = props => (
  <svg width={24} height={24} viewBox="0 0 32 32" fill="none">
    <path
      d="M27.32 26.42c-.41-.76-2-1.25-3.49-1.64M8.24 13.86c.38 1.76.78 2.7.53 3.41a3.79 3.79 0 01-.87 1.28.28.28 0 000 .42c.23.18.55.42.75.55.2.13.79 3.79 2 3.76 1.382-.23 2.723-.66 3.98-1.28a.33.33 0 01.45.25l.28 1.68a2.64 2.64 0 01-2.65 2.24c-2.14-.09-4.24-.14-4.36 2.54"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M15 18a9.46 9.46 0 004.43 2.64 3.122 3.122 0 00-.18 1.07 3.24 3.24 0 103.23-3.24C23.17 16.2 25 9.16 20.06 6.2c-4-2.38-7.55-1.6-9.76.4a5.43 5.43 0 00-.63.64 11.21 11.21 0 00-3 6.47l4.91-.2c.44.67.93 1.42 1.47 2.17"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M12.9 16.51s.43-2 1.48-1.2c1.55 1.22 0 3.15 0 3.15"
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M24.611 3.112A15.5 15.5 0 107.39 28.887 15.5 15.5 0 0024.61 3.112z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// import SkillsetPhotographerIcon from '../../assets/svg/SkillsetPhotographer.svg';
const SkillsetPhotographerIcon = props => (
  <svg width={24} height={24} viewBox="0 0 32 32" fill="none">
    <path
      d="M15.999 31.5c8.56 0 15.5-6.94 15.5-15.5 0-8.56-6.94-15.5-15.5-15.5C7.439.5.5 7.44.5 16c0 8.56 6.94 15.5 15.499 15.5z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.884 11l.866 1.681a.513.513 0 00.456.279h3.99c.536 0 .972.435.972.971v10.776a.972.972 0 01-.971.972H7.803a.971.971 0 01-.972-.972V13.931c0-.536.434-.971.972-.971h4.458a.514.514 0 00.468-.303l.735-1.633"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.349 18.886a4.186 4.186 0 11-8.373-.001 4.186 4.186 0 018.373 0zM21.64 10.534H10.686a.288.288 0 01-.288-.287v-5.76c0-.159.128-.287.288-.287H21.64c.16 0 .287.128.287.288v5.759a.288.288 0 01-.287.287z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// import SkillsetStylistIcon from '../../assets/svg/SkillsetStylist.svg';

const SkillsetStylistIcon = props => (
  <svg width={24} height={24} viewBox="0 0 32 32" fill="none">
    <path
      d="M15.999 31.5c8.56 0 15.5-6.94 15.5-15.5 0-8.56-6.94-15.5-15.5-15.5C7.439.5.5 7.44.5 16c0 8.56 6.94 15.5 15.499 15.5z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.25 16.655H7.245a.979.979 0 01-.392-1.875l8.05-3.526 5.379 2.356M14.904 11.254v-.59c0-.569-.318-1.088-.823-1.345a1.511 1.511 0 01-.825-1.344v-.798a1.978 1.978 0 013.955 0v.298"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M23.01 16.171c-.69.299-1.283.79-1.692 1.426-.982 1.528-1.583 4.005-2.036 4.834-.366.667-.984 1.068-1.647 1.068h-7.432s-.615-.777.293-1.235c.713-.361 2.26-1.663 3.295-2.2.298-.155.62-.236.944-.236h.328c.688 0 1.342-.356 1.795-.98l5.952-8.654c.157-.216.422-.257.618-.097a4.285 4.285 0 011.57 3.378c0 .997-.193 1.44-.497 1.793a2.515 2.515 0 01-.937.663l-.555.24z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M24.043 16.784v5.965" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
// import SkillsetVideographerIcon from '../../assets/svg/SkillsetVideographer.svg';
const SkillsetVideographerIcon = props => (
  <svg width={24} height={24} viewBox="0 0 32 32" fill="none">
    <path
      d="M16 31.5c8.56 0 15.5-6.94 15.5-15.5C31.5 7.44 24.56.5 16 .5 7.44.5.5 7.44.5 16c0 8.56 6.94 15.5 15.5 15.5z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M11.414 23.09H8.09c-.308 0-.559-.228-.559-.51v-7.067c0-.281.25-.51.56-.51H20.6c.308 0 .559.229.559.51v1.251h1.867a.58.58 0 00.208-.039l1.457-.575a.28.28 0 01.382.26v5.74a.28.28 0 01-.41.247l-1.61-.848a.576.576 0 00-.263-.065H21.16v1.096c0 .282-.25.51-.558.51H11.414z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M14.004 11.464a3.54 3.54 0 11-7.079 0 3.54 3.54 0 017.08 0z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M22.074 10.969a4.035 4.035 0 11-8.07-.001 4.035 4.035 0 018.07 0z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M19 19h-8.5" stroke="currentColor" strokeLinecap="round" />
  </svg>
);

function determineSkillIcon(skill) {
  if (skill === 'MAKEUP_ARTIST') {
    return <SkillsetMakupIcon style={{ width: 30, height: 30, fill: 'white' }} />;
  }
  if (skill === 'MODEL') {
    return <SkillsetModelIcon style={{ width: 30, height: 30, fill: 'white' }} />;
  }
  if (skill === 'PHOTOGRAPHER') {
    return <SkillsetPhotographerIcon style={{ width: 30, height: 30, fill: 'white' }} />;
  }
  if (skill === 'STYLIST') {
    return <SkillsetStylistIcon style={{ width: 30, height: 30, fill: 'white' }} />;
  }
  if (skill === 'VIDEOGRAPHER') {
    return <SkillsetVideographerIcon style={{ width: 30, height: 30, fill: 'white' }} />;
  }
  return null;
}

const SkillIcon = ({ skill, text, className, ...rest }: any) => {
  return (
    <span className={classNames('inline-flex', className)}>
      {determineSkillIcon(skill)}
      {text && <span className="pl-1 capitalize">{text.toLowerCase()}</span>}
    </span>
  );
};

SkillIcon.defaultProps = {
  skill: null,
  text: null,
};

// const SkillIconWrap = styled.span`
//   display: inline-block;
//   width: 30px;
//   height: 30px;
//   .icon {
//     font-size: 30px !important;
//     opacity: 1 !important;
//     path {
//     }
//   }
// `;

export default SkillIcon;
