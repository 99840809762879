import React from 'react';
import Svg from '../Svg';
export const SvgClose = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.53 3.53a.75.75 0 00-1.06 0L7 6 4.53 3.53a.75.75 0 10-1.06 1.06l2.47 2.47-2.48 2.48a.75.75 0 001.06 1.061L7 8.121l2.48 2.48a.75.75 0 101.06-1.06L8.06 7.06l2.47-2.47a.75.75 0 000-1.06z"
      fill="currentColor"
    />
  </Svg>
);
SvgClose.isIcon = true;
SvgClose.defaultProps = {
  size: 24,
};
export default SvgClose;
