import { Avatar, Card, Skeleton } from 'antd';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { track } from '../../analytics';

import { buildCloudinaryImageUrl } from '../../utils/cloudinaryUtils';
import { renderLocation } from '../../utils/creatorUtils';
import BookmarkButton from '../BookmarkButton/BookmarkButton';
import CreatorCardCover from '../CreatorCardCover/CreatorCardCover';
import CreatorSkillIcons from '../SkillIcon/CreatorSkillIcons';
import './CreatorCard.less';
import cloudinaryTransformations from '../../constants/cloudinaryTransformations';

interface CreatorCardProps {
  creator?: any;
  loading?: boolean;
  onClick?: () => void;
  index?: number;
}

const CreatorCard: FC<CreatorCardProps> = ({ creator, loading, onClick, index }) => {
  const location = useLocation();
  const trackClick = () => {
    if (location.pathname.startsWith('/app/search')) {
      track('Creator Search: Result clicked', {
        order: (index || 0) + 1,
        id: creator.id,
        name: creator.name,
        ...(creator.rating ? { rating: creator.rating.internal } : {}),
      });
    }
  };

  const creatorSlug = creator?.slug;

  return (
    <Link to={`/app/creator/${creatorSlug}`} onClick={trackClick} className="creator-card-link">
      <Card className="creator-card" cover={<CreatorCardCover creator={creator} loading={loading} />}>
        {creator && <BookmarkButton creator={creator} />}
        <Skeleton loading={loading} avatar active paragraph={false}>
          <Card.Meta
            avatar={
              <Avatar
                size={63}
                src={creator ? buildCloudinaryImageUrl(creator.avatar.publicId, cloudinaryTransformations.avatar) : ''}
                style={{
                  marginTop: '-32px',
                  border: '3px solid white',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  width: '63px',
                  height: '63px',
                }}
              />
            }
            title={creator ? creator.name : null}
          />
        </Skeleton>

        <div className="creator-card-actions">
          <Skeleton loading={loading} title paragraph={false}>
            <span className="creator-card-location">{renderLocation(creator)}</span>
          </Skeleton>

          {/*<Skeleton loading={loading} title paragraph={false}>*/}
          {/*  <span className="creator-card-follower-count">*/}
          {/*    {renderFollowerCount(creator)}*/}
          {/*  </span>*/}
          {/*</Skeleton>*/}

          <Skeleton loading={loading} title paragraph={false}>
            <CreatorSkillIcons creator={creator} />
          </Skeleton>
        </div>
      </Card>
    </Link>
  );
};

CreatorCard.defaultProps = {
  loading: false,
  onClick: () => {},
  index: -1,
};

export default CreatorCard;
