import React from 'react';
import Svg from '../Svg';
export const SvgImage = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 22 22" fill="none" {...props}>
    <path
      clipRule="evenodd"
      d="M9.345 6.39c0 1.365-1.064 2.471-2.377 2.471-1.313 0-2.378-1.106-2.378-2.472 0-1.365 1.065-2.473 2.378-2.473 1.313 0 2.377 1.108 2.377 2.473z"
      stroke="currentColor"
    />
    <path
      d="M2.186 17.19l5.574-4.554 2.782 2.188 6.382-6.74L20.612 11"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 1h-5A7.5 7.5 0 001 8.5v5A7.5 7.5 0 008.5 21h5a7.5 7.5 0 007.5-7.5v-5A7.5 7.5 0 0013.5 1z"
      stroke="currentColor"
    />
  </Svg>
);
SvgImage.isIcon = true;
SvgImage.defaultProps = {
  size: 24,
};
export default SvgImage;
