import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Box from '../box';
import theme from '../theme';
import { HideProps } from '../types';

const getMaxWidth = (px: string) => parseInt(px, 10) - 1;

const breakpoints = (props: HideProps) => ({
  xs: `@media screen and (max-width: ${getMaxWidth(props.theme && props.theme.breakpoints[0])}px)`,
  sm: `@media screen and (min-width: ${props.theme && props.theme.breakpoints[0]}) and (max-width: ${getMaxWidth(
    props.theme && props.theme.breakpoints[1]
  )}px)`,
  md: `@media screen and (min-width: ${props.theme && props.theme.breakpoints[1]}) and (max-width: ${getMaxWidth(
    props.theme && props.theme.breakpoints[2]
  )}px)`,
  lg: `@media screen and (min-width: ${props.theme && props.theme.breakpoints[2]}) and (max-width: ${getMaxWidth(
    props.theme && props.theme.breakpoints[3]
  )}px)`,
  xl: `@media screen and (min-width: ${props.theme && props.theme.breakpoints[3]})`,
  xxl: `@media screen and (min-width: ${props.theme && props.theme.breakpoints[4]})`,
  print: `@media print`,
});

const hidden = (key: string) => (props: HideProps) =>
  props[key]
    ? {
        [breakpoints(props)[key]]: {
          display: 'none',
        },
      }
    : null;

const Hide = styled(Box)<HideProps>`
  ${hidden('xs')} 
  ${hidden('sm')} 
  ${hidden('md')} 
  ${hidden('lg')} 
  ${hidden('xl')} 
  ${hidden('xxl')} 
  ${hidden('print')};
`;

// @ts-ignore
Hide.propTypes = {
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  xxl: PropTypes.bool,
  print: PropTypes.bool,
};

Hide.defaultProps = {
  theme,
};

Hide.displayName = 'Hide';

export default Hide;
