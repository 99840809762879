import moment from 'moment';
import { removeEmpty } from './objectUtils';

export const determineJobStrength = jobPost => {
  let jobStrength = '85%';

  if (!jobPost) {
    return jobStrength;
  }

  const hasMoodBoard = (jobPost?.details?.moodBoard ?? []).length > 0;
  const hasGuidelines =
    (jobPost?.details?.guidelines?.do ?? []).length > 0 || (jobPost?.details?.guidelines?.dont ?? []).length > 0;
  const hasUsageRights = !!jobPost?.details?.usage?.type;

  if (hasMoodBoard) {
    jobStrength = `${parseInt(jobStrength, 10) + 5}%`;
  }
  if (hasGuidelines) {
    jobStrength = `${parseInt(jobStrength, 10) + 5}%`;
  }
  if (hasUsageRights) {
    jobStrength = `${parseInt(jobStrength, 10) + 5}%`;
  }
  return jobStrength;
};

export function buildJobPostInput(values: any = {}): any {
  const budgeRangeJSON = values?.details?.budget?.range;
  const scheduleDueDate = values?.details?.schedule?.dueDate;
  const location = values?.details?.location;
  const styles = values?.details?.styles;
  const files = values?.details?.files;

  const updatedValues: any = {
    ...values,
    description: values.description,
    title: values.title,
    skill: values.skill,
    details: {
      ...(styles ? { styles } : {}),
      ...(files ? { files } : {}),
      budget: {
        range: {},
      },
      ...(location ? { location } : {}),
    },
    visibility: {
      public: true,
      listed: true,
    },
  };

  if (typeof budgeRangeJSON === 'string') {
    updatedValues.details.budget.range = JSON.parse(budgeRangeJSON);
  } else {
    updatedValues.details.budget.range = budgeRangeJSON;
  }

  if (scheduleDueDate) {
    if (typeof scheduleDueDate === 'string') {
      const unixEpochMs = moment(new Date(scheduleDueDate)).unix() * 1000 + '';
      updatedValues.details.schedule = { dueDate: unixEpochMs };
    } else if (scheduleDueDate._isAMomentObject) {
      updatedValues.details.schedule = {
        dueDate: scheduleDueDate.unix() * 1000 + '',
      };
    }
  }
  const moodBoard = values?.details?.moodBoard;
  if (moodBoard && Array.isArray(moodBoard)) {
    updatedValues.details.moodBoard = moodBoard;
  }

  const guidelines = values?.details?.guidelines;
  if (guidelines) {
    if (Array.isArray(guidelines.do) || Array.isArray(guidelines.dont)) {
      updatedValues.details.guidelines = guidelines;
    }
  }
  const usage = values?.details?.usage ?? {};
  if (usage && usage.type) {
    if (usage.type !== 'OTHER' && usage.description) {
      delete usage.description;
    }
    updatedValues.details.usage = usage;
  }

  removeEmpty(updatedValues);

  return updatedValues;
}

export function determineJobPage(jobPost: any, aslug?: string, userType?: string) {
  const slug = aslug || jobPost.jobSlug || jobPost.slug;

  if (jobPost.status === 'OPEN') {
    if (userType === 'Creator') {
      const to = `/my-jobs/manage/overview/${slug}`;
      return {
        to,
        as: `/app${to}`,
      };
    }
    if (jobPost.proposalsCount > 0) {
      const to = `/my-jobs/manage/bids/${slug}`;
      return {
        to,
        as: `/app${to}`,
      };
    } else {
      const to = `/my-jobs/manage/invite/${slug}`;
      return {
        to,
        as: `/app${to}`,
      };
    }
  } else if (jobPost.status === 'CONTRACT' || jobPost.status === 'FILLED') {
    if (jobPost.status === 'FILLED' && userType === 'Creator') {
      const to = `/my-jobs/manage/assets/${slug}`;
      return {
        to,
        as: `/app${to}`,
      };
    }
    const to = `/my-jobs/manage/contract/${slug}`;
    return {
      to,
      as: `/app${to}`,
    };
  } else if (jobPost.status === 'COMPLETE' || jobPost.status === 'ASSETS_READY') {
    const to = `/my-jobs/manage/assets/${slug}`;
    return {
      to,
      as: `/app${to}`,
    };
  } else {
    const to = `/my-jobs/manage/overview/${slug}`;
    return {
      to,
      as: `/app${to}`,
    };
  }
}

export const jobStyleDefaults = [
  {
    label: 'Portrait',
    value: ['Beauty / Makeup', 'Diversity', 'Lifestyle / Portrait', 'Studio / Portrait', 'Portrait/Unique'],
  },
  {
    label: 'Lifestyle',
    value: [
      'Lifestyle / Portrait',
      'Lifestyle / Product',
      'Active / Athletic',
      'Fashionista / Blogger',
      'Lifestyle/Unique',
    ],
  },
  { label: 'Food', value: ['Food', 'Flatlay', 'Food/Unique'] },
  {
    label: 'Fashion',
    value: ['Highbrow / Editorial', 'Swimwear / Beach', 'Urban / Streetwear', 'Fashion/Unique'],
  },
  {
    label: 'Studio',
    value: ['Studio / Portrait', 'Studio / Product', 'Studio/Unique'],
  },
  {
    label: 'Product',
    value: ['Studio / Product', 'Lifestyle / Product', 'Product/Unique'],
  },
  { label: 'Wedding', value: ['Wedding'] },
  { label: 'Event', value: ['BoHo / Festival', 'Neon / Night'] },
  {
    label: 'Travel',
    value: ['Travel / Outdoor', 'Mountains & Fields', 'Beaches & Oceans', 'Snow & Mountains'],
  },
  {
    label: 'Architecture',
    value: ['Interiors', 'Urban Cityscape', 'Exteriors (Architecture)'],
  },
];
export const jobStyleDefaultsWithoutUnique = [
  {
    label: 'Portrait',
    value: ['Beauty / Makeup', 'Diversity', 'Lifestyle / Portrait', 'Studio / Portrait'],
  },
  {
    label: 'Lifestyle',
    value: ['Lifestyle / Portrait', 'Lifestyle / Product', 'Active / Athletic', 'Fashionista / Blogger'],
  },
  { label: 'Food', value: ['Food', 'Flatlay'] },
  {
    label: 'Fashion',
    value: ['Highbrow / Editorial', 'Swimwear / Beach', 'Urban / Streetwear'],
  },
  {
    label: 'Studio',
    value: ['Studio / Portrait', 'Studio / Product'],
  },
  {
    label: 'Product',
    value: ['Studio / Product', 'Lifestyle / Product'],
  },
  { label: 'Wedding', value: ['Wedding'] },
  { label: 'Event', value: ['BoHo / Festival', 'Neon / Night'] },
  {
    label: 'Travel',
    value: ['Travel / Outdoor', 'Mountains & Fields', 'Beaches & Oceans', 'Snow & Mountains'],
  },
  {
    label: 'Architecture',
    value: ['Interiors', 'Urban Cityscape', 'Exteriors (Architecture)'],
  },
];

export const jobStyleTranslator = (jobPostStyles: any[] = [], unique = true): any => {
  const found: any[] = [];
  (unique ? jobStyleDefaults : jobStyleDefaultsWithoutUnique).forEach(styleGroup => {
    let count = 0;
    styleGroup.value.forEach(style => {
      if (jobPostStyles.indexOf(style) !== -1) {
        count++;
      }
    });
    if (count === styleGroup.value.length) {
      found.push(styleGroup);
    }
  });
  return found;
};
