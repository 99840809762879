import styled from 'styled-components/macro';
import { layout, space } from 'styled-system';
import theme from '../theme';
import { ContainerProps } from '../types';

const maxWidth = (props: ContainerProps) =>
  props.maxWidth ? { maxWidth: `${props.maxWidth}px` } : { maxWidth: props.theme && props.theme!.maxContainerWidth };

const Container = styled.div<ContainerProps>`
  margin-left: auto;
  margin-right: auto;

  ${space}
  ${layout}
  ${maxWidth};
`;

Container.defaultProps = {
  theme,
};

Container.displayName = 'Container';

export default Container;
