import gql from 'graphql-tag';

export default gql`
  query CreatorProfileDetailsQuery {
    self {
      ... on Creator {
        id
        name
        avatar {
          publicId
        }
        rating {
          overall
          internal
          tier
        }
        skill {
          name
        }
        otherSkills {
          name
        }
        media(limit: 100) {
          items {
            type
            publicId
          }
        }
        website
        user {
          id
          name
          givenName
          familyName
          gender
        }
      }
    }
  }
`;
