import React from 'react';
import Svg from '../Svg';
export const SvgGlobe = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <g clipPath="url(#globe_svg__clip0)" stroke="currentColor">
      <circle cx={7} cy={7} r={6.5} />
      <path d="M10.5 7c0 1.862-.432 3.523-1.106 4.702C8.713 12.894 7.848 13.5 7 13.5c-.848 0-1.713-.606-2.394-1.798C3.932 10.523 3.5 8.862 3.5 7s.432-3.523 1.106-4.702C5.287 1.106 6.152.5 7 .5c.848 0 1.713.606 2.394 1.798C10.068 3.477 10.5 5.138 10.5 7z" />
      <path d="M1.632 4C2.916 4.876 4.844 5.433 7 5.433S11.084 4.876 12.368 4M1.632 10C2.916 9.124 4.844 8.567 7 8.567s4.084.557 5.368 1.433M6.75 0v14" />
    </g>
    <defs>
      <clipPath id="globe_svg__clip0">
        <rect width="1em" height="1em" fill="#fff" />
      </clipPath>
    </defs>
  </Svg>
);
SvgGlobe.isIcon = true;
SvgGlobe.defaultProps = {
  size: 24,
};
export default SvgGlobe;
