import React from 'react';
import Svg from '../Svg';
export const SvgStar = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.81 2.385a.2.2 0 01.38 0l1.786 5.496a.2.2 0 00.19.138h5.778a.2.2 0 01.117.362l-4.674 3.396a.2.2 0 00-.073.224l1.786 5.495a.2.2 0 01-.308.223l-4.674-3.396a.2.2 0 00-.236 0L5.208 17.72a.2.2 0 01-.308-.223L6.686 12a.2.2 0 00-.073-.224L1.938 8.38a.2.2 0 01.118-.362h5.778a.2.2 0 00.19-.138L9.81 2.385z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </Svg>
);
SvgStar.isIcon = true;
SvgStar.defaultProps = {
  size: 24,
};
export default SvgStar;
