import React from 'react';
import Embed from './components/Embed';
import cloudinaryTransformations from './constants/cloudinaryTransformations';
import { MediaTypeEnum } from './graphql/types/graphql-global-types';
import { buildCloudinaryImageUrl } from './utils/cloudinaryUtils';

export default function PortfolioMediaItem({
  type,
  publicId,
  url,
}: {
  type: MediaTypeEnum;
  publicId?: string;
  url?: string;
}) {
  let item;
  if (type === MediaTypeEnum.YOUTUBE || type === MediaTypeEnum.VIMEO) {
    item = <Embed thumb type={type} publicId={publicId} />;
  }
  if (type === MediaTypeEnum.VIDEO) {
    item = <Embed thumb type={type} url={url} />;
  }
  if (type === MediaTypeEnum.S3_PUBLIC_VIDEO) {
    item = <Embed thumb type={type} url={publicId} />;
  }
  if (type === MediaTypeEnum.IMAGE) {
    if (typeof publicId === 'string') {
      item = (
        <img
          className="rounded"
          alt={publicId}
          src={buildCloudinaryImageUrl(publicId, cloudinaryTransformations.portfolioThumbnail)}
        />
      );
    }
  }
  return <div className="flex">{item}</div>;
}
