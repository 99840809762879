import gql from 'graphql-tag';

// export interface JobPostSchedule {
//   dueDate: string;
//   shootDate: string;
//   eventDate: string;
// }

export const JobPostScheduleFragment = gql`
  fragment JobPostScheduleFragment on JobPostSchedule {
    dueDate
    shootDate
    eventDate
  }
`;

export default JobPostScheduleFragment;
