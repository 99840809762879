import React from 'react';
import { Spinner } from '../../components-core/icon/components';

const PageLoadIndicator = props => (
  <div
    style={{
      backgroundColor: '#FAFAFA',
      color: '#6ba8bf',
      height: '100vh',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Spinner size={64} />
  </div>
);

export default PageLoadIndicator;
