import styled from 'styled-components/macro';
import Absolute from '../../../../components-core/absolute';
import Text from '../../../../components-core/text';
import LocationSelect from '../../../../components/LocationSelect/LocationSelect';

export const SubLabel = styled(Text)`
  margin-bottom: 9px;
`;

export const Life = styled(Absolute)<any>`
  max-width: 100%;
  transition: width 0.3s ease;
`;

export const StyledLocationSelect = styled(LocationSelect)`
  .rc-select-selection--single {
    height: 40px;
  }
`;
