import React, { Fragment } from 'react';
import SkillIcon from './SkillIcon';

const CreatorSkillIcons = ({ creator }) => (
  <div className="skill-icons space-x-1">
    {creator && (
      <Fragment>
        <SkillIcon skill={creator.skill && creator.skill.name} />
        {(creator.otherSkills || []).map((otherSkill, i) =>
          i < 2 ? <SkillIcon key={`${creator.id}-otherSkill-${otherSkill.name}-${i}`} skill={otherSkill.name} /> : null
        )}
      </Fragment>
    )}
  </div>
);

export default CreatorSkillIcons;
