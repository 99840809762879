import baseTheme from '@thehhub/web-theme';
import PropTypes from 'prop-types';
import React, { FunctionComponent } from 'react';
import { createGlobalStyle, DefaultTheme, ThemeProvider as StyledThemeProvider } from 'styled-components/macro';

export const Base = createGlobalStyle<{ theme: any }>`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    
    font-family: ${props => props.theme!.fonts.sans};
    line-height: ${props => props.theme!.lineHeights.standard};
    font-weight: ${props => props.theme!.fontWeights.medium};

    background: ${props => props.theme!.colors.pageBackground};
    color: ${props => props.theme!.colors.text};

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const HThemeProvider: FunctionComponent<{
  theme?: DefaultTheme;
}> = ({ theme, children, ...props }) => (
  <StyledThemeProvider
    theme={{
      ...baseTheme,
      ...theme,
    }}
  >
    <>
      <Base {...props} />
      {children}
    </>
  </StyledThemeProvider>
);

HThemeProvider.propTypes = {
  theme: PropTypes.any,
};

export default HThemeProvider;
