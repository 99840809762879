import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import StarFilled from '@ant-design/icons/StarFilled';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import YoutubeOutlined from '@ant-design/icons/YoutubeOutlined';
import { useMutation } from '@apollo/react-hooks';
import { Alert, Button, Modal, notification, Popconfirm, Upload } from 'antd';
import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { track } from '../../analytics';
import config from '../../config';
import CreatorUpdateMutation from '../../graphql/mutations/CreatorUpdate';
import SelfQuery from '../../graphql/queries/SelfQuery';
import { MediaTypeEnum } from '../../graphql/types/graphql-global-types';
import { buildCloudinaryImageUrl } from '../../utils/cloudinaryUtils';
import { removeTypename } from '../../utils/objectUtils';
import AddVideoModal from '../AddVideoModal';
import CreatorCardPreviewModal from '../CreatorCardPreviewModal';
import CREATOR_PROFILE_QUERY from '../CreatorPortfolio/CreatorPortfolioQuery';
import Embed from '../Embed';
import Headline from '../Headline/Headline';
import MediaUploadButton from '../MediaUploadButton';
import './CreatorPortfolioManagement.less';
// import {
//   arrayMove,
//   SortableContainer,
//   SortableElement,
//   sortableElement,
//   sortableContainer,
// } from 'react-sortable-hoc';

// @ts-ignore
const SortableItem = sortableElement(({ value: { type, publicId }, onRemove, index, items, key }) => (
  <div className="sortable-item" key={key}>
    {items.length >= 3 && (
      <Popconfirm title="Are you sure?" onConfirm={onRemove} okText="Delete" cancelText="Never-mind">
        <Button shape="circle" size="small" icon={<DeleteOutlined />} danger className="delete-button" />
      </Popconfirm>
    )}
    {(index === 0 || index === 1) && <StarFilled className="promo-pic-icon" />}
    {type === MediaTypeEnum.IMAGE && (
      <img alt={`Portfolio Item ${index + 1}`} src={buildCloudinaryImageUrl(publicId, 'w_100')} />
    )}
    {(type === MediaTypeEnum.YOUTUBE || type === MediaTypeEnum.VIMEO || type === MediaTypeEnum.S3_PUBLIC_VIDEO) && (
      <Embed thumb type={type} publicId={publicId} />
    )}
  </div>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul style={{ padding: 0 }}>{children}</ul>;
});

const CreatorPortfolioManagement = ({ media, trigger }: any) => {
  const [addVideoOpen, setAddVideoOpen] = useState(false);
  const [state, setState] = useState({
    visible: false,
    items: media,
    changesMade: false,
    loading: false,
    saving: false,
  });
  useEffect(() => {
    setState(prev => ({ ...prev, items: media }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [creatorUpdate] = useMutation(CreatorUpdateMutation);

  const handleImageUpload = info => {
    console.log(info);
    if (info.file.status === 'uploading') {
      setState(prev => ({ ...prev, loading: true }));
      return;
    }
    if (info.file.status === 'done') {
      const publicId = info.file.response.public_id;
      setState(prevState => ({
        ...prevState,
        loading: false,
        changesMade: true,
        items: prevState.items.concat([{ publicId, type: MediaTypeEnum.IMAGE }]),
      }));
      track('Creator Portfolio Image Uploaded', {
        image: buildCloudinaryImageUrl(publicId),
      });
      // setNewPublicId(info.file.response.public_id);
      // setLoading(false);
    }
  };

  const handleSave = async () => {
    const { items } = state;
    const variables = {
      media: items.map(({ id, createdAt, ...item }) => ({
        ...item,
      })),
    };

    setState(prev => ({ ...prev, saving: true }));
    try {
      await creatorUpdate({
        variables: removeTypename(variables),
        refetchQueries: [{ query: CREATOR_PROFILE_QUERY }, { query: SelfQuery }],
        awaitRefetchQueries: true,
      });
      notification.success({
        message: 'Changes saved!',
      });
      setState(prev => ({ ...prev, saving: false, visible: false }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = index => () => {
    const clone = [...state.items];
    const newItems = clone.filter((item, i) => i !== index);
    setState(prevState => ({
      ...prevState,
      items: newItems,
      changesMade: true,
    }));
  };

  const handleAdd = item => {
    const clone = [...state.items];
    setState(prevState => ({
      ...prevState,
      items: clone.concat([item]),
      changesMade: true,
    }));
  };
  const handleItemClient = () => setState(prev => ({ ...prev, visible: true }));

  const el = trigger ? (
    React.cloneElement(trigger, { onClick: handleItemClient })
  ) : (
    <Button
      // htmlType="button"

      icon={<EditOutlined />}
      type="primary"
      // size="large"
      onClick={() => setState(prev => ({ ...prev, visible: true }))}
    >
      <span className="uppercase font-bold text-sm">Edit Portfolio</span>
    </Button>
  );

  return (
    <div className="creator-portfolio-management">
      {el}

      <Modal
        destroyOnClose
        visible={state.visible}
        width="80vw"
        centered
        bodyStyle={{
          maxHeight: '80vh',
          overflow: 'auto',
        }}
        title={
          <div className="creator-portfolio-management__title">
            <Headline size="2xl mb-3">Manage Portfolio</Headline>

            <div className="creator-portfolio-management__controls">
              {/*{state.items.length < 18 && (*/}
              <>
                <Upload
                  accept="image/*"
                  multiple
                  name="file"
                  action={config.CLOUDINARY_API_UPLOAD_URL}
                  data={{
                    upload_preset: config.CLOUDINARY_API_UPLOAD_PRESET,
                  }}
                  showUploadList={false}
                  onChange={handleImageUpload}
                >
                  <Button loading={state.loading} icon={<UploadOutlined />}>
                    Upload Images
                  </Button>
                </Upload>
                <MediaUploadButton
                  accept="video/*"
                  icon={<VideoCameraOutlined />}
                  onChange={value => {
                    setState(prev => ({
                      ...prev,
                      changesMade: true,
                      items: prev.items.concat(value),
                    }));
                  }}
                  // onClick={() => setAddVideoOpen(true)}
                >
                  Upload Video
                </MediaUploadButton>

                <Button onClick={() => setAddVideoOpen(true)} icon={<YoutubeOutlined />}>
                  Embed Video
                </Button>
                <AddVideoModal
                  onSave={mediaItem => {
                    handleAdd(mediaItem);
                    setAddVideoOpen(false);
                  }}
                  visible={addVideoOpen}
                  onCancel={() => setAddVideoOpen(false)}
                />
                {/*<CreatorInstagramMediaModal*/}
                {/*  onAdd={items => {*/}
                {/*    setState(prevState => ({*/}
                {/*      ...prevState,*/}
                {/*      changesMade: true,*/}
                {/*      items: prevState.items.concat(items),*/}
                {/*    }));*/}
                {/*  }}*/}
                {/*/>{' '}*/}
                <CreatorCardPreviewModal />
              </>
              {/*)}*/}
            </div>
          </div>
        }
        okText="Save Updates"
        okButtonProps={{
          disabled: !state.changesMade,
          loading: state.saving,
          size: 'large',
          icon: <SaveOutlined />,
        }}
        cancelButtonProps={{
          size: 'large',
        }}
        onOk={handleSave}
        onCancel={() => {
          setState(prev => ({
            ...prev,
            items: media,
            visible: false,
          }));
        }}
      >
        {/*{state.items.length === 18 && (*/}
        {/*  <Alert*/}
        {/*    type="success"*/}
        {/*    showIcon*/}
        {/*    message="Congrats! Your portfolio is full."*/}
        {/*  />*/}
        {/*)}*/}
        {state.items.length <= 2 && (
          <Alert
            type="warning"
            showIcon
            message="Heads up!"
            description="You need at least 2 photos - either upload more photos to save or close this window to cancel your changes"
          />
        )}

        <SortableContainer
          axis="xy"
          // items={}
          onSortEnd={({ oldIndex, newIndex }) => {
            const moved = arrayMove(state.items, oldIndex, newIndex);
            setState(prev => ({ ...prev, items: moved, changesMade: true }));
          }}
        >
          <div className="sortable-grid">
            {(state.items || [])
              // .filter((item, i) => i < 18)
              .map((value, index) => (
                <SortableItem
                  items={state.items}
                  key={`item-${value.publicId}`}
                  index={index}
                  onRemove={
                    // () => null
                    handleRemove(index)
                  }
                  value={value}
                />
              ))}
          </div>
        </SortableContainer>
      </Modal>
    </div>
  );
};

export default CreatorPortfolioManagement;
