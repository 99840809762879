import React from 'react';
import Svg from '../Svg';
export const SvgHamburger = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 29 18" fill="none" {...props}>
    <path d="M28 1H1M28 9H1M28 17H1" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
  </Svg>
);
SvgHamburger.isIcon = true;
SvgHamburger.defaultProps = {
  size: 24,
};
export default SvgHamburger;
