import { Radio } from 'antd';
import merge from 'lodash.merge';
import React from 'react';
import Container from '../../../../components-core/container';
import { FormItem } from '../../../../components-core/form';
import Text from '../../../../components-core/text';
import { jobStyleDefaultsWithoutUnique } from '../../../../utils/jobUtils';

const SelectStyleStep = ({
  getFieldDecorator,
  form,
  currentStep,
  payload,
  jobStrength,
  handleNext,
  toggleFooter,
  setPayload,
  className,
}: any) => {
  const radioStyle: any = {
    height: '30px',
    lineHeight: '30px',
    float: 'left',
    width: '45%',
  };
  return (
    <Container className={className} maxWidth={468} id="select-style-step">
      <Text fontSize="18px" bold>
        Style of shoot
      </Text>
      <Text mt={2} mb={3} fontSize="16px" color="#6F6F6F">
        Select the type of images you're looking for. You can always add more details about this in your job
        description.
      </Text>

      <FormItem mb={4} width={3 / 4}>
        {getFieldDecorator('details.styles', {
          initialValue: payload?.details?.styles ?? [],
          rules: [
            {
              required: currentStep === 1,
              message: 'Please select a main style.',
            },
          ],
          onChange: e => {
            const styles = e.target.value;
            setPayload(prev => merge(prev, { details: { styles } }));
          },
        })(
          <Radio.Group
          // boxProps={{
          //   width: [1, 1, 1, 1 / 2],
          //   mr: 0,
          //   mb: 1,
          // }}
          >
            {(jobStyleDefaultsWithoutUnique || []).map((style, i) => (
              <Radio
                style={radioStyle}
                key={i}
                value={JSON.stringify(style.value)}
                // width={[1 / 2]}
                // display="block"
              >
                {style.label}
              </Radio>
            ))}
          </Radio.Group>
        )}
      </FormItem>
    </Container>
  );
};

export default SelectStyleStep;
