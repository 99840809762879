import React from 'react';
import styled from 'styled-components/macro';
import { MediaTypeEnum } from '../graphql/types/graphql-global-types';

function PlayIcon(props) {
  return (
    <svg
      viewBox="64 64 896 896"
      data-icon="play-circle"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372zm164.1 378.2L457.7 677.1a8.02 8.02 0 01-12.7-6.5V353a8 8 0 0112.7-6.5l218.4 158.8a7.9 7.9 0 010 12.9z"
        fill="rgba(0,0,0,0.6)"
      />
      <path
        d="M676.1 505.3L457.7 346.5A8 8 0 00445 353v317.6a8.02 8.02 0 0012.7 6.5l218.4-158.9a7.9 7.9 0 000-12.9z"
        fill="#fff"
      />
    </svg>
  );
}

const PlayButton = styled(PlayIcon)`
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
  color: #000;
  font-size: 40px;
  line-height: 1;
  //opacity: 0.75;
`;

const VideoPlayerWrap = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe,
  video {
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
`;

function Embed({
  type,
  publicId,
  url,
  thumb,
  autoPlay = false,
  noWrap,
}: {
  type?: MediaTypeEnum | null;
  publicId?: string;
  url?: string;
  thumb?: boolean;
  autoPlay?: boolean;
} & any) {
  const Wrap = !thumb || noWrap ? ({ children }) => children : VideoPlayerWrap;
  if (type === MediaTypeEnum.VIDEO) {
    return (
      <Wrap>
        {thumb && <PlayButton />}
        <video
          controls={!thumb}
          src={url}
          style={thumb ? { pointerEvents: 'none', margin: '0 auto' } : { margin: '0 auto' }}
        />
      </Wrap>
    );
  } else if (type === MediaTypeEnum.S3_PUBLIC_VIDEO) {
    return (
      <Wrap>
        {thumb && <PlayButton />}
        <video
          controls={!thumb}
          src={url || publicId}
          style={thumb ? { pointerEvents: 'none', margin: '0 auto' } : { margin: '0 auto' }}
        />
      </Wrap>
    );
  } else if (type === MediaTypeEnum.YOUTUBE) {
    return (
      <Wrap>
        <iframe
          title={`YouTube Embed ${publicId}`}
          style={thumb ? { pointerEvents: 'none' } : {}}
          width="640"
          height="360"
          src={`https://www.youtube.com/embed/${publicId}?${autoPlay ? '?autoplay=1' : ''}&origin=${
            window.location.origin
          }`}
          frameBorder="0"
          allowFullScreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </Wrap>
    );
  } else if (type === MediaTypeEnum.VIMEO) {
    return (
      <Wrap>
        <iframe
          title={`Vimeo Embed ${publicId}`}
          style={thumb ? { pointerEvents: 'none' } : {}}
          src={`https://player.vimeo.com/video/${publicId}${autoPlay ? '?autoplay=1' : ''}`}
          // width="640"
          // height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </Wrap>
    );
  }

  return null;
}

Embed.whyDidYouRender = true;

export default React.memo(Embed);
