import React from 'react';
import Svg from '../Svg';
export const SvgSkillsetPhotographer = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 19.5a9.5 9.5 0 10-.001-19 9.5 9.5 0 000 19z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.73 6.9l.52 1.009a.308.308 0 00.274.167h2.394c.321 0 .583.261.583.583v6.465a.583.583 0 01-.583.583H5.082a.583.583 0 01-.583-.583V8.66c0-.322.26-.583.583-.583h2.675c.12 0 .23-.071.28-.182l.442-.98"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.409 11.511a2.312 2.312 0 11-4.624 0 2.312 2.312 0 014.624 0zM13.357 6.5H6.839V3.1h6.518v3.4z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
SvgSkillsetPhotographer.isIcon = true;
SvgSkillsetPhotographer.defaultProps = {
  size: 24,
};
export default SvgSkillsetPhotographer;
