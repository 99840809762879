import gql from 'graphql-tag';

export const JobPostProposalProtectedCreatorFragment = gql`
  fragment JobPostProposalProtectedCreatorFragment on ProtectedCreator {
    avatar {
      publicId
    }
    id
    layerId
    location {
      region
      locality
      country
    }
    media {
      items {
        type
        publicId
      }
    }
    name
    verified
    rating {
      overall
      internal
      tier
    }
    skill {
      name
    }
    slug
    user {
      familyName
      givenName
    }
  }
`;

export default JobPostProposalProtectedCreatorFragment;
