import React from 'react';
import Svg from '../Svg';
export const SvgLogoGoogle = ({ size, ...props }) => (
  <Svg height={size} viewBox="0 0 1792 1792" width={size} {...props}>
    <path
      fill="currentColor"
      d="M896 786h725q12 67 12 128 0 217-91 387.5T1282.5 1568 896 1664q-157 0-299-60.5T352 1440t-163.5-245T128 896t60.5-299T352 352t245-163.5T896 128q300 0 515 201l-209 201q-123-119-306-119-129 0-238.5 65T484 652.5 420 896t64 243.5T657.5 1316t238.5 65q87 0 160-24t120-60 82-82 51.5-87 22.5-78H896V786z"
    />
  </Svg>
);
SvgLogoGoogle.isIcon = true;
SvgLogoGoogle.defaultProps = {
  size: 24,
};
export default SvgLogoGoogle;
