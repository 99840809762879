import gql from 'graphql-tag';

export default gql`
  fragment ClientProfileSummaryFragment on Client {
    id
    layerId
    adminLevel
    name
    avatar {
      publicId
    }
    user {
      givenName
      familyName
      email
    }
    referralCode
    premiumFeatures
  }
`;
