import React from 'react';
import Svg from '../Svg';
export const SvgCheckboxActive = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <rect x={0.5} y={0.5} width={13} height={13} rx={0.5} stroke="currentColor" />
    <path d="M10.397 4.5l-4.667 5-2-1.875" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
  </Svg>
);
SvgCheckboxActive.isIcon = true;
SvgCheckboxActive.defaultProps = {
  size: 24,
};
export default SvgCheckboxActive;
