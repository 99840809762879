import gql from 'graphql-tag';

export default gql`
  mutation ClientResubscribe{
    clientResubscribe {
      status
      amount
      interval
      expiredAt
    }
  }
`;
