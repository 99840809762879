import { Badge, Menu } from 'antd';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from '../../components-core/icon';
import buildNavItems, { getActiveNavItem } from '../../routes/buildNavigationItems';
import { StreamContext } from '../../screens/MessagesScreen/components/StreamProvider';
import { isAuthorized } from '../../utils/authorization';
import createLogger from '../../utils/createLogger';
import { AnnouncementContext } from '../Announcements/AnnouncmentContext';
import { AuthContext, UserContext } from '../Authenticator';

const logger = createLogger('GlobalNav');

function GlobalNav({ onNavItemClick }: any) {
  const location = useLocation();
  const history = useHistory();
  logger('GlobalNav() render');
  const { announcements } = useContext(AnnouncementContext);
  const { signOut } = useContext(AuthContext);
  const streamContext = useContext(StreamContext);
  const user = useContext(UserContext);
  if (!user) {
    return null;
  }

  const navItems = buildNavItems(
    user.__typename,
    streamContext.getUnreadCount(),
    announcements.length,
    isAuthorized(user.adminLevel)
  );

  const activeNavItem = getActiveNavItem(navItems, location.pathname);

  return (
    <nav>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={activeNavItem ? [activeNavItem.href] : []}
        onClick={({ key }) => {
          if (key.toString().startsWith('http')) {
            window.open(key.toString());
          } else if (key.toString().indexOf('/admin') !== -1) {
            window.location.assign('/admin');
          } else if (key.toString().indexOf('/sign-out') !== -1) {
            signOut();
          } else {
            history.push(key.toString());
          }
          if (typeof onNavItemClick === 'function') {
            onNavItemClick();
          }
        }}
      >
        {navItems.map((navItem, i) =>
          navItem.divider ? (
            <Menu.Divider
              key={i}
              style={{
                backgroundColor: '#EBEBEB',
                opacity: 0.2,
                margin: '10px 0',
              }}
            />
          ) : (
            <Menu.Item key={navItem.href}>
              <div className="flex items-center">
                <Icon name={navItem.icon} size={15} className="anticon" />
                <span className="nav-text flex-1">{navItem.label}</span>
                {navItem.badgeCount && navItem.badgeCount > 0 ? (
                  <>
                    <span className="inline md:hidden lg:inline">
                      <Badge count={navItem.badgeCount} />
                    </span>
                    <span className="hidden md:inline lg:hidden">
                      <Badge dot />
                    </span>
                  </>
                ) : null}{' '}
              </div>
            </Menu.Item>
          )
        )}
      </Menu>
    </nav>
  );
}

export default GlobalNav;
