const buildUrl = () => {
  const appStage = process.env.REACT_APP_STAGE || process.env.APP_STAGE;
  const appEnv = process.env.NODE_ENV;

  if (appStage === 'staging') {
    return 'https://staging.thehhub.com/app';
  }
  return appEnv === 'production' ? 'https://thehhub.com/app' : 'http://localhost:5001';
};

if (window.location.hostname.indexOf('app.') !== -1 || window.location.hostname.indexOf('app-staging.') !== -1) {
  window.location.assign(`${buildUrl()}${window.location.pathname.replace('/app/', '/')}${window.location.search}`);
}

export {};
