import React from 'react';
import Svg from '../Svg';
export const SvgCheckbox = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <rect x={0.5} y={0.5} width={13} height={13} rx={0.5} stroke="currentColor" />
  </Svg>
);
SvgCheckbox.isIcon = true;
SvgCheckbox.defaultProps = {
  size: 24,
};
export default SvgCheckbox;
