import React from 'react';
import Svg from '../Svg';
export const SvgSearch = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="transparent"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.197 3.197a7.499 7.499 0 000 10.606 7.499 7.499 0 0010.606 0 7.499 7.499 0 000-10.606 7.499 7.499 0 00-10.606 0z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path d="M2 19l4.5-4.5" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" />
  </Svg>
);
SvgSearch.isIcon = true;
SvgSearch.defaultProps = {
  size: 24,
};
export default SvgSearch;
