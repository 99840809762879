import LogRocket from 'logrocket';
import config from '../config';
import createLogger from '../utils/createLogger';
import {event as FSEvent, identify as FSIdentify} from '@fullstory/browser';

const logger = createLogger('Analytics');

declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
  }
}

const segmentSDKLoaded = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  if (!window.analytics) {
    logger('Segment is not set up. Nothing will be tracked.');
    return false;
  }
  return true;
};

export function initAnalytics() {
  logger('init id=%s', config.SEGMENT_APP_ID);

  if (!segmentSDKLoaded()) {
    return;
  }
  window.analytics.load(config.SEGMENT_APP_ID);
}

export function track(
  name: string,
  parameters = {},
  customEventData?: {
    readonly action: string;
    readonly category?: string;
    readonly label?: string;
    readonly value?: string;
  }
) {
  logger('track(%s, %o)', name, parameters);
  LogRocket.track(name);

  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_STAGE === 'production') {
    FSEvent(name, parameters);

    if (window.dataLayer && customEventData?.action) {
      window.dataLayer.push({
        event: 'CustomEvent',
        eventAction: customEventData.action,
        eventCategory: customEventData?.category,
        eventLabel: customEventData?.label,
        eventValue: customEventData?.value
      });
    }
  }

  if (!segmentSDKLoaded()) {
    return;
  }
  window.analytics.track(name, parameters);
}

export function trackPage() {
  logger('trackPage()');
  if (!segmentSDKLoaded()) {
    return;
  }
  window.analytics.page();
}

let identified = false;

export function identify(selfId: string | undefined, userProperties: any) {
  logger('identify(%s, %O)', selfId, userProperties);
  if (identified) {
    return;
  }

  if (typeof selfId === 'string') {
    LogRocket.identify(selfId, userProperties);
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_STAGE === 'production') {
      FSIdentify(selfId, {
        displayName: userProperties?.name,
        ...userProperties
      });
    }
    identified = true;
  }

  if (!segmentSDKLoaded()) {
    return;
  }
  window.analytics.identify(selfId, userProperties);
}
