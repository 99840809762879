import Auth from '@aws-amplify/auth';
import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import DebounceLink from 'apollo-link-debounce';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import config from './config';
import introspectionQueryResultData from './fragmentTypes.json';

const DEFAULT_DEBOUNCE_TIMEOUT = 100;
async function getToken() {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}

const fetcher = (...args) => {
  // @ts-ignore
  return window.fetch(...args);
};

const url = config.AWS_APPSYNC_GRAPHQL_APP_URL;
const region = config.COGNITO_REGION;
const auth: import('aws-appsync-auth-link').AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => {
    try {
      return await getToken();
    } catch (error) {
      await Auth.signIn('guest@thehhub.com', 'thehhub');
      return await getToken();
    }
  },
};

const cacheOptions = {
  dataIdFromObject: (object: any) => {
    if (object.__typename === 'MediaItem') {
      // @ts-ignore
      return object.publicId;
    } else {
      return defaultDataIdFromObject(object);
    }
  },
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  }),
};

const publicAPILink = createHttpLink({
  headers: {
    'x-api-key': config.AWS_APPSYNC_GRAPHQL_PUBLIC_KEY,
  },
  useGETForQueries: true,
  uri: config.AWS_APPSYNC_GRAPHQL_PUBLIC_URL,
  fetch: fetcher,
});

const httpLink = createHttpLink({ uri: url, fetch: fetcher });

const link = ApolloLink.split(
  operation => operation.getContext().clientName === 'public',
  ApolloLink.from([new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT), publicAPILink]),
  ApolloLink.from([
    new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
    createAuthLink({
      url,
      region,
      auth,
    }),
    createSubscriptionHandshakeLink(url, httpLink),
  ])
);

let client: ApolloClient<any> | null = null;

export default function initApollo() {
  if (!client) {
    client = new ApolloClient({
      link: link,
      cache: new InMemoryCache(cacheOptions),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
        query: {
          fetchPolicy: 'network-only',
        },
      },
    });
  }
  return client;
}
