import React from 'react';
import Svg from '../Svg';
export const SvgBell = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 16 17" fill="none" {...props}>
    <path d="M2 7.5a6 6 0 016-6v0a6 6 0 016 6V14H2V7.5z" stroke="currentColor" />
    <circle cx={8} cy={1} r={0.5} stroke="currentColor" />
    <path d="M.5 14h15" stroke="currentColor" strokeLinecap="round" />
    <path d="M11 13.5a3 3 0 11-6 0" stroke="currentColor" />
  </Svg>
);
SvgBell.isIcon = true;
SvgBell.defaultProps = {
  size: 24,
};
export default SvgBell;
