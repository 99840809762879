import React, { FunctionComponent } from 'react';
import Flex from '../flex';
import Icon from '../icon';
import Text from '../text';
import theme from '../theme';
import TransparentButton from '../transparent-button';
import { IconButtonProps } from '../types';

const IconButton: FunctionComponent<IconButtonProps> = ({ iconProps, children, ...props }) => {
  const icon = <Icon {...iconProps} />;

  return (
    // @ts-ignore
    <TransparentButton {...props}>
      {children ? (
        <Flex>
          {icon}
          <Text pl={1}>{children}</Text>
        </Flex>
      ) : (
        icon
      )}
    </TransparentButton>
  );
};

IconButton.displayName = 'IconButton';

IconButton.defaultProps = {
  theme,
};

export default IconButton;
