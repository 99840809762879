import gql from 'graphql-tag';

export default gql`
    query SubscriptionQuery {
        subscription{
            status
            amount
            interval
            expiredAt
        }
    }
`;