import UploadOutlined from '@ant-design/icons/UploadOutlined';
import { useApolloClient } from '@apollo/react-hooks';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone-h';
import { MediaTypeEnum } from '../graphql/types/graphql-global-types';
import { uploadImage } from '../utils/cloudinaryUtils';
import { shareFile } from '../utils/uploadUtils';

interface MediaUploadButtonProps {
  children?: React.ReactNode;
  accept?: string | string[];
  label?: string;
  onChange?: any;
  icon?: any;
}

export default function MediaUploadButton({
  accept = ['image/*', 'video/*'],
  children,
  label = 'Upload',
  onChange,
  icon,
}: MediaUploadButtonProps) {
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDropAccepted: async acceptedFiles => {
      const videos = acceptedFiles.filter(file => file.type.startsWith('video'));
      const images = acceptedFiles.filter(file => file.type.startsWith('image'));

      setLoading(true);

      Promise.all([
        ...images.map(file =>
          uploadImage(file).then(result => ({
            publicId: result?.public_id,
            type: MediaTypeEnum.IMAGE,
          }))
        ),
        ...videos.map(file =>
          shareFile(file, apolloClient).then(s3result => ({
            publicId: s3result,
            type: MediaTypeEnum.S3_PUBLIC_VIDEO,
          }))
        ),
      ])
        .then(
          result =>
            new Promise(resolve => {
              // sleep 2 seconds
              setTimeout(() => {
                resolve(result);
              }, 2000);
            })
        )
        .then(result => {
          if (onChange) {
            onChange(result);
          }
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });

      // if (onChange) {
      //   onChange([
      //     { publicId: 'lhsqqobt4b7sokuedxsh', type: MediaTypeEnum.IMAGE },
      //   ]);
      // }
      // onChange();
    },
  });
  return (
    <>
      <input type="file" className="sr-only" {...getInputProps()} />
      <Button icon={icon || <UploadOutlined />} {...getRootProps()} loading={loading}>
        {children || <span className="font-bold uppercase text-xs">{label}</span>}
      </Button>
    </>
  );
}
