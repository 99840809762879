import FileImageOutlined from '@ant-design/icons/FileImageOutlined';
import FileJpgOutlined from '@ant-design/icons/FileJpgOutlined';
import FilePdfOutlined from '@ant-design/icons/FilePdfOutlined';
import FileWordOutlined from '@ant-design/icons/FileWordOutlined';
import FileZipOutlined from '@ant-design/icons/FileZipOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import React from 'react';

export function determineFileIcon(mimeType) {
  if (!mimeType) {
    return <FileImageOutlined />;
  }
  if (mimeType.startsWith('video')) {
    return <VideoCameraOutlined />;
  }
  if (mimeType === 'image/jpeg' || mimeType === 'image/jpg') {
    return <FileJpgOutlined />;
  }
  if (mimeType.startsWith('image/')) {
    return <FileImageOutlined />;
  }
  if (mimeType === 'application/pdf') {
    return <FilePdfOutlined />;
  }
  if (mimeType.indexOf('word') !== -1) {
    return <FileWordOutlined />;
  }
  if (mimeType === 'application/zip') {
    return <FileZipOutlined />;
  }
  return <FileOutlined />;
}

export function humanFileSize(size: number) {
  if (!size) {
    return 'Unknown';
  }
  if (size === 0) {
    return '0';
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  // @ts-ignore
  return `${(size / Math.pow(1024, i)).toFixed(2) * 1} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
}

export const getTotalFileSize = (files: File[]): number => {
  let total = 0;
  files.forEach(file => (total += file.size));
  return total;
};
