import buildNavigationItems from './buildNavigationItems';

export { buildNavigationItems };
// export { default } from './AppRoutes';

const routes = userType => [
  {
    routeConfig: {
      path: '/app/dashboard',
      exact: true,
    },
    icon: 'Dashboard',
    screenImport: () =>
      userType === 'Client'
        ? import(
            /* webpackChunkName: "ClientDashboardScreen" */ '../screens/ClientDashboardScreen/ClientDashboardScreen'
          )
        : null,
  },
  {
    routeConfig: {
      path: '/app/bookmarks',
      exact: true,
    },
    icon: 'Bookmark',
    screenImport: () =>
      userType === 'Client'
        ? import(
            /* webpackChunkName: "ClientBookmarksScreen" */ '../screens/ClientBoomarksScreen/ClientBookmarksScreen'
          )
        : import(/* webpackChunkName: "BookmarksScreen" */ '../screens/BookmarksScreen'),
  },
  {
    routeConfig: {
      path: '/app/creator/apply',
      exact: true,
    },
    screenImport: () =>
      import(/* webpackChunkName: "CreatorApplicationScreen" */ '../screens/CreatorApplicationScreen'),
  },
  {
    routeConfig: {
      path: '/app/creator/:type/:slugChunk',
      exact: true,
    },
    screenImport: () =>
      import(/* webpackChunkName: "CreatorPublicProfileScreen" */ '../screens/CreatorPublicProfileScreen'),
  },
  {
    routeConfig: {
      path: '/app/search',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "CreatorSearchScreen" */ '../screens/CreatorSearchScreen'),
  },
  {
    routeConfig: {
      path: '/app/hire-creators',
      exact: true,
    },
    screenImport: () =>
      import(/* webpackChunkName: "ClientHireCreatorsScreen" */ '../screens/AdvancedClientHireCreatorsScreen'),
  },
  {
    routeConfig: {
      path: '/app/my-jobs',
      exact: true,
    },
    screenImport: () =>
      userType === 'Client'
        ? import(/* webpackChunkName: "ClientMyJobsScreen" */ '../screens/ClientMyJobsScreen')
        : import(/* webpackChunkName: "CreatorMyJobsScreen" */ '../screens/CreatorMyJobsScreen'),
  },
  {
    routeConfig: {
      path: '/app/my-jobs/manage/:section/:slug',
      exact: true,
    },
    screenImport: () =>
      userType === 'Client'
        ? import(
            /* webpackChunkName: "ClientJobManagerScreen" */ '../screens/JobManagerScreen/screens/ClientJobManagerScreen'
          )
        : import(
            /* webpackChunkName: "CreatorJobManagerScreen" */ '../screens/JobManagerScreen/screens/CreatorJobManagerScreen'
          ),
  },
  {
    routeConfig: {
      path: '/app/job-board',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "JobBoardScreen" */ '../screens/JobBoardScreen'),
  },
  {
    routeConfig: {
      path: '/app/post-a-job',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "PostAJobScreen" */ '../screens/PostAJobScreen'),
  },
  {
    routeConfig: {
      path: '/app/repost-a-job',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "RePostAJobScreen" */ '../screens/PostAJobScreen/RePostAJobScreen'),
  },
  {
    routeConfig: {
      path: '/app/messages',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "MessagesScreen" */ '../screens/MessagesScreen'),
  },
  {
    routeConfig: {
      path: '/app/messages/:cid',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "MessagesScreen" */ '../screens/MessagesScreen'),
  },
  {
    routeConfig: {
      path: '/app/post-a-job',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "PostAJobScreen" */ '../screens/PostAJobScreen'),
  },
  {
    routeConfig: {
      path: '/app/profile',
      exact: true,
    },
    icon: 'UserCard',
    screenImport: () => import(/* webpackChunkName: "CreatorProfileScreen" */ '../screens/CreatorPrivateProfileScreen'),
  },
  {
    routeConfig: {
      path: '/app/sign-in',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "SignInScreen" */ '../screens/SignInScreen'),
  },
  {
    routeConfig: {
      path: '/app/auth/reset',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "ResetPasswordScreen" */ '../screens/ResetPasswordScreen'),
  },
  {
    routeConfig: {
      path: '/app/auth/verify',
      exact: true,
    },
    screenImport: () => import(/* webpackChunkName: "VerifyPasswordScreen" */ '../screens/VerifyPasswordScreen'),
  },
  {
    routeConfig: {
      path: '/app/settings',
      exact: true,
    },
    icon: 'Settings',
    screenImport: () => import(/* webpackChunkName: "SettingsScreen" */ '../screens/SettingsScreen'),
  },
  {
    routeConfig: {
      path: '/app/critique-corner',
      exact: false,
    },
    icon: 'Settings',
    screenImport: () =>
      import(/* webpackChunkName: "CritCornerScreen" */ '../screens/CritCornerScreen/CritCornerScreen'),
  },
];

export default routes;
