import React from 'react';
import Svg from '../Svg';
export const SvgDownload = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M5.93.667v8.682M1.86 5.55l4 4a.1.1 0 00.14 0l4-4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M.667 9.333v2h10.666v-2" stroke="currentColor" />
  </Svg>
);
SvgDownload.isIcon = true;
SvgDownload.defaultProps = {
  size: 24,
};
export default SvgDownload;
