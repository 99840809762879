import { useQuery } from '@apollo/react-hooks';
import { Alert, Avatar, Button, Form, Input, InputNumber, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import { track } from '../../../analytics';
import Icon from '../../../components-core/icon';
import { UserContext } from '../../../components/Authenticator';
import CreatorPortfolioSelection from '../../../components/CreatorProfile/CreatorPortfolioSelection';
import MediaUploadButton from '../../../components/MediaUploadButton';
import TextareaWithCount from '../../../components/TextareaWithCount/TextareaWithCount';
import { EMAIL_PHONE_URL_HANDLE_MATCHER } from '../../../constants';
import CREATOR_MEDIA_QUERY from '../../../graphql/queries/CreatorMediaQuery';
import { CreatorMediaQuery } from '../../../graphql/queries/typings/CreatorMediaQuery';
import PortfolioMediaItem from '../../../PortfolioMediaItem';
import { buildCloudinaryImageUrl } from '../../../utils/cloudinaryUtils';
// import { default as HInput } from '../components-core/input';
import PictureOutlined from '@ant-design/icons/PictureOutlined';

const FileCloseButton = props => (
  <svg width={17} height={17} fill="none" {...props}>
    <circle cx={8.5} cy={8.5} r={7.5} fill="#161616" fillOpacity={0.3} stroke="#FEFEFE" />
    <path d="M5.555 5.554l5.892 5.892M11.447 5.554l-5.893 5.892" stroke="#FEFEFE" strokeLinecap="round" />
  </svg>
);

function JobProposalForm({ jobPost, onShowPreview, bidPayload }) {
  const [form] = Form.useForm();
  const user = useContext(UserContext);
  const [showPortfolio, setShowPortfolio] = useState(false);
  const [media, setMedia] = useState(bidPayload?.media ?? []);
  const [showWarning, setShowWarning] = useState(false);
  const [forceHideWarning, setForceHideWarning] = useState(false);

  const creatorMediaQuery = useQuery<CreatorMediaQuery>(CREATOR_MEDIA_QUERY);
  const portfolio = creatorMediaQuery.data?.creatorSelf?.media?.items ?? [];

  const platformFeePercentage =
    typeof jobPost.platformFeePercentage !== 'undefined' && jobPost.platformFeePercentage !== null
      ? parseFloat(jobPost.platformFeePercentage)
      : 0.18;
  const budgetRange = jobPost?.details?.budget?.range;

  const budget = jobPost?.details?.budget?.range?.min && jobPost?.details?.budget?.range?.max
    ? `$${(budgetRange.min / 100).toLocaleString()} - $${(budgetRange.max / 100).toLocaleString()}`
    : 'Needs Estimate';
  // console.log(jobPost.platformFeePercentage);

  const [fileError, setFileError] = useState('');
  const [price, setPrice] = useState(0);

  // console.log(form);

  // const isReadyToSubmit =
  //   form.getFieldsError().filter(field => field.errors.length > 0).length > 0 &&
  //   media.length >= 3;

  // console.log(isReadyToSubmit);

  const checkFiles = () => {
    if (media.length < 3) {
      setFileError('You must add at least three items');
      return true;
    }
    return false;
  };
  const handleSubmit = values => {
    // form.validateFieldsAndScroll(async (errs, values) => {
    if (checkFiles()) {
      return;
    }

    // if (fileError) {
    //   setFileError('');
    // }

    // if (errs) {
    //   console.warn(errs);
    //   return;
    // }

    const input = {
      amount: parseInt(values.amount, 10) * 100,
      headline: values.headline,
      introduction: values.introduction,
      media: media,
      deliverables: [
        {
          type: 'PHOTOS',
          quantity: values.deliverables,
        },
      ],
    };

    if (showWarning) {
      track('Going Off-site', input);
    }

    onShowPreview(input);
    // });
  };
  const errors = [];
  const fieldErrors: any = form.getFieldsError();
  Object.keys(fieldErrors).forEach(key => {
    if (Array.isArray(fieldErrors[key])) {
      // @ts-ignore
      errors.push(fieldErrors[key]);
    }
  });
  // const disableSubmit = !form.isFieldsTouched() || errors.length > 0;

  const warning = onClick => (
    <Alert
      showIcon
      className="mb-8 border border-yellow"
      message="Taking your conversation off the Hub?"
      description="Including personal contact information goes against our Terms Of Use. Stay safe and secure by keeping all of your messaging on the Hub."
      type="warning"
      onClose={onClick}
      closable
    />
  );

  let handleError = () => {
    checkFiles();
  };

  return (
    <div className="bg-whiteApple border border-borderGray overflow-hidden rounded">
      {showPortfolio && (
        <CreatorPortfolioSelection
          onAdd={value => {
            setMedia(prev => prev.concat(value));
            setShowPortfolio(false);
          }}
          onCancel={() => setShowPortfolio(false)}
          portfolio={portfolio}
        />
      )}

      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={handleError}
        // onSuccess={handleSubmit}
        initialValues={{
          ...bidPayload,
          deliverables:
            bidPayload?.deliverables && bidPayload?.deliverables.length > 0
              ? bidPayload?.deliverables[0]?.quantity + ''
              : '',
          amount: bidPayload?.amount ? bidPayload?.amount / 100 : '',
        }}
        style={{
          display: showPortfolio ? 'none' : 'block',
        }}
      >
        <div style={{ overflowX: 'hidden' }}>
          <div className="bg-white p-3 md:p-6 space-y-6 border-b border-borderGray">
            <div>
              <div className="flex">
                <Avatar
                  size={40}
                  src={buildCloudinaryImageUrl(user?.avatar?.publicId)}
                  style={{
                    border: '2px solid white',
                  }}
                />
                <div className="pl-2 mb-2">
                  <div className="font-bold text-grayDark">
                    Your Headline
                    <span className="text-red font-bold">*</span>
                  </div>

                  <div className="text-xs">Sample: Experienced Fashion Photographer of 5 Years</div>
                </div>
              </div>

              <Form.Item
                name="headline"
                rules={[
                  { required: true, message: 'Describe who you are' },
                  {
                    max: 100,
                    message: "Can't be more than 100 characters",
                  },
                ]}
              >
                <Input size="large" autoFocus placeholder="Describe who you are" />
              </Form.Item>
            </div>

            <div>
              <div className="font-bold text-grayDark mb-2">
                Bid Amount
                <span className="text-red font-bold">*</span>
              </div>
              <Form.Item
                name="amount"
                rules={[{ required: true, message: 'Enter your proposed price.' }]}
                // getValueFromEvent={e => {
                //   if (!e || !e.target) {
                //     return e;
                //   }
                //   const { target } = e;
                //   // console.log(typeof parseInt(target.rawValue));
                //   // const parsedTextValue = parseInt(target.rawValue, 10);
                //   return target.rawValue;
                // }}
              >
                {/*{form.getFieldDecorator('amount', {*/}
                {/*  ...(bidPayload?.amount*/}
                {/*    ? {*/}
                {/*        initialValue: bidPayload?.amount / 100,*/}
                {/*      }*/}
                {/*    : {*/}
                {/*        initialValue: '',*/}
                {/*      }),*/}
                {/*  // @ts-ignore*/}
                {/*  onChange: e => {*/}
                {/*    setPrice(parseInt(e.target.rawValue, 10));*/}
                {/*  },*/}
                {/*  getValueFromEvent: e => {*/}
                {/*    if (!e || !e.target) {*/}
                {/*      return e;*/}
                {/*    }*/}
                {/*    const { target } = e;*/}
                {/*    // console.log(typeof parseInt(target.rawValue));*/}
                {/*    // const parsedTextValue = parseInt(target.rawValue, 10);*/}
                {/*    return target.rawValue;*/}
                {/*  },*/}
                {/*  rules: [*/}
                {/*    { required: true, message: 'Enter your proposed price.' },*/}
                {/*  ],*/}
                {/*})(*/}
                <InputNumber
                  size="large"
                  // prefix={
                  //   <Icon
                  //     name="DollarCircle"
                  //     size="1em"
                  //     mr={1}
                  //     color="#d9d9d9"
                  //   />
                  // }
                  // cleaveOptions={{
                  //   numeral: true,
                  //   numeralThousandsGroupStyle: 'thousand',
                  //   // prefix: '$',
                  // }}
                  // size="large"
                  style={{ minWidth: 210, marginRight: 15 }}
                  min={50}
                  placeholder="Enter an exact amount"
                  onChange={value => {
                    if (value) {
                      // @ts-ignore
                      setPrice(value);
                    }
                  }}
                  // max={budgetRange.max / 100}
                  // step={50}
                  // defaultValue={1000}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => (value || '').replace(/\$\s?|(,*)/g, '')}
                  precision={0}
                  // onChange={onChange}
                />
                {/*)}*/}
              </Form.Item>

              {!isNaN(price) && platformFeePercentage !== 0 && price !== 0 ? (
                <div className="text-sm font-bold text-gray-900 text-opacity-50">
                  You’ll make ${(price - Math.round(price * platformFeePercentage)).toLocaleString()} after the job is
                  complete{' '}
                  <Tooltip
                    title={
                      <>
                        <div className="flex justify-between" style={{ width: 170 }}>
                          <div>Price</div>
                          <div>${price}</div>
                        </div>
                        <div className="flex justify-between" style={{ width: 170 }}>
                          <div>Service fee (18%)</div>
                          <div>-${Math.round(price * 0.18)}</div>
                        </div>
                        <div className="flex justify-between" style={{ width: 170 }}>
                          <div>Payout after work</div>
                          <div>${price - Math.round(price * 0.18)}</div>
                        </div>
                      </>
                    }
                  >
                    <span>
                      <Icon size={15} color="blue" name="HelpCircle" style={{ marginBottom: -2, marginLeft: 3 }} />
                    </span>
                  </Tooltip>
                </div>
              ) : (
                <div className="text-sm font-bold text-gray-900 text-opacity-50 mt-2">The job budget is: {budget}</div>
              )}
            </div>

            <div>
              <div className="font-bold text-grayDark mb-2">
                How many assets can the client expect to receive?
                <span className="text-red font-bold">*</span>
              </div>
              <Form.Item name="deliverables" rules={[{ required: true, message: 'Enter a quantity' }]}>
                {/*{form.getFieldDecorator('deliverables', {*/}
                {/*  initialValue: bidPayload?.deliverables[0]?.quantity + '',*/}
                {/*  getValueFromEvent: e => {*/}
                {/*    if (!e || !e.target) {*/}
                {/*      return e;*/}
                {/*    }*/}
                {/*    const { target } = e;*/}
                {/*    return target.rawValue;*/}
                {/*  },*/}
                {/*  rules: [{ required: true, message: 'Enter a quantity.' }],*/}
                {/*})(*/}
                <Input
                  size="large"
                  prefix={<Icon name="Image" size="1em" mr={1} color="#d9d9d9" />}
                  // cleaveOptions={{
                  //   numeral: true,
                  //   numeralThousandsGroupStyle: 'thousand',
                  //   // prefix: '$',
                  // }}
                  // size="large"
                  style={{ maxWidth: 210, marginRight: 15 }}
                  min={50}
                  placeholder="Enter an exact amount"
                  // max={budgetRange.max / 100}
                  // step={50}
                  // defaultValue={1000}
                  // formatter={value =>
                  //   `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  // }
                  // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  // onChange={onChange}
                />
                {/*)}*/}
              </Form.Item>
            </div>
          </div>

          <div className="bg-whiteApple p-3 md:p-6 border-b border-borderGray">
            <div className="font-bold text-grayDark mb-1">
              Your Bid Letter
              <span className="text-red font-bold">*</span>
            </div>
            <div className="mb-3">
              This is your pitch. Explain why you are a fit, why they should choose you AND explain WHY you are bidding
              the amount that you are.
            </div>
            <div>{!forceHideWarning && showWarning && warning(() => setForceHideWarning(true))}</div>
            <Form.Item
              style={{ margin: 0 }}
              colon={false}
              name="introduction"
              rules={[
                {
                  required: true,
                  message: "Describe why you're a good fit",
                },
                { min: 150, message: 'Must be at least 150 characters' },
                { max: 600, message: "Can't be more than 600 characters" },
              ]}
              validateTrigger="onBlur"
              // label={<strong>Your Bid Letter</strong>}
            >
              <TextareaWithCount
                min={100}
                max={600}
                size="large"
                rows={5}
                placeholder="Example - I bid $1,200 because I am charging $700 for my time but need to hire two models at $150 each and also pay for a location to do your brief justice."
                onChange={e => {
                  const value = e.target.value;
                  if (value) {
                    const matches = value.match(EMAIL_PHONE_URL_HANDLE_MATCHER);
                    if (matches && matches.length > 0) {
                      setShowWarning(true);
                    } else if (showWarning) {
                      setShowWarning(false);
                    }
                  }
                }}
              />
              {/*)}*/}
            </Form.Item>
          </div>

          <div className="bg-white p-3 md:p-6">
            <div className="font-bold text-grayDark mb-1">
              Upload Relevant Assets
              <span className="text-red font-bold">*</span>
            </div>
            <div className="text-sm mb-3">
              Select three or more items that show experience with this type of project
            </div>
            <div className="flex flex-col md:flex-row items-start md:items-center" style={{ maxWidth: 380 }}>
              <div className="mr-2">
                <MediaUploadButton
                  onChange={value => {
                    setMedia(prev => prev.concat(value));
                  }}
                />
              </div>

              <Button icon={<PictureOutlined />} onClick={() => setShowPortfolio(true)}>
                <span className="font-bold uppercase text-xs pr-2">Select from Portfolio</span>
              </Button>
            </div>

            {media.length > 0 && (
              <div className="grid grid-cols-3 gap-4 mt-6">
                {media.map(({ type, publicId }, i) => (
                  <div
                    key={i}
                    className="relative"
                    // style={{ width: 180, height: 180 }}
                  >
                    <div className="absolute top-0 right-0 z-10 pr-2">
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => setMedia(prev => prev.filter((_file, index) => i !== index))}
                      >
                        <FileCloseButton />
                      </Button>
                    </div>

                    <PortfolioMediaItem type={type} publicId={publicId} />
                  </div>
                ))}
              </div>
            )}

            {media.length < 3 && fileError && <div className="text-red mt-2">{fileError}</div>}
          </div>
        </div>
        <div className="text-right border-t border-borderGray bg-whiteApple p-4">
          <Button
            // width={135}
            size="large"
            type="primary"
            htmlType="submit"
            // disabled={!isReadyToSubmit}
          >
            <span className="font-bold uppercase px-6">Continue</span>
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default JobProposalForm;
