import createLogger from '../../utils/createLogger';

const logger = createLogger('Announcements build title');

export default (userType, announcement) => {
  let heading;
  let icon;
  // let action;
  // let message;
  switch (announcement.eventType) {
    case 'ContractCancelled':
      heading = 'Contract cancelled';
      break;
    case 'JobPostDuplicated':
      heading = 'Job duplicated';
      icon = 'InfoCircle';
      break;
    case 'ContractDeclined':
      heading = 'Contract delined';
      icon = 'CloseCircle';
      break;
    case 'ClientChargeRefunded':
      heading = 'Refund issued';
      break;
    case 'ContractAssetsReady':
      heading = 'Assets ready for review';
      icon = 'Image';
      // actionText = 'View Assets';
      break;
    case 'ContractAssetsDelivered':
      heading = 'Assets delivered';
      // actionText = 'View Assets';
      break;
    case 'JobPostProposalCreated':
      heading = 'You received a bid';
      // actionText = 'View Bid';
      break;
    case 'ContractAccepted':
      heading = 'Contract accepted';
      // actionText = 'View Contract';
      break;
    case 'JobPostCancelled':
      heading = 'Job canceled';
      break;
    case 'ClientChargeCreated':
      heading = 'Payment pending';
      break;
    case 'ContractAssetsApproved':
      heading = 'Assets have been approved';
      break;
    case 'ContractCreated':
      heading = 'Contract to review';
      break;
    case 'JobPostCreatorInvited':
      heading = 'Bid requested';
      // actionText = 'View Contract';
      break;
    case 'CreatorPayoutCreated':
      heading = userType === 'Creator' ? 'You got paid!' : 'Job Completed';
      break;
    case 'ClientChargeCaptured':
      heading = 'Payment updated';
      icon = 'InfoCircle';
      break;
    default:
      logger('[warn] No heading for: ', announcement?.metadata?.eventType);
      break;
  }

  return { heading, icon };
};
