import { useApolloClient } from '@apollo/react-hooks';
import { Avatar, Select, Spin } from 'antd';
import React, { useState } from 'react';
import CREATOR_PREDICTION_QUERY from '../../graphql/queries/CreatorPrediction';
import { buildCloudinaryImageUrl } from '../../utils/cloudinaryUtils';

const CreatorSearchTypeahead = ({ onChange, onSearch, initialValue, hideResult, ...props }: any) => {
  const [searching, setSearching] = useState(false);
  const [foundCreators, setFoundCreators] = useState<any[]>([]);
  const [selectedCreators, setSelectedCreators] = useState(initialValue || []);

  const client = useApolloClient();

  async function handleSearch(input) {
    if (onSearch) {
      onSearch(input);
    }

    if (!input) {
      return;
    }

    setSearching(true);
    setFoundCreators([]);
    const { data }: any = await client.query({
      query: CREATOR_PREDICTION_QUERY,
      variables: { input },
    });
    if (data.creatorPrediction) {
      setFoundCreators(data.creatorPrediction);
    }
    setSearching(false);
  }

  function handleChange(value) {
    setFoundCreators([]);
    setSelectedCreators(value);
    if (onChange) {
      onChange(value);
      // console.log(selectedCreators)
    }
  }

  const avatar = creator => (
    // @ts-ignore
    <Avatar
      // @ts-ignore
      creator={creator}
      size="small"
      src={buildCloudinaryImageUrl(creator.avatar.publicId, 'w_120,h_120,c_fill')}
    />
  );

  return (
    <Select
      mode="multiple"
      onSearch={handleSearch}
      onChange={handleChange}
      value={hideResult ? [] : selectedCreators}
      onBlur={() => setFoundCreators([])}
      labelInValue
      filterOption={false}
      notFoundContent={searching ? <Spin size="small" /> : null}
      placeholder="Search for creators"
      size="large"
      {...props}
    >
      {foundCreators.map(creator => (
        <Select.Option key={creator.id} value={creator.id}>
          {avatar(creator)}&nbsp;
          {creator.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CreatorSearchTypeahead;

// import React from 'react';
// import { AutoComplete, avatar, Icon, Input, Select, Spin } from 'antd';
// import { withApollo } from 'react-apollo';
//
// import CREATOR_PREDICTION_QUERY from '../../graphql/queries/CreatorPrediction.ts';
// import { buildCloudinaryImageUrl } from '../../lib/cloudinary';
// import './CreatorSearchTypeahead.less';
//
// const { Option } = AutoComplete;
//
// class CreatorSearchTypeahead extends React.Component {
//   lastFetchId = 0;
//
//   static getDerivedStateFromProps(nextProps, prevState) {
//     if (nextProps.value && nextProps.value !== prevState.value) {
//       return {
//         value: nextProps.value,
//       };
//     }
//     return {};
//   }
//
//   static defaultProps = {
//     onChange: () => {},
//     onSelect: () => {},
//     showIcon: false,
//     multiple: true,
//   };
//
//   state = {
//     data: [],
//     value: [],
//     fetching: false,
//   };
//
//   fetchUser = value => {
//     if (!value) {
//       return;
//     }
//     this.lastFetchId += 1;
//     const fetchId = this.lastFetchId;
//     this.setState({ data: [], fetching: true });
//     const { client } = this.props;
//     client
//       .query({
//         query: CREATOR_PREDICTION_QUERY,
//         variables: { input: value },
//       })
//       .then(({ data: { creatorPrediction } }) => {
//         if (fetchId !== this.lastFetchId) {
//           // for fetch callback order
//           return;
//         }
//         const data = creatorPrediction
//           .filter(creator => !!creator)
//           .map(creator => ({
//             text: `${creator.name}`,
//             value: creator.id,
//             creatorId: creator.id,
//             layerId: creator.layerId,
//             avatarPublicId: creator.avatar.publicId,
//             ...creator,
//           }));
//         this.setState({ data, fetching: false });
//       });
//   };
//
//   handleChange = value => {
//     this.setState(
//       {
//         value,
//         data: [],
//         fetching: false,
//       },
//       () => {
//         this.props.onChange(value);
//       }
//     );
//   };
//
//   render() {
//     const { fetching, data } = this.state;
//     const { multiple, onSelect, ...rest } = this.props;
//
//     if (!multiple) {
//       return (
//         <AutoComplete
//           placeholder="Search for creators"
//           size="large"
//           // value={value}
//           className="creator-search-typeahead"
//           addonAfter={<Icon type="search" />}
//           onSelect={onSelect}
//           {...rest}
//           onSearch={this.fetchUser}
//           onChange={this.handleChange}
//           showSearch
//           dataSource={data.map(d => (
//             <Option key={d.creatorId} value={d.slug}>
//               <avatar
//                 size="small"
//                 src={buildCloudinaryImageUrl(d.avatarPublicId)}
//               />{' '}
//               {d.text}
//             </Option>
//           ))}
//         >
//           <Input suffix={<Icon type="search" className="search-icon" />} />
//         </AutoComplete>
//       );
//     }
//
//     return (
//       <Select
//         mode="multiple"
//         labelInValue
//         placeholder="Search for creators"
//         onSearch={this.fetchUser}
//         onChange={this.handleChange}
//         notFoundContent={fetching ? <Spin size="small" /> : null}
//         filterOption={false}
//         style={{ width: '100%', minWidth: 180 }}
//         size="large"
//         // value={value}
//         // suffixIcon={<Icon type="search" />}
//         allowClear
//       >
//         {data.map(d => (
//           <Option key={d.layerId} value={d}>
//             <avatar
//               size="small"
//               src={buildCloudinaryImageUrl(d.avatarPublicId)}
//             />{' '}
//             {d.text}
//           </Option>
//         ))}
//       </Select>
//     );
//   }
// }
//
// export default withApollo(CreatorSearchTypeahead);
