import { Input } from 'antd';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import Text from '../../components-core/text';

const TextareaWithCount = React.forwardRef(({ min, max, value, ...rest }: any, ref) => {
  const theme = useTheme();
  let countColor = 'gray';
  if (min && value) {
    countColor = min > value.length ? 'gray' : 'green';
  }
  if (max && value) {
    countColor = max < value.length ? 'red' : 'gray';
  }
  return (
    <div className="relative">
      <Input.TextArea
        ref={ref}
        style={{
          fontFamily: theme.font,
        }}
        onBlur={e => {
          if (!min || !max) {
            return;
          }
          if (typeof min !== 'undefined') {
            const currentLength = e.target.value.length;
            if (currentLength < min) {
              // show error
            }
          }
          // console.log(e.target.value.length);
        }}
        value={value}
        {...rest}
      />
      {value && (
        <div className="absolute right-0 bottom-0 mr-4 mb-4">
          <Text bold color="gray">
            <Text as="span" color={countColor}>
              {value.length}
            </Text>
            {max ? ` / ${max}` : ''}
          </Text>
        </div>
      )}
    </div>
  );
});

export default TextareaWithCount;
