import React from 'react';

const HelpIcon = props => (
  <svg width={14} height={14} fill="none" {...props}>
    <path d="M13.5 7a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z" stroke="#6BA8BF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.523 9.419c0-.11.09-.2.2-.2h.476c.11 0 .2.09.2.2v.461a.2.2 0 01-.2.2h-.476a.2.2 0 01-.2-.2v-.461zM5.24 5.903a.19.19 0 01-.193-.2c.037-.504.213-.917.53-1.239.358-.363.85-.544 1.474-.544.578 0 1.04.155 1.388.466.348.31.521.707.521 1.19 0 .292-.063.53-.191.712-.128.182-.385.45-.772.803-.282.257-.464.474-.548.652-.067.142-.107.338-.12.589a.208.208 0 01-.205.2H6.74c-.11 0-.201-.09-.196-.2.015-.304.063-.555.145-.754.1-.242.318-.519.656-.83l.352-.328a1.39 1.39 0 00.255-.293 1.042 1.042 0 00-.081-1.263c-.172-.199-.456-.298-.852-.298-.49 0-.828.17-1.016.513-.086.155-.142.366-.168.632a.209.209 0 01-.205.192h-.39z"
      fill="#6BA8BF"
    />
  </svg>
);

export default HelpIcon;
