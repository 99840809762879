import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components/macro';
import Hide from '../../components-core/hide';
import Relative from '../../components-core/relative';
import Absolute from '../../components-core/absolute';
import Box from '../../components-core/box';

export const RangeWrap = styled(Box)`
  position: relative;
  max-width: 304px;
  width: 100%;
  margin: 0 auto;
`;

const PlusIcon = props => (
  <svg width={38} height={38} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 35.625c9.182 0 16.625-7.443 16.625-16.625S28.182 2.375 19 2.375 2.375 9.818 2.375 19 9.818 35.625 19 35.625zM38 19c0 10.493-8.507 19-19 19S0 29.493 0 19 8.507 0 19 0s19 8.507 19 19z"
      fill="#6BA8BF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.187 11.4a1.187 1.187 0 10-2.375 0v6.412H11.4a1.188 1.188 0 000 2.375h6.412V26.6a1.187 1.187 0 102.375 0v-6.413H26.6a1.188 1.188 0 000-2.375h-6.413V11.4z"
      fill="#6BA8BF"
    />
  </svg>
);

export const MoodBoardUploadButton = styled(PlusIcon)`
  cursor: pointer;
`;

export const StatCurve = styled(props => {
  return (
    <Relative height="75px">
      <Absolute bottom="1px" zIndex={1}>
        <Hide lg xl xxl position={'relative'} top="-6px">
          <animated.img src={require('../../assets/images/stat-curve.png')} width={304} height={75} {...props} />
        </Hide>
        <Hide xs sm md>
          <animated.svg width={304} height={75} fill="none" {...props}>
            <g clipPath="url(#prefix__clip0)" stroke="#6BA8BF" strokeWidth={6} strokeLinecap="round">
              <path
                opacity={0.3}
                d="M3 76v-2M12 75v-3M21 75v-6M30 75V65M39 75V57M48 75V47M192 75V47M291 75v-4M300 75v-3M246 75v-6M255 75v-6M264 75v-5M273 75v-4M282 75v-4M237 75v-7M228 75v-8M219 75V61M210 76V55M201 75V54M183 75V41M174 75V33M165 75V33M93 75V28M102 75V18M84 75V28.45M75 75V41M65.97 75l.06-35.15M57 75V44.6M111 75V8M120 75V5M156 75V26M147 75V15M138 76V8M129 75V3"
              />
            </g>
            <defs>
              <clipPath id="prefix__clip0">
                <path fill="#fff" d="M0 0h304v75H0z" />
              </clipPath>
            </defs>
          </animated.svg>
        </Hide>
      </Absolute>
      {/*<Absolute bottom={'0'} zIndex={"2"}>{svg('#d9d9d9')}</Absolute>*/}
    </Relative>
  );
})`
  position: relative;
  top: 11px;
`;

export const StyledFileInput = styled.input`
  cursor: pointer;
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
`;
