import gql from 'graphql-tag';

export default gql`
  query ChatIdentity {
    chatIdentity {
      userId
      token
    }
  }
`;
