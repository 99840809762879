import React from 'react';
import Headline from '../Headline/Headline';

export default ({ title, children }: any) => (
  <div
    className="flex justify-between items-center shadow h-auto sticky bg-white border-b px-3 md:px-6"
    style={{ minHeight: 72 }}
  >
    {title && <Headline>{title}</Headline>}
    {children}
  </div>
);
