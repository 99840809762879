import gql from 'graphql-tag';

export default gql`
  query CreatorNamesPrediction($input: String!) {
    creatorPrediction(input: $input) {
      id
      name
      avatar {
        publicId
      }
      slug
      instagramProfile {
        id
        userName
      }
      user {
        id
        legacyId
      }
      layerId
    }
  }
`;
