import React from 'react';
import Svg from '../Svg';
export const SvgCheck = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 9 7" fill="none" {...props}>
    <path d="M7.667 1L3 6 1 4.125" stroke="currentColor" strokeLinecap="round" />
  </Svg>
);
SvgCheck.isIcon = true;
SvgCheck.defaultProps = {
  size: 24,
};
export default SvgCheck;
