import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, Form, Input, Modal, Radio, Tooltip } from 'antd';
import React, { useState } from 'react';
import { MediaTypeEnum } from '../graphql/types/graphql-global-types';
import Embed from './Embed';

function YouTubeLogo(props) {
  return (
    <svg viewBox="0 0 492 110" {...props}>
      <path
        fill="red"
        d="M154.3 17.5c-1.8-6.7-7.1-12-13.8-13.8C128.4.4 79.7.4 79.7.4S31 .5 18.9 3.8c-6.7 1.8-12 7.1-13.8 13.8C1.9 29.7 1.9 55 1.9 55s0 25.3 3.3 37.5c1.8 6.7 7.1 12 13.8 13.8 12.1 3.3 60.8 3.3 60.8 3.3s48.7 0 60.8-3.3c6.7-1.8 12-7.1 13.8-13.8 3.3-12.1 3.3-37.5 3.3-37.5s-.1-25.3-3.4-37.5z"
      />
      <path fill="#FFF" d="M64.2 78.4L104.6 55 64.2 31.6z" />
      <g>
        <g fill="#282828">
          <path d="M227.9 99.7c-3.1-2.1-5.3-5.3-6.6-9.7-1.3-4.4-1.9-10.2-1.9-17.5v-9.9c0-7.3.7-13.3 2.2-17.7 1.5-4.5 3.8-7.7 7-9.7 3.2-2 7.3-3.1 12.4-3.1 5 0 9.1 1 12.1 3.1s5.3 5.3 6.7 9.7c1.4 4.4 2.1 10.3 2.1 17.6v9.9c0 7.3-.7 13.1-2.1 17.5-1.4 4.4-3.6 7.6-6.7 9.7-3.1 2-7.3 3.1-12.5 3.1-5.4.1-9.6-1-12.7-3zM245.2 89c.9-2.2 1.3-5.9 1.3-10.9V56.8c0-4.9-.4-8.5-1.3-10.7-.9-2.3-2.4-3.4-4.5-3.4s-3.5 1.1-4.4 3.4c-.9 2.3-1.3 5.8-1.3 10.7v21.3c0 5 .4 8.7 1.2 10.9.8 2.2 2.3 3.3 4.5 3.3 2.1 0 3.6-1.1 4.5-3.3zM464.4 72.7v3.5c0 4.4.1 7.7.4 9.9.3 2.2.8 3.8 1.6 4.8s2.1 1.5 3.8 1.5c2.3 0 3.9-.9 4.7-2.7.9-1.8 1.3-4.8 1.4-8.9l13.3.8c.1.6.1 1.4.1 2.4 0 6.3-1.7 11-5.2 14.1-3.5 3.1-8.3 4.7-14.6 4.7-7.6 0-12.9-2.4-15.9-7.1-3-4.7-4.6-12.1-4.6-22V61.6c0-10.2 1.6-17.7 4.7-22.4 3.2-4.7 8.6-7.1 16.2-7.1 5.3 0 9.3 1 12.1 2.9 2.8 1.9 4.8 4.9 6 9 1.2 4.1 1.7 9.7 1.7 16.9v11.7h-25.7zm2-28.8c-.8 1-1.3 2.5-1.6 4.7-.3 2.2-.4 5.5-.4 10v4.9h11.2v-4.9c0-4.4-.1-7.7-.4-10-.3-2.3-.8-3.9-1.6-4.8-.8-.9-2-1.4-3.6-1.4-1.7.1-2.9.6-3.6 1.5zM190.5 71.4L173 8.2h15.3l6.1 28.6c1.6 7.1 2.7 13.1 3.5 18h.4c.5-3.6 1.7-9.5 3.5-17.9l6.3-28.7h15.3l-17.7 63.1v30.3h-15.1V71.4zM311.5 33.4v68.3h-12l-1.3-8.4h-.3c-3.3 6.3-8.2 9.5-14.7 9.5-4.5 0-7.9-1.5-10-4.5-2.2-3-3.2-7.6-3.2-13.9v-51h15.4v50.1c0 3 .3 5.2 1 6.5.7 1.3 1.8 1.9 3.3 1.9 1.3 0 2.6-.4 3.8-1.2 1.2-.8 2.1-1.9 2.7-3.1V33.4h15.3zM390.4 33.4v68.3h-12l-1.3-8.4h-.3c-3.3 6.3-8.2 9.5-14.7 9.5-4.5 0-7.9-1.5-10-4.5-2.2-3-3.2-7.6-3.2-13.9v-51h15.4v50.1c0 3 .3 5.2 1 6.5.7 1.3 1.8 1.9 3.3 1.9 1.3 0 2.6-.4 3.8-1.2 1.2-.8 2.1-1.9 2.7-3.1V33.4h15.3z" />
          <path d="M353.3 20.6H338v81.1h-15V20.6h-15.3V8.2h45.5v12.4zM441.2 44.3c-.9-4.3-2.4-7.4-4.5-9.4-2.1-1.9-4.9-2.9-8.6-2.9-2.8 0-5.5.8-7.9 2.4-2.5 1.6-4.3 3.7-5.7 6.3h-.1v-36h-14.8v96.9h12.7l1.6-6.5h.3c1.2 2.3 3 4.1 5.3 5.5 2.4 1.3 5 2 7.9 2 5.2 0 9-2.4 11.5-7.2 2.4-4.8 3.7-12.3 3.7-22.4V62.2c0-7.6-.5-13.6-1.4-17.9zm-14.1 27.9c0 5-.2 8.9-.6 11.7-.4 2.8-1.1 4.8-2.1 6-1 1.2-2.3 1.8-3.9 1.8-1.3 0-2.4-.3-3.5-.9-1.1-.6-1.9-1.5-2.6-2.7V49.3c.5-1.9 1.4-3.4 2.7-4.6 1.3-1.2 2.6-1.8 4.1-1.8 1.6 0 2.8.6 3.6 1.8.9 1.2 1.4 3.3 1.8 6.2.3 2.9.5 7 .5 12.4v8.9z" />
        </g>
      </g>
    </svg>
  );
}

function VimeoLogo(props) {
  return (
    <svg height={20} width={70} fill="#1ab7ea" {...props}>
      <path d="M15.72 9.431c-.069 1.514-1.127 3.588-3.172 6.22-2.114 2.749-3.903 4.124-5.367 4.124-.906 0-1.673-.837-2.3-2.512l-1.255-4.604c-.465-1.674-.964-2.512-1.498-2.512-.116 0-.524.245-1.221.733l-.731-.943C.943 9.263 1.7 8.588 2.445 7.912c1.024-.884 1.792-1.35 2.305-1.397 1.21-.116 1.955.712 2.235 2.483.302 1.912.511 3.101.628 3.566.349 1.586.733 2.378 1.152 2.378.326 0 .815-.515 1.467-1.543.651-1.029 1-1.812 1.047-2.349.093-.888-.256-1.333-1.047-1.333-.373 0-.757.085-1.151.255.764-2.504 2.224-3.721 4.38-3.652 1.598.047 2.351 1.084 2.259 3.111" />
      <path d="M22.281 1.918c-.023.58-.314 1.136-.874 1.669a3.12 3.12 0 01-2.234.903c-1.327 0-1.968-.579-1.921-1.737.022-.602.378-1.182 1.064-1.738.687-.555 1.449-.834 2.288-.834.489 0 .896.192 1.223.574.325.382.477.77.454 1.163zm3.038 12.419c-.652 1.232-1.548 2.349-2.689 3.349-1.56 1.349-3.119 2.024-4.679 2.024-.723 0-1.275-.233-1.659-.699-.384-.465-.565-1.069-.541-1.814.022-.767.261-1.954.715-3.56.454-1.605.682-2.466.682-2.582 0-.605-.21-.908-.629-.908-.139 0-.536.245-1.188.733l-.803-.943 2.235-2.025c1.001-.884 1.746-1.35 2.236-1.397.768-.069 1.332.157 1.693.679.36.523.494 1.2.402 2.035-.303 1.415-.629 3.212-.978 5.392-.024.998.337 1.496 1.082 1.496.326 0 .908-.344 1.746-1.033.699-.574 1.269-1.114 1.712-1.62l.663.873" />
      <path d="M47.127 14.336c-.652 1.233-1.548 2.349-2.689 3.349-1.56 1.349-3.12 2.024-4.679 2.024-1.514 0-2.247-.837-2.2-2.513.022-.745.168-1.639.436-2.686.267-1.048.413-1.862.436-2.444.024-.883-.245-1.326-.806-1.326-.607 0-1.331.722-2.172 2.165-.887 1.514-1.367 2.98-1.436 4.4-.05 1.002.05 1.77.293 2.305-1.624.047-2.762-.221-3.411-.803-.582-.512-.848-1.361-.801-2.549.02-.745.136-1.49.343-2.235.205-.745.319-1.408.342-1.991.05-.861-.268-1.292-.944-1.292-.583 0-1.213.664-1.888 1.991a10.141 10.141 0 00-1.119 4.155c-.05 1.305.04 2.212.25 2.724-1.598.047-2.733-.29-3.404-1.01-.558-.603-.812-1.52-.765-2.751.02-.603.129-1.445.321-2.524.192-1.08.299-1.921.321-2.525.05-.417-.06-.627-.314-.627-.14 0-.536.236-1.188.707l-.838-.943c.117-.092.849-.768 2.2-2.025.978-.907 1.641-1.373 1.99-1.396.606-.047 1.094.203 1.467.75.372.547.559 1.182.559 1.903 0 .233-.02.454-.07.664a6.433 6.433 0 011.222-1.398c1.071-.93 2.27-1.455 3.597-1.571 1.141-.093 1.955.174 2.445.803.395.512.581 1.246.558 2.2.163-.139.338-.291.525-.454.534-.628 1.058-1.128 1.57-1.501.861-.629 1.759-.978 2.689-1.048 1.118-.093 1.921.173 2.41.8.418.51.605 1.241.559 2.191-.024.65-.181 1.595-.472 2.836-.292 1.241-.436 1.953-.436 2.139-.024.488.023.824.139 1.009.117.186.395.278.838.278.326 0 .907-.344 1.746-1.034.698-.573 1.269-1.113 1.712-1.619l.664.872" />
      <path d="M52.295 10.654c.022-.625-.233-.938-.767-.938-.698 0-1.407.481-2.127 1.442-.721.961-1.093 1.882-1.116 2.762-.013 0-.013.151 0 .452a7.86 7.86 0 002.964-1.911c.674-.741 1.022-1.344 1.046-1.807zm7.927 3.646c-.675 1.117-2.002 2.232-3.981 3.348-2.467 1.418-4.971 2.126-7.508 2.126-1.885 0-3.237-.628-4.051-1.885-.582-.861-.861-1.885-.838-3.072.023-1.885.862-3.677 2.515-5.377 1.815-1.862 3.957-2.794 6.425-2.794 2.282 0 3.492.93 3.632 2.787.093 1.184-.559 2.404-1.956 3.658-1.49 1.371-3.365 2.241-5.622 2.612.418.581 1.046.871 1.885.871 1.676 0 3.504-.426 5.483-1.279 1.42-.599 2.538-1.221 3.353-1.866l.663.871" />
      <path d="M65.755 11.828a4.407 4.407 0 00-.262-1.732c-.198-.524-.484-.788-.855-.788-1.188 0-2.166.642-2.933 1.925-.653 1.05-1.003 2.17-1.048 3.358-.024.584.081 1.098.314 1.54.255.514.616.77 1.083.77 1.047 0 1.944-.617 2.689-1.854.628-1.027.965-2.1 1.012-3.219zm3.946.132c-.093 2.139-.884 3.987-2.374 5.544-1.49 1.557-3.342 2.336-5.553 2.336-1.839 0-3.236-.593-4.19-1.779-.698-.883-1.083-1.987-1.152-3.311-.118-2 .604-3.836 2.165-5.51 1.676-1.859 3.782-2.789 6.32-2.789 1.629 0 2.863.547 3.702 1.639.792 1 1.152 2.29 1.082 3.87" />
    </svg>
  );
}

export default ({ onSave, ...modalProps }) => {
  const [form] = Form.useForm();
  const [type, setType] = useState<MediaTypeEnum | null | undefined>(null);
  const [publicId, setId] = useState<any>(null);

  function onChange(e) {
    setType(e.target.value);
  }

  return (
    <Modal
      width={400}
      destroyOnClose
      {...modalProps}
      footer={null}
      title={<div className="font-bold font-headline uppercase">Add Video</div>}
      bodyStyle={{ padding: '12px 24px 0' }}
    >
      <Form layout="vertical" form={form} onFinish={onSave} initialValues={{ type, publicId: publicId }}>
        <Form.Item name="type" rules={[{ required: true }]} label="Video Provider">
          <Radio.Group onChange={onChange} className="mb-4">
            <Radio value={MediaTypeEnum.VIMEO}>
              <VimeoLogo className="inline-block" />
            </Radio>
            <Radio value={MediaTypeEnum.YOUTUBE}>
              <YouTubeLogo className="inline-block" style={{ minWidth: 59 }} />
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Enter video ID" required>
          <Form.Item noStyle name="publicId" rules={[{ required: true }]}>
            <Input style={{ width: 160 }} onChange={e => setId(e.currentTarget.value)} />
          </Form.Item>

          <Tooltip
            title={
              <>
                <div className="mb-4">The video ID is usually the text at the end of your embed URL.</div>
                <div>
                  (e.g., http://youtu.be/
                  <span className="font-bold">M7lc1UVf-VE</span>)
                </div>
              </>
            }
          >
            <Button type="link">Need Help?</Button>
          </Tooltip>
        </Form.Item>

        {type && publicId && (
          <Form.Item>
            <Embed type={type} publicId={publicId} />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" icon={<SaveOutlined />} size="large" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
