import { useApolloClient } from '@apollo/react-hooks';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import Text from '../../components-core/text';
import LOCATION_PREDICTION_QUERY from '../../graphql/queries/LocationPredictionQuery';
import { LocationPredictionQuery_locationPrediction } from '../../graphql/queries/typings/LocationPredictionQuery';
import { removeTypename } from '../../utils/objectUtils';
import createLogger from '../../utils/createLogger';

interface LatLon {
  lat: number;
  lon: number;
}

const logger = createLogger('location-select');

const LocationSelect: React.FC<any> = ({
  initialLatLon,
  onChange,
  onSelect,
  onClear,
  value,
  forwardedRef,
  ...rest
}) => {
  const [predictions, setPredictions] = useState<LocationPredictionQuery_locationPrediction[]>([]);
  const [selectValue, setSelectValue] = useState([]);
  const client = useApolloClient();

  function lookup(input) {
    logger('lookup -%O', input);
    return client.query({
      query: LOCATION_PREDICTION_QUERY,
      variables: { input },
      context: {
        clientName: 'public',
        debounceKey: 'citiesAutocomplete',
      },
    });
  }

  useEffect(() => {
    if (value) {
      let lat = value.lat;
      let lon = value.lon;
      // @ts-ignore
      if (value.coords) {
        // @ts-ignore
        lat = value.coords.lat;
        // @ts-ignore
        lon = value.coords.lon;
      }

      if (localStorage) {
        logger('get cached location');

        try {
          const rawSelectedValue = localStorage.getItem(JSON.stringify({ lat, lon }));
          if (rawSelectedValue) {
            const selectedValue = JSON.parse(rawSelectedValue);
            logger(selectedValue);
            setSelectValue(selectedValue.formatted);
          } else if (value?.locality) {
            setSelectValue(
              // @ts-ignore
              [value?.locality, value?.region, value?.country].join(', ')
            );
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [value]);

  const handleChange = async input => {
    logger('handle change', input);
    if (!input) {
      setPredictions([]);
      setSelectValue([]);
      if (onChange) {
        // @ts-ignore
        onChange(null);
      }
      if (onClear) {
        // @ts-ignore
        onClear(null);
      }

      return;
    }

    try {
      const { data } = await lookup(input);
      if (data && data.locationPrediction) {
        logger('predictions %o', data.locationPrediction);
        setPredictions(data.locationPrediction);
      }
    } catch (e) {
      console.error(e);
    }

    // if (services && services.autoCompleteService) {
    //   services.autoCompleteService.getQueryPredictions(
    //     { input, types: ['(cities)'] },
    //     (results: any, status: string) => {
    //       if (status === 'OK') {
    //         setPredictions(
    //           results.filter(result => result.types.indexOf('locality') !== -1)
    //         );
    //       }
    //     }
    //   );
    // }
  };

  const handleSelection = (selection: any) => {
    const selectedValue = predictions.find(prediction => prediction.formatted === selection);
    logger('selected %o', selectedValue);

    setSelectValue(selection);
    setPredictions([]);

    // @ts-ignore
    const { id, formatted, ...locationStrict } = selectedValue;

    if (onChange) {
      onChange(removeTypename(locationStrict));
    }
    if (!onSelect || !selectedValue) {
      return;
    }
    // @ts-ignore
    onSelect(removeTypename(locationStrict));

    if (localStorage) {
      logger('setting local storage', selectedValue);
      localStorage.setItem(
        JSON.stringify(removeTypename(selectedValue?.coords ?? {})),
        JSON.stringify(removeTypename(selectedValue))
      );
    }
    // services.geocoderService.geocode(
    //   { placeId: selectedValue.place_id },
    //   (results: any[], status: string) => {
    //     if (status !== 'OK' || !results[0]) {
    //       return;
    //     }
    //     const { address_components } = results[0];
    //     const { lat, lng } = results[0].geometry.location;
    //     const location: any = {
    //       coords: {
    //         lat: lat(),
    //         lon: lng(),
    //       },
    //     };
    //
    //     address_components.forEach((item: any) => {
    //       // console.log(item);
    //       if (item.types.indexOf('locality') !== -1) {
    //         location.locality = item.long_name;
    //       }
    //       if (item.types.indexOf('administrative_area_level_1') !== -1) {
    //         location.region = item.short_name;
    //       }
    //       if (item.types.indexOf('country') !== -1) {
    //         location.country = item.short_name;
    //       }
    //     });
    //     if (!location.locality) {
    //       address_components.forEach((item: any) => {
    //         if (
    //           item.types.indexOf('sublocality') !== -1 ||
    //           item.types.indexOf('natural_feature') !== -1 ||
    //           item.types.indexOf('establishment') !== -1
    //         ) {
    //           location.locality = item.long_name;
    //         }
    //       });
    //     }
    //     // console.log(location);
    //
    //     onChange(location);
    //   }
    // );
  };

  const options = predictions.map((prediction, i) => (
    // @ts-ignore
    <Select.Option key={prediction.id} value={prediction.formatted}>
      {prediction.formatted}
    </Select.Option>
  ));

  return (
    <Select
      allowClear
      autoClearSearchValue={false}
      placeholder="Enter location"
      showArrow={false}
      // dropdownMenuStyle={
      //   options.length > 0
      //     ? {
      //         paddingBottom: 37,
      //         background:
      //           'rgba(0, 0, 0, 0) url("/static/powered_by_google_on_white_hdpi.png") no-repeat scroll 10px 93% / 144px 18px',
      //       }
      //     : {}
      // }
      // @ts-ignore
      // @ts-ignore
      // {
      //...(selectValue ? {value: [selectValue]} : {})
      // }
      showSearch
      // size="large"
      ref={forwardedRef}
      // @ts-ignore
      notFoundContent={<Text>{selectValue ? 'Search for a location' : ''}</Text>}
      style={{ minWidth: 220 }}
      {...rest}
      value={selectValue}
      onSearch={handleChange}
      onChange={handleChange}
      onSelect={handleSelection}
    >
      {options}
    </Select>
  );
};

export default LocationSelect;

// .rc-select-dropdown-menu {
//   padding-bottom: 20px;
//   background: url('/static/images/powered_by_google_on_white.png') bottom left no-repeat;
//   background-size: auto;
//   background-size: 144px 18px;
// }
