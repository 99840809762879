import gql from 'graphql-tag';
import JobPostContractFragment from '../fragments/JobPostContractFragment';
import JobPostDetailsFragment from '../fragments/JobPostDetailsFragment';
import JobPostProposalFragment from '../fragments/JobPostProposalFragment';

export default gql`
  ${JobPostProposalFragment}
  ${JobPostDetailsFragment}
  ${JobPostContractFragment}
  ${JobPostDetailsFragment}
  query JobPostQuery(
    $id: ID
    $slug: String
    $proposalSortBy: ProposalsSortBy
    $proposalsLimit: Int
    $proposalNextToken: String
  ) {
    jobPost(id: $id, slug: $slug) {
      proposals {
        liked(limit: $proposalsLimit, sortBy: $proposalSortBy, nextToken: $proposalNextToken) {
          items {
            ...JobPostProposalFragment
          }
          nextToken
        }
        neutral(limit: $proposalsLimit, sortBy: $proposalSortBy, nextToken: $proposalNextToken) {
          items {
            ...JobPostProposalFragment
          }
          nextToken
        }
        hidden(limit: $proposalsLimit, sortBy: $proposalSortBy, nextToken: $proposalNextToken) {
          items {
            ...JobPostProposalFragment
          }
          nextToken
        }
      }
      contract {
        ...JobPostContractFragment
      }
      id
      jobCreatedAt: createdAt
      details {
        ...JobPostDetailsFragment
      }
      creatorFeedback {
        createdAt
        ratings {
          overall
        }
      }
      ownerProfile {
        ... on ProtectedCreator {
          id
          layerId
        }
        ... on ProtectedClient {
          id
          avatar {
            publicId
          }
          name
          layerId
          slug
          user {
            id
            givenName
            familyName
          }
        }
      }
      visibility {
        public
        listed
        creatorFilters {
            creatorIds
            tags
            tiers
            internalRating {
                min
                max
            }
            styles
        }
      }
      jobSlug: slug
      status
      title
      description
      skill
      updatedAt
      proposalsCount
    }
  }
`;
