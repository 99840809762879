import React from 'react';
import Svg from '../Svg';
export const SvgPencil = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#pencil_svg__clip0)" stroke="currentColor">
      <path
        d="M19.5 10.832v2.433a6.23 6.23 0 01-6.235 6.235h-6.53A6.23 6.23 0 01.5 13.265v-6.53A6.23 6.23 0 016.734.5h3.267"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.447 10.296l-1.452 3.997 4.28-1.168 9.297-9.296L16.744 1l-9.297 9.296z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.447 3.24l1.886 1.885M8.2 10.487l1.885 1.886" strokeLinecap="square" />
    </g>
    <defs>
      <clipPath id="pencil_svg__clip0">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </Svg>
);
SvgPencil.isIcon = true;
SvgPencil.defaultProps = {
  size: 24,
};
export default SvgPencil;
