import React from 'react';
import Svg from '../Svg';
export const SvgUserCard = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <rect x={1} y={2} width={12} height={10} rx={1} stroke="currentColor" />
    <path d="M3.15 9.397a1.75 1.75 0 013.5.003" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx={4.95} cy={5.85} r={1.5} stroke="currentColor" />
    <path stroke="currentColor" strokeLinecap="round" d="M8.25 5H11M8.25 7.5H11" />
  </Svg>
);
SvgUserCard.isIcon = true;
SvgUserCard.defaultProps = {
  size: 24,
};
export default SvgUserCard;
