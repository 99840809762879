import merge from 'lodash.merge';
import React, { useState } from 'react';
import Absolute from '../../../../components-core/absolute';
import Button from '../../../../components-core/button';
import CloseButton from '../../../../components-core/close-button';
import Container from '../../../../components-core/container';
import Flex from '../../../../components-core/flex';
import Icon from '../../../../components-core/icon';
import { default as HImage } from '../../../../components-core/image';
import Relative from '../../../../components-core/relative';
import Text from '../../../../components-core/text';
import TextButton from '../../../../components-core/text-button';
import { MoodBoardUploadButton, StyledFileInput } from '../../../../components/BudgetRange/styles';
import BlackTooltip from '../../../../components/SimpleTooltip/BlackTooltip';
import { buildCloudinaryImageUrl } from '../../../../utils/cloudinaryUtils';
import { removeTypename } from '../../../../utils/objectUtils';
import { handleCloudinaryUpload } from '../../../../utils/uploadUtils';

export const MoodBoardStep = ({
  getFieldDecorator,
  form,
  currentStep,
  payload,
  jobStrength,
  handleNext,
  toggleFooter,
  setPayload,
  textAlign,
  onChange,
  className,
}: any) => {
  const samples = [
    'post-a-job/moodboard-samples/1',
    'post-a-job/moodboard-samples/2',
    'post-a-job/moodboard-samples/3',
    'post-a-job/moodboard-samples/4',
    'post-a-job/moodboard-samples/5',
    'post-a-job/moodboard-samples/6',
  ];
  const empty = [null, null, null, null, null, null, null, null];
  // console.log(get(payload, 'details.moodBoard'));
  const items = payload?.details.moodBoard ?? empty;
  let defaultState =
    items.map(item => {
      if (!item) {
        return null;
      }
      if (typeof item === 'string') {
        return item;
      }
      if (item.publicId) {
        return item.publicId;
      }
      return item;
    }) || empty;
  if (defaultState.length === 0) {
    defaultState = empty;
  } else {
    const extra = empty.splice(defaultState.length, empty.length - defaultState.length);
    defaultState = defaultState.concat(extra);
  }

  const [showSample, setShowSample] = useState(false);
  const [images, setImages] = useState(removeTypename(defaultState));
  const [uploadingIndex, setUploading] = useState(null);

  const handleSampleViewToggle = () => {
    if (toggleFooter) {
      toggleFooter();
    }
    setShowSample(prev => !prev);
  };

  const preloadHolderId = 'preload-image';
  const handleFileSelect = index => async e => {
    setUploading(index);
    const [file] = e.target.files;
    if (!file) {
      return;
    }
    const cloudinaryResponse = await handleCloudinaryUpload(file);
    if (!cloudinaryResponse) {
      return;
    }
    const width = 90;

    const existingHolderImage: any = document.getElementById(preloadHolderId);
    const newSource = buildCloudinaryImageUrl(cloudinaryResponse.public_id, `w_${width! * 2},h_${width! * 2},c_fill`);
    const handleImageLoad = () => {
      setUploading(null);
      setImages(images => {
        const newImages = [...images];
        newImages[index] = cloudinaryResponse.public_id;
        setPayload(prev => {
          const merged = merge(prev, { details: { moodBoard: newImages } });
          if (onChange) {
            onChange(merged);
          }
          return merged;
        });
        return newImages;
      });
    };

    if (existingHolderImage) {
      existingHolderImage.src = newSource;
      existingHolderImage.onload = handleImageLoad;
    } else {
      const image: any = new Image(width, width);
      image.src = newSource;
      image.style.display = 'none';
      image.id = preloadHolderId;
      image.onload = handleImageLoad;
      document.body.appendChild(image);
    }
  };

  const handleRemove = index => () => {
    setImages(images => {
      const newImages = [...images];
      newImages[index] = null;
      setPayload(prev => {
        const merged = merge(prev, {
          details: {
            moodBoard: newImages.filter(i => i !== null).length === 0 ? null : newImages,
          },
        });
        if (onChange) {
          onChange(merged);
        }
        return merged;
      });
      return newImages;
    });
  };

  return (
    <Container px={textAlign === 'left' ? 0 : 4} className={className}>
      {!showSample && (
        <Text textAlign={textAlign || 'center'} mb={4}>
          <Text fontSize="18px" bold>
            Mood board upload{' '}
            <BlackTooltip>
              <Text minWidth="256px" textAlign="left">
                A mood board is a collection of images to communicate your visual direction and creative ideas to your
                photographer.
              </Text>
            </BlackTooltip>
          </Text>
          <Text mt={2} mb={3} fontSize="16px" color="#6F6F6F">
            Add inspirational images to show your vision to your creator!
          </Text>
          <TextButton color="blue" onClick={handleSampleViewToggle}>
            Show a sample
          </TextButton>
        </Text>
      )}

      <Flex flexWrap="wrap" alignItems="center" pb={4}>
        {showSample
          ? samples.map((image, i) => (
              <Relative width={1 / 3} key={i} mb={2}>
                {image ? (
                  <Relative>
                    <Flex alignItems="center" justifyContent="center">
                      <HImage
                        borderRadius={2}
                        width={164}
                        height={162}
                        src={buildCloudinaryImageUrl(image, 'w_164,h_162,c_fill')}
                      />
                    </Flex>
                  </Relative>
                ) : null}
              </Relative>
            ))
          : images.map((image, i) => (
              <Relative width={1 / 4} key={i} mb={2}>
                {image ? (
                  <Relative>
                    <Flex alignItems="center" justifyContent="center">
                      <Absolute top={0} right={'50%'} mr="-50px">
                        <CloseButton color="white" onClick={handleRemove(i)} />
                      </Absolute>
                      <HImage
                        borderRadius={2}
                        width={100}
                        height={90}
                        src={buildCloudinaryImageUrl(image, 'w_100,h_90,c_fill')}
                      />
                    </Flex>
                  </Relative>
                ) : (
                  <Flex height="90px" alignItems="center" justifyContent="center">
                    <StyledFileInput type="file" accept="image/*" onChange={handleFileSelect(i)} />
                    <Text textAlign="center">
                      {uploadingIndex === i ? (
                        <Icon name="Spinner" color="blue" size={24} />
                      ) : (
                        <MoodBoardUploadButton />
                      )}
                    </Text>
                  </Flex>
                )}
              </Relative>
            ))}
      </Flex>

      {showSample && (
        <Flex alignItems="center" justifyContent="center">
          <Button onClick={handleSampleViewToggle}>Close Sample</Button>
        </Flex>
      )}
    </Container>
  );
};

export default MoodBoardStep;
