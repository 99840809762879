import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, notification } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import BOOKMARK_CREATE_MUTATION from '../../graphql/mutations/BookmarkCreate';
import BOOKMARK_DELETE_MUTATION from '../../graphql/mutations/BookmarkDelete';
import { BookmarkCreate, BookmarkCreateVariables } from '../../graphql/mutations/typings/BookmarkCreate';
import { BookmarkDelete, BookmarkDeleteVariables } from '../../graphql/mutations/typings/BookmarkDelete';
import BOOKMARK_IDS_QUERY from '../../graphql/queries/BookmarkIds';
import BOOKMARKS_QUERY from '../../graphql/queries/Bookmarks';
import { BookmarkIds } from '../../graphql/queries/typings/BookmarkIds';
import BookmarkIcon from '../BookmarkIcon/BookmarkIcon';

const openBookmarkNotification = creator => {
  notification.open({
    message: (
      <React.Fragment>
        <strong>{creator.name}</strong> has been added to your bookmarks!
      </React.Fragment>
    ),
    icon: <BookmarkIcon style={{ color: '#108ee9' }} />,
  });
};

const BookmarkButton = ({ small, creator }: any) => {
  const [bookmarked, setBookmarked] = useState(false);
  const bookmarkIdsQuery = useQuery<BookmarkIds>(BOOKMARK_IDS_QUERY);
  const [bookmarkCreate] = useMutation<BookmarkCreate, BookmarkCreateVariables>(BOOKMARK_CREATE_MUTATION);
  const [bookmarkDeleteMutation] = useMutation<BookmarkDelete, BookmarkDeleteVariables>(BOOKMARK_DELETE_MUTATION);

  const bookmarkIds = bookmarkIdsQuery.data?.bookmarkIds?.items ?? [];
  useEffect(() => {
    if (bookmarked) {
      return;
    }
    if (bookmarkIds.length > 0) {
      if (bookmarkIds.filter(id => id === creator.id).length > 0) {
        setBookmarked(true);
      }
    }
    // eslint-disable-next-line
  }, [bookmarkIds, bookmarkIdsQuery, bookmarked]);

  const handleBookmarkToggle = async e => {
    e.preventDefault();
    e.stopPropagation();
    const options = {
      variables: { id: creator.id },
      refetchQueries: [{ query: BOOKMARKS_QUERY }, { query: BOOKMARK_IDS_QUERY }],
      awaitRefetchQueries: true,
    };

    if (bookmarked) {
      await bookmarkDeleteMutation({
        ...options,
      });
      setBookmarked(false);
      return;
    }
    openBookmarkNotification(creator);
    setBookmarked(true);
    return bookmarkCreate({
      ...options,
    });
  };
  return (
    <div
      className={classNames('bookmark-button', {
        'is-small': small,
        'is-bookmarked': bookmarked,
      })}
    >
      <Button
        htmlType="button"
        shape="circle"
        size="large"
        type={bookmarked ? 'primary' : 'ghost'}
        onClick={handleBookmarkToggle}
      >
        <BookmarkIcon style={{ marginTop: 4, marginRight: -1 }} />
      </Button>
    </div>
  );
};

BookmarkButton.defaultProps = {
  small: false,
  bookmarkIds: {
    bookmarkIds: {
      items: [],
    },
  },
};

export default BookmarkButton;
