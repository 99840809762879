import React, { FunctionComponent } from 'react';
import Icon from '../icon';
import theme from '../theme';
import TransparentButton from '../transparent-button';

const CloseButton: FunctionComponent<any> = ({ size, color, as, circle, ...props }) => (
  <TransparentButton {...props}>
    <Icon name={circle ? 'CloseCircle' : 'Close'} size={size} color={color} />
  </TransparentButton>
);

CloseButton.defaultProps = {
  size: 24,
  title: 'Close',
  circle: false,
  theme,
};

CloseButton.displayName = 'CloseButton';

export default CloseButton;
