import gql from 'graphql-tag';

export default gql`
  fragment FileFragment on File {
    key
    name
    url
    uploadUrl
    extension
    mimeType
    size
  }
`;
