import React from 'react';
import Svg from '../Svg';
export const SvgLogoFacebook = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M18.897 0H1.103A1.103 1.103 0 000 1.103v17.794A1.103 1.103 0 001.103 20h9.587v-7.735H8.086V9.238h2.604V7.01c0-2.582 1.579-3.99 3.882-3.99.777-.001 1.552.038 2.325.118v2.7H15.31c-1.255 0-1.5.593-1.5 1.469v1.927h3l-.39 3.028h-2.627V20h5.104A1.104 1.104 0 0020 18.897V1.103A1.104 1.104 0 0018.897 0z"
      fill="currentColor"
    />
  </Svg>
);
SvgLogoFacebook.isIcon = true;
SvgLogoFacebook.defaultProps = {
  size: 24,
};
export default SvgLogoFacebook;
