import React from 'react';
import Svg from '../Svg';
export const SvgSkillsetMakeup = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 19.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M13.742 13.45l-.967 1.589c-1.605 2.107-4.208 2.107-5.814 0l-.967-1.59.356-.348c.279-.273.555-.551.816-.851 1.168-1.34 1.738-.307 2.702-.307s1.582-.991 2.702.307c.26.302.538.578.816.851l.356.349z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.995 13.45s3.483 1.878 7.747 0M16.174 6.013a2.433 2.433 0 01-4.865 0M13.742 8.445v1.34M12.09 7.798l-.926.926M15.393 7.798l.926.926"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.993 10.072a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
      fill="currentColor"
    />
  </Svg>
);
SvgSkillsetMakeup.isIcon = true;
SvgSkillsetMakeup.defaultProps = {
  size: 24,
};
export default SvgSkillsetMakeup;
