import gql from 'graphql-tag';
import JobPostContractFragment from '../fragments/JobPostContractFragment';
import JobPostDetailsFragment from '../fragments/JobPostDetailsFragment';

export default gql`
  ${JobPostDetailsFragment}
  ${JobPostContractFragment}
  ${JobPostDetailsFragment}
  query CreatorJobPostQuery($id: ID, $slug: String) {
    jobPost(id: $id, slug: $slug) {
      contract {
        ...JobPostContractFragment
      }
      id
      jobCreatedAt: createdAt
      details {
        ...JobPostDetailsFragment
      }
      ownerProfile {
        ... on ProtectedCreator {
          id
          layerId
        }
        ... on ProtectedClient {
          id
          avatar {
            publicId
          }
          name
          layerId
          slug
          user {
            id
            givenName
            familyName
          }
        }
      }
      visibility {
        public
        listed
      }
      jobSlug: slug
      status
      title
      description
      skill
      updatedAt
      proposalsCount
    }
  }
`;
