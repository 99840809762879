import styled from 'styled-components/macro';
import { borderRadius, layout, space } from 'styled-system';
import theme from '../theme';
import { ImageProps } from '../types';

const Image = styled.img<ImageProps>`
  display: block;
  max-width: 100%;
  height: auto;
  ${borderRadius}
  ${space}
  ${layout}
`;

Image.displayName = 'Image';

Image.defaultProps = {
  theme,
};

export default Image;
