import React from 'react';
import Svg from '../Svg';
export const SvgUser = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fill="transparent"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6.005a5 5 0 11-10-.01 5 5 0 0110 .01z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="transparent"
      d="M2 18.849c.004-4.095 3.59-7.412 8.008-7.408 4.418.005 7.997 3.327 7.992 7.422"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
SvgUser.isIcon = true;
SvgUser.defaultProps = {
  size: 24,
};
export default SvgUser;
