import { useApolloClient } from '@apollo/react-hooks';
import { Button, Select } from 'antd';
import React from 'react';
import adminTagPrediction from '../../graphql/queries/AdminTagPrediction';

const TagTypeahead = ({ mode, onAddClick, onChange, onRef, onSearch, showAddTag, placeholder, ref, ...rest }: any) => {
  const client = useApolloClient();
  const [selectedTags, setSelectedTags] = React.useState(rest.selectedTags || []);
  const [tagQuery, setTagQuery] = React.useState(null);
  const [foundTags, setFoundTags] = React.useState([]);
  const [searching, setSearching] = React.useState(false);

  const handleTagSearch = async input => {
    if (!input) {
      return;
    }
    if (onSearch) {
      onSearch(input, searching);
    }
    if (input && input.length > 1) {
      setTagQuery(input);
    }
    setSearching(true);
    const { data, errors } = await client.query({
      query: adminTagPrediction,
      variables: { input },
      fetchPolicy: 'no-cache',
      context: {
        debounceKey: 'adminTagPrediction',
      },
    });
    if (errors && errors.length > 0) {
      const [error] = errors;
      console.warn(error);
    } else if (data && data.adminTagPrediction) {
      setFoundTags(data.adminTagPrediction.map(tag => tag.name));
    }
    setSearching(false);
  };
  return (
    <Select
      size="large"
      mode={mode}
      style={{ width: '100%' }}
      placeholder={placeholder}
      value={selectedTags}
      ref={onRef}
      onBlur={() => {
        setTagQuery(null);
      }}
      onChange={value => {
        setSelectedTags(value);
        if (onChange) {
          onChange(value, searching);
        }
      }}
      loading={searching}
      showSearch
      onSearch={handleTagSearch}
      notFoundContent={
        showAddTag ? (
          <div>
            No tags found{' '}
            {tagQuery ? (
              <Button
                size="small"
                onClick={() => {
                  onAddClick(tagQuery);
                  setTagQuery(null);
                }}
              >
                Create new tag "<strong>{tagQuery}</strong>"
              </Button>
            ) : null}
          </div>
        ) : (
          'No tags selected'
        )
      }
      allowClear
      {...rest}
    >
      {foundTags
        // .filter(tag =>
        //   (Array.isArray(selectedTags) ? !selectedTags.includes(tag) : selectedTags === tag))
        .map(tag => (
          <Select.Option key={tag} value={tag}>
            {tag}
          </Select.Option>
        ))}
    </Select>
  );
};

TagTypeahead.defaultProps = {
  mode: 'multiple',
  onSearch: () => {},
  onAddClick: () => {},
  onRef: () => {},
  placeholder: 'Select tags',
};

export default TagTypeahead;
