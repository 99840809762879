export function numberWithCommas(num: number) {
  // debug('numberWithCommas(%d, %d, %b)', num);
  if (!num) return 0;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 *
 * @param num
 * @param digits
 * @param showUnits
 */
export const shortenLargeNumber = (num: number, digits = 0, showUnits = true) => {
  const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let decimal;

  for (let i = units.length - 1; i >= 0; i--) {
    decimal = 1000 ** (i + 1);
    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + (showUnits ? units[i] : '');
    }
  }

  return num;
};

/**
 *
 * @param number
 * @param fallback
 */
export function pad(number?: number | null, fallback = 'XX') {
  if (!number) {
    return fallback;
  }
  return number < 10 ? '0' + number : number;
}
