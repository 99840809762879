import gql from 'graphql-tag';

export default gql`
  mutation JobPostPresignedContractAssetFileUploadRequest($contractId: ID!, $fileNames: [String]!) {
    jobPostPresignedContractAssetFileUploadRequest(contractId: $contractId, fileNames: $fileNames) {
      key
      fileName
      uploadUrl
    }
  }
`;
