import config from '../config';

const placeholderPublicId = 'oubvsnmrmclldpoauned';

export const buildCloudinaryImageUrl = (publicId: string | undefined | null, transformationString?: string) =>
  // debug('buildCloudinaryImageUrl(%s, %s)', publicId, transformationString);
  `https://res.cloudinary.com/${config.CLOUDINARY_CLOUD_NAME}/image/upload${
    transformationString ? `/f_auto,q_auto,${transformationString}` : '/f_auto,q_auto'
  }/${publicId || placeholderPublicId}`;

export const uploadImage = async (image: any) => {
  const url = config.CLOUDINARY_API_UPLOAD_URL;
  const formData = new FormData();
  formData.append('file', image);
  formData.append('upload_preset', config.CLOUDINARY_API_UPLOAD_PRESET);

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
};

export const cloundiaryResponseToMediaImageInput = (data: any) => ({
  publicId: data.public_id || data.publicUrl || data.publicId,
  // displayUrl: data.secure_url || data.displayUrl,
  // thumbnailUrl: data.secure_url || data.thumbnailUrl,
  type: 'IMAGE',
});

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};
