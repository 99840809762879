import gql from 'graphql-tag';
import JobPostDetailsFragment from './JobPostDetailsFragment';

export default gql`
  ${JobPostDetailsFragment}
  fragment ClientJobPostSummaryFragment on JobPost {
    id
    jobCreatedAt: createdAt
    details {
      ...JobPostDetailsFragment
    }
    ownerProfile {
      ... on ProtectedCreator {
        id
        layerId
      }
      ... on ProtectedClient {
        id
        avatar {
          publicId
        }
        name
        layerId
        slug
        user {
          id
          givenName
          familyName
        }
      }
    }
    visibility {
      public
      listed
    }
    jobSlug: slug
    status
    title
    description
    skill
    updatedAt
    proposalsCount
  }
`;
