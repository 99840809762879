import styled from 'styled-components/macro';
import { color, space } from 'styled-system';

const Svg = styled('svg')(
  {
    flex: 'none',
  },
  space,
  color
);

export default Svg;
