import React from 'react';
import Svg from '../Svg';
export const SvgStyles = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 21 24" fill="none" {...props}>
    <path
      d="M8.172 18.378L2.515 12.72a1 1 0 010-1.414L9.661 4.16a1 1 0 01.766-.291l5.343.314a1 1 0 01.94.94l.314 5.342a1 1 0 01-.292.766l-7.146 7.147a1 1 0 01-1.414 0z"
      stroke="currentColor"
    />
    <path clipRule="evenodd" d="M14.225 7.82a.75.75 0 11-1.06-1.06.75.75 0 011.06 1.06z" stroke="currentColor" />
    <path
      d="M2.515 16.157l5.657 5.657a1 1 0 001.414 0l4.95-4.95"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
SvgStyles.isIcon = true;
SvgStyles.defaultProps = {
  size: 24,
};
export default SvgStyles;
