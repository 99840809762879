import React from 'react';
import Svg from '../Svg';
export const SvgChevronUp = ({ size, ...props }) => (
  <Svg viewBox="0 0 24 24" width={size} height={size} {...props}>
    <path d="M7.4 15.4l4.6-4.6 4.6 4.6L18 14l-6-6-6 6 1.4 1.4z" fill="currentColor" />
  </Svg>
);
SvgChevronUp.isIcon = true;
SvgChevronUp.defaultProps = {
  size: 24,
};
export default SvgChevronUp;
