import React from 'react';
import Svg from '../Svg';
export const SvgSignOut = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path d="M12.2 13.6a7 7 0 110-11.2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.6 7.5a.5.5 0 100 1v-1zm8.754.854a.5.5 0 000-.708l-3.182-3.182a.5.5 0 10-.708.708L14.293 8l-2.829 2.828a.5.5 0 00.707.708l3.183-3.182zM6.6 8.5H15v-1H6.6v1z"
      fill="currentColor"
    />
  </Svg>
);
SvgSignOut.isIcon = true;
SvgSignOut.defaultProps = {
  size: 24,
};
export default SvgSignOut;
