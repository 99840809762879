import React from 'react';
import { createGlobalStyle } from 'styled-components/macro';
import Flex from '../components-core/flex';
import Text from '../components-core/text';
import VideoBackground from '../components/VideoBackground/VideoBackground';

const GlobalErrorStyles = createGlobalStyle`
html {
  margin: 0;
  background: url(${require('../assets/images/GradientBg.png')}) no-repeat center center fixed;
  background-size: cover;
}
html::after {
  content: '';
  background: url(${require('../assets/images/static.gif')});
  opacity: 0.05;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
`;

class ErrorScreen extends React.Component<{
  statusCode?: number;
  message?: string;
}> {
  static defaultProps = {
    statusCode: 404,
    message: 'Uh oh. Something broke.',
  };
  render() {
    return (
      <VideoBackground>
        <GlobalErrorStyles />
        <Flex
          position="absolute"
          width="100%"
          height="100vh"
          // top="0"
          // left="0"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Flex
            width={290}
            height={290}
            borderRadius={2}
            borderStyle="solid"
            borderWidth="2px"
            borderColor="white"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Flex width={200} height={200} alignItems="center" justifyContent="center" flexDirection="column">
              <Text color="white">
                {/* {this.props.statusCode} */}
                <Text fontSize={5} bold lineHeight="1.2">
                  {this.props.statusCode === 404 ? <>The page you're looking for doesnt exist</> : this.props.message}
                </Text>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </VideoBackground>
    );
  }
}

export default ErrorScreen;
