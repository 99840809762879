import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { Affix, Badge, Button, Drawer, Layout as AntdLayout, Modal, notification } from 'antd';
import React, { useContext, useState } from 'react';
import Media from 'react-media';
import { Link, useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components/macro';
import Icon from '../../components-core/icon';
import LogoSmall from '../../components-core/logo-small';
import { buildCloudinaryImageUrl } from '../../utils/cloudinaryUtils';
import Announcements from '../Announcements';
import { AnnouncementContext } from '../Announcements/AnnouncmentContext';
import { UserContext } from '../Authenticator';
import GlobalNav from '../GlobalNav/GlobalNav';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`;

function Layout({ children }) {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = window.innerWidth < parseInt(theme.breakpoints.md, 10);
  const isLargeDesktop = window.innerWidth > parseInt(theme.breakpoints.xl, 10);
  // console.log(theme.breakpoints);
  const user = useContext(UserContext);
  const { announcements, removeAllAnnouncements, loading } = useContext(AnnouncementContext);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [activityDrawerOpen, setActivityDrawerOpen] = useState(isLargeDesktop && announcements.length > 0);
  const userAvatar = user?.avatar?.publicId;

  if (!user) {
    return children || null;
  }

  function closeDrawer() {
    setActivityDrawerOpen(false);
  }

  const siderContent = (
    <div className="flex flex-col justify-between h-full">
      <div className="mt-4 mb-1 ">
        <div className="relative left-0 ml-2 lg:ml-0">
          <div className="text-center">
            <img
              alt={user?.name}
              className="inline rounded-full"
              style={{ maxWidth: 55 }}
              src={
                userAvatar
                  ? buildCloudinaryImageUrl(userAvatar, `w_120,h_120,c_fill`)
                  : require('../../assets/images/avatar-default.jpg')
              }
            />
          </div>
        </div>

        <div className="text-center mt-4 md:hidden lg:block">
          <div className="font-bold text-white leading-none">
            {user?.user?.givenName} {user?.user?.familyName}
          </div>
          <div className="font-bold text-xs text-white text-opacity-50 capitalize">
            {user?.__typename === 'Client' ? user?.name : (user?.skill?.name ?? '').toLowerCase()}
          </div>
          {/*<Rating percent={0.8} mx="auto" />*/}
        </div>
      </div>
      <div className="flex-1">
        <GlobalNav onNavItemClick={() => setMobileNavOpen(false)} />
      </div>
      {user && user.__typename === 'Client' && (
        <div className="flex items-center justify-center flex-col opacity-75 mb-3 md:hidden lg:flex">
          <Link to="/app/post-a-job">
            <Button ghost size="small">
              <span className="uppercase font-bold text-xs px-4">Post a job</span>
            </Button>
          </Link>
          {/*<TextButton*/}
          {/*  color="rgba(255,255,255,.7)"*/}
          {/*  caps*/}
          {/*  bold*/}
          {/*  fontSize={0}*/}
          {/*  mt={2}*/}
          {/*>*/}
          {/*  Need Help?*/}
          {/*</TextButton>*/}
        </div>
      )}
      <div className="text-center px-3 mx-auto mb-4">
        <LogoSmall width={40} height={40} className="inline" />
        <div className="text-white text-opacity-75 mt-2 text-xs">
          &copy; H Influencer Collective.
          <br /> All Rights Reserved.
        </div>
      </div>
    </div>
  );

  const notificationButton = (
    <button
      className="h-auto p-4 -mb-2 -mr-2"
      // css={{ height: 'auto', padding: '10px' }}
      onClick={() => setActivityDrawerOpen(true)}
    >
      <Badge
        count={announcements.length}
        style={{
          borderColor: theme.colors['red'],
          backgroundColor: theme.colors['red'],
          border: 0,
          // transform: 'translate3d(130%, -3px, 0)',
        }}
      >
        <Icon name="Bell" color="white" />
      </Badge>
    </button>
  );

  return (
    <>
      <AntdLayout>
        <Media
          queries={{
            small: `(max-width: ${parseInt(theme.breakpoints.md) - 1}px)`,
          }}
        >
          {matches =>
            matches.small ? (
              <div className="relative">
                <Affix>
                  <Header
                    className="bg-black text-white flex lg:none px-4 py-2"
                    // bg="black"
                    // color="white"
                    // display={['flex', 'flex', 'flex', 'none']}
                    // px={3}
                    // py={1}
                  >
                    <button
                      onClick={() => {
                        setMobileNavOpen(prev => !prev);
                      }}
                    >
                      <Icon name="Hamburger" />
                    </button>
                    <div className="absolute" style={{ left: '50%', marginLeft: -15, top: 12 }}>
                      <LogoSmall width={30} height={30} />
                    </div>
                    {notificationButton}
                  </Header>
                </Affix>
                <Drawer
                  placement="left"
                  visible={mobileNavOpen}
                  onClose={() => setMobileNavOpen(false)}
                  bodyStyle={{
                    padding: 0,
                    backgroundColor: theme.colors.black,
                    height: '100%',
                  }}
                >
                  {siderContent}
                </Drawer>
              </div>
            ) : (
              <AntdLayout.Sider
                style={{
                  overflow: 'hidden',
                  overflowY: 'auto',
                  height: '100vh',
                  position: 'fixed',
                  width: '48rem',
                  zIndex: 2,
                }}
                width="14rem"
                // collapsible
                // collapsedWidth="60px"
                breakpoint="lg"
                // onBreakpoint={broken => {
                //   console.log(broken);
                // }}
                // onCollapse={(collapsed, type) => {
                //   console.log(collapsed, type);
                // }}
              >
                {siderContent}
              </AntdLayout.Sider>
            )
          }
        </Media>

        <AntdLayout className="pl-0 md:pl-20 lg:pl-56">{children}</AntdLayout>
      </AntdLayout>

      {location.pathname.indexOf('/my-jobs') !== -1 && (
        <div className="hidden lg:block fixed z-10 top-0 right-0 text-black mt-6 mr-6">
          <button
            className="h-auto p-4 -mb-2 -mr-2"
            // css={{ height: 'auto', padding: '10px' }}
            onClick={() => setActivityDrawerOpen(true)}
          >
            <Badge
              count={announcements.length}
              style={{
                borderColor: theme.colors['red'],
                backgroundColor: theme.colors['red'],
                border: 0,
                // transform: 'translate3d(130%, -3px, 0)',
              }}
            >
              <Icon name="Bell" color="black" />
            </Badge>
          </button>
        </div>
      )}
      {location.pathname.indexOf('/my-jobs') !== -1 || isMobile ? (
        <Drawer
          title={
            <>
              <span className="font-bold">Activity</span>

              {announcements.length > 0 && (
                <Button
                  style={{ marginLeft: 5 }}
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: 'Are you sure you want to remove all activity notifications?',
                      okText: 'Yes, remove all',
                      okButtonProps: {
                        loading,
                      },
                      onOk: () => {
                        return removeAllAnnouncements()
                          .then(() => {
                            notification.success({
                              message: 'All activity cleared!',
                            });
                          })
                          .catch(error => {
                            notification.error({
                              message: error.message,
                            });
                          });
                      },
                    });
                  }}
                >
                  Clear
                </Button>
              )}
            </>
          }
          placement={'right'}
          closable
          onClose={closeDrawer}
          visible={activityDrawerOpen}
          mask={window.innerWidth <= 1200}
          width={window.innerWidth < 400 ? window.innerWidth : 300}
          bodyStyle={{ padding: 0 }}
        >
          <Announcements onAction={closeDrawer} />
        </Drawer>
      ) : null}
    </>
  );
}

export default Layout;
