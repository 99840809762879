import React from 'react';
import Svg from '../Svg';
export const SvgTag = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 22" fill="none" {...props}>
    <path
      d="M7.284 16.77l-4.95-4.95a1 1 0 010-1.415l6.33-6.33a1 1 0 01.765-.29l4.675.275a1 1 0 01.94.94l.274 4.674a1 1 0 01-.29.766l-6.33 6.33a1 1 0 01-1.414 0z"
      stroke="currentColor"
    />
    <path clipRule="evenodd" d="M12.802 7.338a.675.675 0 11-.954-.954.675.675 0 01.954.954z" stroke="currentColor" />
    <path
      d="M2.263 14.841l5.02 5.02a1 1 0 001.415 0l4.384-4.384"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
SvgTag.isIcon = true;
SvgTag.defaultProps = {
  size: 24,
};
export default SvgTag;
