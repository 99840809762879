import React from 'react';
import loadable from '@loadable/component';
import config from '../config/config';
import createLogger from '../utils/createLogger';

const StreamChatLib = loadable.lib(() =>
  import(/* webpackPreload: true */ /* webpackChunkName: "StreamChat" */ 'stream-chat')
);

const logger = createLogger('StreamChatClient');

const withStreamChatLib = Component => props => (
  <StreamChatLib>
    {({StreamChat}) => {
      const chatClient = new StreamChat(config.STREAM_API_KEY,
        // @ts-ignore
        {
          timeout: 20000
        },
        {
          logger: (log_level, message) => {
            logger(`[${log_level}] ${message}`);
          }
        });

      return <Component {...props} StreamChat={StreamChat} chatClient={chatClient} />;
    }}
  </StreamChatLib>
);

export default withStreamChatLib;
