import { useQuery } from '@apollo/react-hooks';
import { useContext } from 'react';
import { UserContext } from '../../components/Authenticator';
import determineJobPostQuery from './determineJobQuery';

export default function useJobQuery(options) {
  const user = useContext(UserContext);
  const JOB_POST_QUERY = determineJobPostQuery(user.__typename);
  return useQuery(JOB_POST_QUERY, options);
}
