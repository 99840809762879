import 'cleave.js/dist/addons/cleave-phone.us';
import Cleave from 'cleave.js/react';
import React, { InputHTMLAttributes, KeyboardEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import { BorderProps, flex, FlexProps, FontSizeProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import Relative from '../relative';
import defaultTheme from '../theme';
import { bordersStyle, INPUT_HEIGHT, inputStyles } from './styles';

export type InputProps = BorderProps &
  LayoutProps &
  FontSizeProps &
  SpaceProps &
  FlexProps &
  // NativeInputPropsWithoutSize &
  InputPropsBase;

type NativeInputPropsWithoutSize = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

interface InputPropsBase {
  size?: 'medium' | 'large';
  type?: string;
  isSearch?: boolean;
  error?: string | boolean | null;
  prefix?: any;
  suffix?: string | ReactNode | null;
  onPressEnter?: (e: any) => void;
  onChange?: (e: any) => void;
  value?: string;
  theme?: any;
  cleaveOptions?: any;
  css?: any;
  [key: string]: any;
  Textarea?: ReactNode;
}

const sizeStyles = (props: InputProps) => {
  switch (props.size!) {
    case 'large':
      return {
        fontSize: `${props.theme!.fontSizes[2]}px`,
        height: 42,
      };
    default:
      return {
        fontSize: `${props.theme!.fontSizes[1]}px`,
        height: INPUT_HEIGHT,
      };
  }
};

const StyledInput = styled.input<InputProps>`
  ${inputStyles}
  ${bordersStyle}
  ${space} ${flex}
  ${layout}
  ${sizeStyles}
`;

const StyledCleaveInput = styled(Cleave)<InputProps>`
  ${inputStyles}
  ${bordersStyle}
  ${layout}
  ${space} ${flex}
  ${sizeStyles}
`;

const InputIconContainer = styled.div<InputProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 30px;
  height: ${INPUT_HEIGHT}px;

  position: absolute;
  top: 0;
  z-index: 1;

  ${props =>
    props.prefix &&
    css`
      left: 0;
    `}
  ${props =>
    props.suffix &&
    css`
      right: 0;
    `}
`;

const InputBase: React.FC<InputProps> = ({
  error,
  prefix,
  suffix,
  isSearch,
  onPressEnter,
  cleaveOptions,
  forwardedRef,
  size,
  style,
  ...textInputProps
}) => {
  const handleKeyDownChange = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onPressEnter && event.which === 13) {
      onPressEnter(event);
    }
  };

  const hasCleaveOptions = typeof cleaveOptions === 'object' && Object.keys(cleaveOptions).length > 0;

  const input = hasCleaveOptions ? (
    // @ts-ignore
    <StyledCleaveInput
      // @ts-ignore
      size={size}
      pl={prefix ? '30px' : '8px'}
      pr={suffix ? '30px' : '8px'}
      // @ts-ignore
      options={cleaveOptions}
      onKeyDown={handleKeyDownChange}
      {...textInputProps}
      ref={forwardedRef}
    />
  ) : (
    <StyledInput
      // @ts-ignore
      size={size}
      pl={prefix ? '30px' : '8px'}
      pr={suffix ? '30px' : '8px'}
      onKeyDown={handleKeyDownChange}
      {...textInputProps}
      ref={forwardedRef}
    />
  );

  return (
    <Relative style={style}>
      {prefix && <InputIconContainer prefix={prefix}>{prefix}</InputIconContainer>}
      {input}
      {suffix && <InputIconContainer suffix={true}>{suffix}</InputIconContainer>}
    </Relative>
  );
};

InputBase.defaultProps = {
  type: 'text',
  theme: defaultTheme,
  cleaveOptions: {},
  size: 'medium',
};

const Input: React.FC<InputProps> = React.forwardRef((props, ref) => <InputBase {...props} forwardedRef={ref} />);

export default Input;
