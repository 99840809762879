import cx from 'classnames';
import React from 'react';

const Headline = ({ size, alt, className, as: Component, ...rest }: any) => (
  <Component
    className={cx(
      `headline font-bold uppercase m-0 text-${size}`,
      {
        'font-headline': !alt,
        'font-sans': alt,
      },
      className
    )}
    {...rest}
  />
);

Headline.defaultProps = {
  alt: false,
  className: '',
  as: 'div',
  size: '3xl',
};

export default Headline;
