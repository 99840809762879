import { Radio } from 'antd';
import merge from 'lodash.merge';
import React from 'react';
import BlackTooltip from '../../../../components/SimpleTooltip/BlackTooltip';
import Container from '../../../../components-core/container';
import { FormItem } from '../../../../components-core/form';
import Textarea from '../../../../components-core/input/Textarea';
import Text from '../../../../components-core/text';

const UsageRightsStep = ({
  getFieldDecorator,
  form,
  currentStep,
  payload,
  jobStrength,
  handleNext,
  toggleFooter,
  setPayload,
  className,
}: any) => {
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };
  return (
    <Container maxWidth={540} pt={3} pb={4} className={className}>
      <Text textAlign="center" mb={4}>
        <Text fontSize="18px" bold>
          Option for usage rights
        </Text>
        <Container maxWidth={400} className={className}>
          <Text mt={2} mb={3} color="#6F6F6F">
            Help the creators understand where you'd like to use the assets (images and/or video) and for how long.
          </Text>
        </Container>
      </Text>
      <FormItem css={{ margin: 0 }}>
        {getFieldDecorator('details.usage.type', {
          initialValue: payload?.details?.usage?.type,
          onChange: e => {
            setPayload(prev =>
              merge(prev, {
                details: {
                  usage: {
                    type: e.target.value || null,
                    // description: e.target.value === 'OTHER' ? null : get(prev, 'details.usage.description'),
                  },
                },
              })
            );
          },
        })(
          <Radio.Group
            style={{ marginBottom: 9 }}
            // flexDirection="column"
          >
            <Radio style={radioStyle} value="UNLIMITED">
              Unlimited rights{' '}
              <BlackTooltip>
                <Text bold>Unlimited Rights:</Text>
                <Text lineHeight="1.2">
                  Photographers have full rights to use the images from
                  <br /> your job wherever they choose. Including their website
                  <br /> or portfolio, on social media and print / digital mediums.
                </Text>
              </BlackTooltip>
            </Radio>
            <Radio style={radioStyle} value="DIGITAL">
              Digital Only{' '}
              <BlackTooltip>
                <Text bold>Digital Only:</Text>
                <Text lineHeight="1.2">
                  Photographers have partial rights to use the images from
                  <br /> your job only on digital platforms - their website, social media
                  <br />
                  and other online sources.
                </Text>
              </BlackTooltip>
            </Radio>
            <Radio style={radioStyle} value="OTHER">
              Other (Please Specify){' '}
              <BlackTooltip>
                <Text bold>Other:</Text>
                <Text lineHeight="1.2">
                  If you want to specify the exact usage rights for your creator,
                  <br /> add them here. Some Examples include: Photographer’s
                  <br />
                  Website Only or No Posting on Social Media.
                </Text>
              </BlackTooltip>
            </Radio>
          </Radio.Group>
        )}
      </FormItem>

      <FormItem css={{ margin: 0 }}>
        {getFieldDecorator('details.usage.description', {
          initialValue: payload?.details?.usage?.description,
          rules: [
            {
              required: form.getFieldValue('details.usage').type === 'OTHER',
              message: 'Please enter usage rights for "OTHER"',
            },
          ],
          onChange: e => {
            setPayload(prev =>
              merge(prev, {
                details: { usage: { description: e.target.value || null } },
              })
            );
          },
        })(
          <Textarea
            disabled={form.getFieldValue('details.usage').type !== 'OTHER'}
            placeholder="Enter the details for usage rights"
          />
        )}
      </FormItem>

      {/*<Input.Textarea*/}
      {/*  disabled={get(payload, 'details.usage.type') !== 'OTHER'}*/}
      {/*  placeholder="Enter the details for usage rights"*/}
      {/*  value={get(payload, 'details.usage.description')}*/}
      {/*  required={get(payload, 'details.usage.type') === 'OTHER'}*/}
      {/*  onChange={e => {*/}
      {/*    setPayload(prev =>*/}
      {/*      merge(prev, {*/}
      {/*        details: { usage: { description: e.target.value || null } },*/}
      {/*      })*/}
      {/*    );*/}
      {/*  }}*/}
      {/*/>*/}
    </Container>
  );
};

export default UsageRightsStep;
