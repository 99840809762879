import SafetyCertificateOutlined from '@ant-design/icons/SafetyCertificateOutlined';
import { useMutation } from '@apollo/react-hooks';
import loadable from '@loadable/component';
import { message, Modal, notification } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components/macro';
import Text from '../components-core/text';
import { AuthContext, UserContext } from '../components/Authenticator';
import PayoutAccountVerifyLinkRequest from '../graphql/mutations/PayoutAccountVerifyLinkRequest';
import CreatorBidSubmitScreen from '../screens/CreatorBidSubmitScreen';
import RePostAJobScreen from '../screens/PostAJobScreen/RePostAJobScreen';
import buildNavItems, { getActiveNavItem, publicNavItems } from './buildNavigationItems';
import routes from './index';

const ClientSettingsFinancialScreen = loadable(() =>
  import(
    /* webpackChunkName: "ClientSettingsFinancialScreen" */ '../screens/SettingsScreen/screens/ClientAccountSettings/screens/ClientSettingsFinancialScreen'
  )
);
const ClientSettingsLocationScreen = loadable(() =>
  import(
    /* webpackChunkName: "ClientSettingsLocationScreen" */ '../screens/SettingsScreen/screens/ClientAccountSettings/screens/ClientSettingsLocationScreen'
  )
);
const ClientSettingsPasswordScreen = loadable(() =>
  import(
    /* webpackChunkName: "ClientSettingsPasswordScreen" */ '../screens/SettingsScreen/screens/ClientAccountSettings/screens/ClientSettingsPasswordScreen'
  )
);

const ErrorScreen = loadable(() => import(/* webpackChunkName: "ErrorScreen" */ '../screens/ErrorScreen'));

let messageHolder;

const AppRoutes = ({ theme }) => {
  const history = useHistory();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);

  const isSuccessFromStripeHostedForms = location.search.indexOf('stripeResult=success') !== -1;

  const [payoutAccountVerifyLinkRequest] = useMutation(PayoutAccountVerifyLinkRequest);

  const navItems: any[] = user ? buildNavItems(user.__typename).concat(publicNavItems) : publicNavItems;

  const activeNavItem = getActiveNavItem(navItems, location.pathname);

  const head = (
    <Helmet>{activeNavItem ? <title>{activeNavItem.label} &ndash; The H Hub</title> : <title>The H Hub</title>}</Helmet>
  );

  useEffect(() => {
    if (isSuccessFromStripeHostedForms && !messageHolder) {
      notification.success({ message: 'Stripe updated!' });
      messageHolder = true;
      return;
    }
    if (isSuccessFromStripeHostedForms) {
      return;
    }
    if (auth.requiresStripeUpdate) {
      Modal.confirm({
        icon: <SafetyCertificateOutlined style={{ color: theme.colors.red }} />,
        content: (
          <Text bold fontSize={2}>
            Your Stripe account requires an update.
          </Text>
        ),
        okText: 'Fix the problem',
        okButtonProps: {
          size: 'large',
        },
        cancelText: null,
        cancelButtonProps: {
          style: {
            display: 'none',
          },
        },
        maskClosable: false,
        onOk() {
          payoutAccountVerifyLinkRequest().then(({ data }) => {
            const url = data?.payoutAccountVerifyLinkRequest?.url ?? null;
            if (url) {
              window.location.assign(url);
            } else {
              console.warn('no url returned from payoutAccountVerifyLinkRequest');
            }
          });
        },
      });
    } else if (auth.requiresPayoutUpdate || auth.requiresPayoutCreation) {
      if (auth.requiresPayoutCreation) {
        message.info('Payment source needed.');
      } else {
        message.info('Payment source update needed.');
      }
      setTimeout(() => {
        message.loading('Redirecting to financial settings', 1);
        setTimeout(() => {
          if (auth.requiresPayoutCreation) {
            history.push(`/app/settings?onboard`);
          } else {
            history.push(`/app/settings?financial`);
          }
        }, 1000);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [messageHolder]);

  if (!user) {
    return (
      <>
        {head}
        <Switch>
          {publicNavItems.map((navItem, i) => (
            <Route key={i} path={navItem.href} exact>
              {navItem.component ? <navItem.component /> : null}
            </Route>
          ))}
        </Switch>
      </>
    );
  }
  const builtRoutes = routes(user.__typename);

  return (
    <>
      {head}
      <Switch>
        {builtRoutes.map((route, index) => (
          <Route
            key={index}
            {...route.routeConfig}
            // @ts-ignore
            component={loadable(route.screenImport)}
          />
        ))}

        <Route path="/app/job-board/bid-submit/:jobId" exact component={CreatorBidSubmitScreen} />
        <Route path="/app/repost-a-job/:jobId" exact component={RePostAJobScreen} />

        <Route path="/app/settings/location" exact component={ClientSettingsLocationScreen} />
        <Route path="/app/settings/financial" exact component={ClientSettingsFinancialScreen} />
        <Route path="/app/settings/password" exact component={ClientSettingsPasswordScreen} />
        <Route component={ErrorScreen} />
      </Switch>
    </>
  );
};

export default withTheme(AppRoutes);
