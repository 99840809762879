import React from 'react';
import Svg from '../Svg';
export const SvgOverview = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 12 12" fill="none" {...props}>
    <path d="M1 8.833V11h10V1H1v5.167" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 4h4M4 6h4M4 8h4" stroke="currentColor" strokeLinecap="round" />
  </Svg>
);
SvgOverview.isIcon = true;
SvgOverview.defaultProps = {
  size: 24,
};
export default SvgOverview;
