import React from 'react';
import Svg from '../Svg';
export const SvgPlusCircleLarge = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 48 48" fill="none" {...props}>
    <circle cx={24} cy={24} r={23.5} stroke="currentColor" />
    <path d="M24 10.667v26.666M10.666 24h26.667" stroke="currentColor" />
  </Svg>
);
SvgPlusCircleLarge.isIcon = true;
SvgPlusCircleLarge.defaultProps = {
  size: 24,
};
export default SvgPlusCircleLarge;
