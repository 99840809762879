import gql from 'graphql-tag';

export default gql`
  query Profiles($profileIds: [ID!]!) {
    profiles(profileIds: $profileIds) {
      ... on ProtectedCreator {
        id
        rating {
          overall
          internal
          tier
        }
        location {
          locality
          region
          country
        }
        skill {
          name
        }
        slug
        name
        avatar {
          publicId
        }
      }
      ... on ProtectedClient {
        id
        slug
        name
        avatar {
          publicId
        }
      }
    }
  }
`;
