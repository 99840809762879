import gql from 'graphql-tag';
import { JobPostProposalFragment } from './JobPostProposalFragment';

export default gql`
  ${JobPostProposalFragment}
  fragment JobPostProposalsFragment on JobPostProposals {
    all(limit: 100) {
      items {
        ...JobPostProposalFragment
      }
      nextToken
    }
  }
`;
