import React from 'react';
import Svg from '../Svg';
export const SvgPlusCircle = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 36 36" fill="none" {...props}>
    <circle cx={18} cy={18} r={17.5} stroke="currentColor" />
    <path d="M18 8v20M8 18h20" stroke="currentColor" />
  </Svg>
);
SvgPlusCircle.isIcon = true;
SvgPlusCircle.defaultProps = {
  size: 24,
};
export default SvgPlusCircle;
