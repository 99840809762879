import {Form, InputNumber, Radio, Space} from 'antd';
import React, {useContext, useState} from 'react';
import Box from '../../../../components-core/box';
import Divider from '../../../../components-core/divider';
import Flex from '../../../../components-core/flex';
import Text from '../../../../components-core/text';
import {UserContext} from '../../../../components/Authenticator';
import {SubLabel} from './styles';
import Link from '../../../../components-core/link';

const formatter = value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const BudgetStep = ({
                      getFieldDecorator,
                      form,
                      currentStep,
                      payload,
                      jobStrength,
                      handleNext,
                      toggleFooter,
                      setPayload,
                      className
                    }: any) => {
  const user = useContext(UserContext);
  // yes this is shitty. too bad we couldn't get self.adminLevel working for clients.
  const isShannon = user?.user?.email === 'shannon@thehhub.com';

  // const DEFAULT_MIN = 250;
  // const DEFAULT_MAX = 10000;
  // const [touched, setTouched] = useState(false);
  // const [range, setRange] = useState<any>(null);

  const values = {
    0: {
      min: 600,
      max: 1500
    },
    1: {
      min: 1250,
      max: 2500
    },
    2: {
      min: 2250,
      max: 6000
    }
  };

  const range = payload?.details.budget?.range;
  const rangeKey = range
    ? Object.keys(values).findIndex(key => values[key].min === range.min && values[key].max === range.max)
    : null;

  const isPartOfWizard = typeof currentStep !== 'undefined';
  const hasPreselectedRange = rangeKey !== -1;
  const [min, setMin] = useState<any>(hasPreselectedRange ? range?.min : null);
  const [max, setMax] = useState<any>(hasPreselectedRange ? range?.max : null);
  const [rangeTouched, setRangeTouched] = useState(min !== null || max !== null);

  // const subscriptionQuery = useQuery(SubscriptionQuery);
  // const subscriptionStatus = subscriptionQuery?.data?.subscription?.status;

  const setRange = ({min, max}: any) => {
    setPayload(prev => ({
      ...prev,
      details: {
        ...(prev.details || {}),
        budget: {
          ...(prev.details?.budget ?? {}),
          range: {
            ...(prev.details?.budget?.range ?? {}),
            ...(min ? {min} : {}),
            ...(max ? {max} : {})
          }
        }
      }
    }));
  };

  return (
    <Box px={[3, 3, 3, 4]} textAlign='center' className={className}>
      <Text>
        <Text fontSize='18px' bold mb={3}>
          What’s your job budget?
        </Text>
        {/*
        <Text color="#6F6F6F" mb={4}>

          <Link href="https://thehhub.com/case-studies/unit-economic-breakdown/" target="_blank">
            HERE
          </Link>{' '}
          are examples of recent shoots and how much they cost our clients.
          <br />

          The average shoot on our platform is $1,938.{' '}

          <BlackTooltip>
            While some clients have lower budgets and post jobs for less than $1,938, less experienced photographers and
            videographers tend to bid on lower paying jobs. To find the best talent we recommend posting jobs in the
            $1,500-$2,500 range.
          </BlackTooltip>

        </Text>
        */}
      </Text>
      <Box>
        <Form.Item>
          {form.getFieldDecorator('rangeHolder', {
            initialValue: rangeKey,
            rules: [
              {
                required: isPartOfWizard && !min && !max,
                message: 'Select a range'
              }
            ]
          })(
            <Radio.Group
              // disabled={rangeTouched}
              // value={rangeKey}
              onChange={e => {
                setMin(null);
                setMax(null);
                setRangeTouched(true);
                setRange(values[e.target.value]);

                if (values[e.target.value]) {
                  form.setFields({
                    'details.budget.range.min': {
                      value: values[e.target.value].min,
                      errors: null
                    },
                    'details.budget.range.max': {
                      value: values[e.target.value].max,
                      errors: null
                    }
                  });
                }
              }}
              name='radiogroup'
              style={{width: '100%'}}
              size='large'
            >
              <Space style={{padding: '4px'}}>
                <Radio.Button value={0} style={{height: 'auto', width: '186px'}}>
                  <div className='text-left leading-normal py-3'>
                    <div className='font-bold text-smaller uppercase'>Beginner</div>
                    <div className='font-normal'>$600 - $1,500</div>
                    <div className='text-xs'>Lower 1/3 of Rated Creators</div>
                  </div>
                </Radio.Button>
              </Space>
              <Space style={{padding: '4px'}}>
                <Radio.Button value={1} style={{height: 'auto', width: '186px'}}>
                  <div className='text-left leading-normal py-3'>
                    <div className='font-bold text-smaller uppercase'>Intermediate</div>
                    <div className='font-normal'>$1,250 - $2,500</div>
                    <div className='text-xs'>Middle 1/3 of Rated Creators</div>
                  </div>
                </Radio.Button>
              </Space>
              <Space style={{padding: '4px'}}>
                <Radio.Button value={2} style={{height: 'auto', width: '186px'}}>
                  <div className='text-left leading-normal py-3'>
                    <div className='font-bold text-smaller uppercase'>Professional</div>
                    <div className='font-normal'>$2,250 - $6,000</div>
                    <div className='text-xs'>Upper 1/3 of Rated Creators</div>
                  </div>
                </Radio.Button>
              </Space>
            </Radio.Group>
          )}
        </Form.Item>


        <Text color='#6F6F6F'>
          Want help with budget ranges?{' '}
          <Link href='https://thehhub.com/case-studies/unit-economic-breakdown/' target='_blank'>
            HERE
          </Link>{' '}
          are some examples of shoots and their cost.
        </Text>
      </Box>

      <Divider mt={3} mb={3} />
      <Text bold mb={2}>
        OR
      </Text>
      <SubLabel>Select a custom budget</SubLabel>
      <Flex justifyContent={isPartOfWizard ? 'center' : 'flex-start'}>
        <Form.Item style={{margin: 0}}>
          {form.getFieldDecorator('details.budget.range.min', {
            initialValue: range?.min,
            onChange: min => {
              setMin(min);
              setRange({min});
              form.setFields({
                rangeHolder: {value: null, errors: null}
              });
            },
            rules: [
              {
                required: (!hasPreselectedRange && !rangeTouched) || (!min && max),
                message: 'Enter a minimum'
              }
            ]
          })(
            <InputNumber
              size='large'
              min={isShannon ? 1 : 400}
              max={100000}
              style={{maxWidth: '108px'}}
              placeholder='$600'
              formatter={formatter}
              // value={min}
              // onChange={val => {
              //   setMin(val);
              // }}
            />
          )}
        </Form.Item>
        <Box as='span' px={4}>
          &mdash;
        </Box>
        <Form.Item style={{margin: 0}}>
          {form.getFieldDecorator('details.budget.range.max', {
            initialValue: range?.max,
            onChange: max => {
              setMax(max);
              if (!min) {
                setRange({max, min: 600});
              } else {
                setRange({max});
              }
              form.setFields({
                rangeHolder: {value: null, errors: null}
              });
            },
            rules: [
              {
                required: !hasPreselectedRange && !rangeTouched && !max,
                message: 'Enter a maximum'
              }
            ]
          })(
            <InputNumber
              size='large'
              min={min ? min + 1 : isShannon ? 1 : 600}
              width={108}
              placeholder='$10,000'
              style={{maxWidth: '108px'}}
              max={100000}
              formatter={formatter}
              // value={max}
              // onChange={}
            />
          )}
        </Form.Item>
      </Flex>
      {/*
      {subscriptionStatus === 'NOT_REQUIRED' &&
        <Text color='#6F6F6F' fontSize='10px' mt={2}>
          An 18% platform fee will be added when you hire your creator.
        </Text>}
        */}
    </Box>
  );
};

export default BudgetStep;
