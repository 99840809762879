import React from 'react';
import Text from '../../components-core/text';
import SimpleTooltip from './SimpleTooltip';

const BlackToolTip = ({ children }) => (
  <SimpleTooltip containerProps={{ bg: 'black' }}>
    <Text fontSize="13px" px="18px" py="16px" color="white" maxWidth={391}>
      {children}
    </Text>
  </SimpleTooltip>
);

export default BlackToolTip;
