import gql from 'graphql-tag';
import JobPostFragment from '../fragments/JobPostFragment';

export default gql`
  ${JobPostFragment}
  mutation JobPostCreateMutation($input: JobPostInput!) {
    jobPostCreate(input: $input) {
      ...JobPostFragment
    }
  }
`;
