import {useMutation, useQuery} from '@apollo/react-hooks';
import {Avatar, Modal, notification} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components/macro';
import Box from '../../components-core/box';
import Button from '../../components-core/button';
import Text from '../../components-core/text';
import Form from '../../components-core/form';
import JOB_POST_CREATE_MUTATION from '../../graphql/mutations/JobPostCreateMutation';
import ClientJobsQuery from '../../graphql/queries/ClientJobsQuery';
import SelfQuery from '../../graphql/queries/SelfQuery';
import {jobStyleTranslator} from '../../utils/jobUtils';
import {removeTypename} from '../../utils/objectUtils';
import buildJobSubmitVariables from './buildJobSubmitVariables';
import BudgetStep from './components/PostJobFormWizard/BudgetStep';
import DosAndDontsStep from './components/PostJobFormWizard/DosAndDontsStep';
import JobDetailsStep from './components/PostJobFormWizard/JobDetailsStep';
import LocationStep from './components/PostJobFormWizard/LocationStep';
import MoodBoardStep from './components/PostJobFormWizard/MoodboardStep';
import SelectStyleStep from './components/PostJobFormWizard/SelectStyleStep';
import UsageRightsStep from './components/PostJobFormWizard/UsageRightsStep';
import SelectSkillStep from './components/PostJobFormWizard/SelectSkillStep';
import {VisibilityStep} from './components/PostJobFormWizard/VisibilityStep';
import PROFILES_QUERY from '../../graphql/queries/ProfilesQuery';
import {UserContext} from '../../components/Authenticator';
import {buildCloudinaryImageUrl} from '../../utils/cloudinaryUtils';
import OutlineButton from '../../components-core/outline-button';
import SubscriptionQuery from '../../graphql/queries/SubscriptionQuery';
import CLIENT_RESUBSCRIBE_MUTATION from '../../graphql/mutations/ClientResubscribeMutation';
import moment from 'moment';
import Container from '../../components-core/container';
import Flex from '../../components-core/flex';
import Icon from '../../components-core/icon';

const StyleStep = styled(SelectStyleStep)`
  text-align: left;
  margin: 0 auto;
  width: 100%;
  max-width: 540px;
`;
const StyledBudgetStep = styled(BudgetStep)`
  text-align: left;
  margin: 0 auto;
  width: 100%;
  max-width: 540px;
  padding: 0;
`;
const StyledDosAndDontsStep = styled(DosAndDontsStep)`
  margin: 0 auto;
  width: 100%;
  max-width: 540px;
  padding: 0;
  > div {
    text-align: left;
  }
`;

const StyledVisibilityStep = styled(VisibilityStep)`
  margin: 0 auto;
  width: 100%;
  max-width: 540px;
  padding: 0;
  > div {
    text-align: left;
  }
`;

const StyledMoodBoardStep = styled(MoodBoardStep)`
  margin: 0 auto;
  width: 100%;
  max-width: 540px;
  padding: 0;
  > div {
    text-align: left;
  }
`;
const StyledUsageRightsStep = styled(UsageRightsStep)`
  margin: 0 auto;
  width: 100%;
  max-width: 540px;
  padding: 0;
  div {
    text-align: left;
  }
`;

export const RePostForm = Form.create<any>()(({form, jobPost, onSuccess, buttonText}: any) => {
  const history = useHistory();
  const user = useContext(UserContext);

  const [hideVisibilityStep, setHideVisibilityStep] = useState(true);

  const [submissionError, setSubmissionError] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [jobPostCreate, jobPostCreateState] = useMutation(JOB_POST_CREATE_MUTATION);
  const [namedCreatorResults, setNamedCreatorResults] = useState<any>([]);

  const [payload, setPayload] = useState(jobPost ? removeTypename(jobPost) : null);

  const profilesQuery = useQuery(PROFILES_QUERY, {
    variables: {profileIds: payload?.visibility?.creatorFilters?.creatorIds},
    skip: !(payload?.visibility?.creatorFilters?.creatorIds?.length > 0)
  });

  const [clientResubscribe] = useMutation(CLIENT_RESUBSCRIBE_MUTATION);
  const subscriptionQuery = useQuery(SubscriptionQuery);
  const subscriptionNeeded = subscriptionQuery?.data?.subscription?.status === 'UPDATE_REQUIRED';
  const subscriptionAmount = subscriptionQuery?.data?.subscription?.amount;
  const subscriptionInterval = subscriptionQuery?.data?.subscription?.interval;
  const subscriptionExpiredAt = subscriptionQuery?.data?.subscription?.expiredAt;
  const subscriptionExpiredAtFormatted = moment(new Date(parseInt(subscriptionExpiredAt, 10))).format('MMMM Do, YYYY');

  const resubscribe = async () => {
    try {
      setSubmitting(true);
      console.log('resubscribing client');

      const {data} = await clientResubscribe({
        refetchQueries: [
          {query: SubscriptionQuery}
        ],
        awaitRefetchQueries: true
      });

      console.log(data);
    } catch (error) {
      console.error(error);
      setSubmissionError('Could not resubscribe your account. Please contact customer support for assistance.');
    } finally{
      setSubmitting(false)
    }
  }

  const subscriptionModalCanceled = () => {
    window.location.href = '/app/dashboard'
  }

  const avatar = creator => (
    // @ts-ignore
    <Avatar
      // @ts-ignore
      creator={creator}
      size="small"
      src={buildCloudinaryImageUrl(creator.avatar.publicId, 'w_120,h_120,c_fill')}
    />
  );

  useEffect(() => {
    if (payload === null && jobPost) {
      setPayload(jobPost);
    }

    // add premium features if the client has access to them
    if (user?.premiumFeatures?.includes('job-post-creator-filters')) {
      setHideVisibilityStep(false);
    }

    // populate the named creators results if creator ids are specified
    if (namedCreatorResults.length === 0 && profilesQuery?.data?.profiles?.length > 0)
      setNamedCreatorResults(
        profilesQuery.data.profiles.map(a => ({
          key: a.id,
          value: a.id,
          label: [avatar(a), ' ', a.name]
        }))
      );
  }, [jobPost, namedCreatorResults, payload, profilesQuery, user]);

  if (!payload) {
    return null;
  }

  const [styleGroup] = jobStyleTranslator(jobPost?.details?.styles, false);

  if (submitting) {
    return (
      <Container maxWidth={300}>
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Box
            mt="30vh"
            // px={4}
            pl={3}
            pr={3}
            py={3}
            bg="whiteApple"
            borderRadius={2}
            boxShadow="0px 10px 26px rgba(0, 0, 0, 0.1);"
          >
            <Flex alignItems="center">
              <Icon name="Spinner" color="purple" size={42} />
            </Flex>
          </Box>
        </Flex>
      </Container>
    );
  }

  return (
    <Form
      id="job-repost-form"
      onSubmit={e => {
        e.preventDefault();
        form.validateFieldsAndScroll(async (err, values) => {
          if (err) {
            return;
          }
          console.log(values);

          const variables = buildJobSubmitVariables({
            ...values,
            visibility: removeTypename(payload.visibility),
            details: {
              ...(values.details || {}),
              // styles: payload.details?.styles ?? [],
              guidelines: removeTypename(payload.details?.guidelines),
              budget: removeTypename(values.details?.budget),
              files: removeTypename(payload.details?.files).map(({key, name, mimeType, size}) => ({
                key,
                name,
                mimeType,
                size
              })),
              moodBoard: (payload.details?.moodBoard ?? [])
                .filter(m => m !== null)
                .map(item =>
                  typeof item === 'string' ? {publicId: item, type: 'IMAGE'} : {publicId: item.publicId, type: 'IMAGE'}
                )
            }
          });

          if (typeof onSuccess === 'function') {
            return onSuccess(variables);
          }

          try {
            const {data} = await jobPostCreate({
              variables: {
                input: variables
              },
              refetchQueries: [
                {query: SelfQuery},
                {
                  query: ClientJobsQuery,
                  variables: {
                    limit: 100
                  }
                }
              ],
              awaitRefetchQueries: true
            });

            const newJobSlug = data?.jobPostCreate?.slug;
            history.push(`/app/my-jobs/manage/invite/${newJobSlug}`);

            notification.success({message: 'Job Posted!'});

            // showSuccessModal(newJobSlug);
          } catch (error) {
            console.error(error);
            // setSubmitting(false);
            setSubmissionError(error.message);
            window.scrollTo(0, 0);
          }

          // console.log(payload);
        });
      }}
    >
      {submissionError && (
        <Box py={3}>
          <Text color="red" bold>
            Error saving information
          </Text>
          <Text color="red">{submissionError}</Text>
        </Box>
      )}

      <Box bg={'#fafafa'} p={4} borderTop="1px solid #EBEBEB" borderBottom="1px solid #EBEBEB">
        <JobDetailsStep
          currentStep={1}
          setPayload={setPayload}
          payload={payload}
          form={form}
          // @ts-ignore
          getFieldDecorator={form.getFieldDecorator}
        />
      </Box>


      <Box bg={'#fafafa'} py={4} borderTop="1px solid #EBEBEB" borderBottom="1px solid #EBEBEB">
        <StyledMoodBoardStep
          setPayload={setPayload}
          payload={payload}
          form={form}
          // @ts-ignore
          getFieldDecorator={form.getFieldDecorator}
        />
      </Box>

      <Box bg={'#fefefe'} p={4} pb={3}>
        <StyleStep
          setPayload={setPayload}
          payload={{
            details: {
              styles: JSON.stringify(styleGroup?.value ?? [])
            }
          }}
          // payload={{
          //   ...jobPost,
          //   details: {
          //     ...jobPost?.details ?? {},
          //     styles: JSON.stringify(jobPost?.details?.styles ?? []),
          //   }
          // }}
          // @ts-ignore
          getFieldDecorator={form.getFieldDecorator}
        />
      </Box>


      <Box
        bg={'#fafafa'}
        p={4}
        borderTop="1px solid #EBEBEB"
        borderBottom="1px solid #EBEBEB"
        borderRadius="5px 5px 0 0"
      >
        <SelectSkillStep setPayload={setPayload} payload={payload} form={form} isEdit />
      </Box>



      <Box bg={'#fefefe'} py={4}>
        <StyledBudgetStep
          setPayload={setPayload}
          form={form}
          payload={{
            details: {
              budget: {
                range: {
                  min: (payload.details?.budget?.range?.min ?? 0) / 100,
                  max: (payload.details?.budget?.range?.max ?? 0) / 100
                }
              }
            }
          }}
          // @ts-ignore
          getFieldDecorator={form.getFieldDecorator}
        />
      </Box>

      <Box bg={'#fafafa'} py={4} borderTop="1px solid #EBEBEB" borderBottom="1px solid #EBEBEB">
        <LocationStep
          setPayload={setPayload}
          payload={{
            details: {
              location: payload.details?.location,
              schedule: {
                dueDate: parseInt(payload.details?.schedule?.dueDate)
              }
            }
          }}
          form={form}
          // @ts-ignore
          getFieldDecorator={form.getFieldDecorator}
        />
      </Box>

      <Box bg={'#fefefe'} py={4} style={hideVisibilityStep ? {display: 'none'} : {}}>
        <StyledVisibilityStep
          setPayload={setPayload}
          namedCreatorResults={namedCreatorResults}
          stashNamedCreatorResults={updatedNamedCreatorResults => {
            if (updatedNamedCreatorResults) {
              setNamedCreatorResults(updatedNamedCreatorResults);
            }
          }}
          form={form}
          payload={{
            visibility: payload.visibility
          }}
          // @ts-ignore
          getFieldDecorator={form.getFieldDecorator}
        />
      </Box>


      <Box bg={'#fefefe'} p={4}>
        <StyledDosAndDontsStep
          setPayload={setPayload}
          form={form}
          payload={{
            details: {
              guidelines: {
                dos: payload?.details?.guidelines.dos ?? null,
                donts: payload?.details?.guidelines.donts ?? null
              }
            }
          }}
          // @ts-ignore
          getFieldDecorator={form.getFieldDecorator}
        />
      </Box>
      <Box bg={'#fafafa'} p={4} borderTop="1px solid #EBEBEB" borderBottom="1px solid #EBEBEB">
        <StyledUsageRightsStep
          payload={payload}
          setPayload={setPayload}
          form={form}
          // @ts-ignore
          getFieldDecorator={form.getFieldDecorator}
        />
      </Box>
      <Box bg={'#fefefe'} py={4} px={5}>
        <Button type="submit" disabled={jobPostCreateState.loading} loading={jobPostCreateState.loading}>
          {buttonText}
        </Button>
      </Box>
      <Modal
        visible={subscriptionNeeded}
        title="Active Subscription Required"
        centered
        onOk={resubscribe}
        onCancel={subscriptionModalCanceled}
        footer={[
          <OutlineButton key="back" onClick={subscriptionModalCanceled} mr={2}>
            Cancel
          </OutlineButton>,
          <Button onClick={resubscribe}>
            Resubscribe
          </Button>
        ]}
      >
        <p>An active subscription is required to post a new job.
          Your subscription of ${subscriptionAmount/100}{subscriptionInterval ? `/${subscriptionInterval}` : ''} expired on {subscriptionExpiredAtFormatted}.
          Please resubscribe to continue.</p>
      </Modal>
    </Form>

  );
});

RePostForm.defaultProps = {
  buttonText: 'Re-Post Job'
};

export default RePostForm;
