import React from 'react';
import Svg from '../Svg';
export const SvgSkillsetVideographer = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M19.5 10c0 5.246-4.253 9.5-9.5 9.5S.5 15.246.5 10 4.753.5 10 .5s9.5 4.254 9.5 9.5z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M6.942 14.726H4.727c-.206 0-.373-.151-.373-.34v-4.71c0-.188.167-.34.373-.34h8.34c.206 0 .373.152.373.34v.833h1.245a.387.387 0 00.138-.026l.971-.383a.186.186 0 01.255.173V14.1c0 .14-.149.23-.273.165l-1.074-.566a.385.385 0 00-.175-.043H13.44v.731c0 .188-.167.34-.372.34H6.942z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M8.67 6.976a2.36 2.36 0 11-4.72 0 2.36 2.36 0 014.72 0z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M14.05 6.646a2.69 2.69 0 11-5.381 0 2.69 2.69 0 015.38 0z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.5 12H12" stroke="currentColor" strokeLinecap="round" />
  </Svg>
);
SvgSkillsetVideographer.isIcon = true;
SvgSkillsetVideographer.defaultProps = {
  size: 24,
};
export default SvgSkillsetVideographer;
