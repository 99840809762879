import React from 'react';
import Svg from '../Svg';
export const SvgGender = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <g clipPath="url(#gender_svg__clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.4 10a.5.5 0 00-.5.5v1.25h-1a.5.5 0 000 1h1v.75a.5.5 0 001 0v-.75h1a.498.498 0 00.5-.5.5.5 0 00-.5-.5h-1V10.5a.5.5 0 00-.5-.5z"
        fill="currentColor"
      />
      <circle cx={4.506} cy={6.5} r={4} stroke="currentColor" />
      <circle cx={9.5} cy={7.5} r={4} stroke="currentColor" />
      <path
        d="M11.508 1.154l-.87 2.393M13.046 2.778L11.679.684l-2.392.726"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="gender_svg__clip0">
        <rect width="1em" height="1em" fill="#fff" />
      </clipPath>
    </defs>
  </Svg>
);
SvgGender.isIcon = true;
SvgGender.defaultProps = {
  size: 24,
};
export default SvgGender;
