import React from 'react';
import Svg from '../Svg';
export const SvgChatBubble = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}>
    <path d="M4.883 13.592A6.36 6.36 0 008 14.4a6.4 6.4 0 10-5.6-3.3" stroke="currentColor" strokeLinecap="round" />
    <path
      d="M4.958 14.074a.5.5 0 10-.317-.948l.317.948zm-2.084-2.716a.5.5 0 10-.949-.316l.949.316zM1.199 14.8l-.474-.158a.5.5 0 00.633.632l-.159-.474zm.726-3.758l-1.2 3.6.949.316 1.2-3.6-.949-.316zm-.567 4.232l3.6-1.2-.317-.948-3.6 1.2.317.948z"
      fill="currentColor"
    />
    <path
      d="M9.75 8a.25.25 0 11.5 0 .25.25 0 01-.5 0zM7.5 8A.25.25 0 118 8a.25.25 0 01-.5 0zM5.25 8a.25.25 0 11.5 0 .25.25 0 01-.5 0z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </Svg>
);
SvgChatBubble.isIcon = true;
SvgChatBubble.defaultProps = {
  size: 24,
};
export default SvgChatBubble;
