import { Button, message } from 'antd';
import React from 'react';
import { register } from './serviceWorker';

export default function registerServiceWorker() {
  if (!('serviceWorker' in navigator)) {
    return;
  }

  register({
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.onstatechange = event => {
          if (event.target?.state === 'activated') {
            window.location.reload();
            return;
          }
        };
        message.config({
          maxCount: 1,
        });
        message.info({
          content: (
            <>
              New version detected.{' '}
              <Button
                style={{ marginLeft: 10 }}
                size="small"
                onClick={() => {
                  waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                }}
              >
                Update now
              </Button>
            </>
          ),
          duration: 0,
        });
      }
    },
  });
  const ONE_MINUTE_IN_MS = 60000;
  setInterval(() => {
    console.debug('Checking for sw updates');
    navigator.serviceWorker.getRegistration().then(registration => {
      if (registration) {
        registration.update();
      }
    });
  }, ONE_MINUTE_IN_MS);
}
