import gql from 'graphql-tag';

// export interface JobPostLocation {
//   formatted?: string;
//   streetAddress?: string;
//   locality: string;
//   region: string;
//   country: string;
//   coords: GeoLocation;
// }

export const JobPostLocationFragment = gql`
  fragment JobPostLocationFragment on JobPostLocation {
    coords {
      lat
      lon
    }
    locality
    region
    country
  }
`;

export default JobPostLocationFragment;
