import React from 'react';
import Svg from '../Svg';
export const SvgLogoTwitter = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M6.192 18c7.431 0 11.495-6.156 11.495-11.495 0-.175 0-.349-.012-.522a8.22 8.22 0 002.016-2.091 8.063 8.063 0 01-2.32.635 4.054 4.054 0 001.776-2.234c-.793.47-1.66.802-2.566.98a4.044 4.044 0 00-6.884 3.685 11.47 11.47 0 01-8.326-4.22A4.043 4.043 0 002.62 8.13a4.01 4.01 0 01-1.833-.506v.052a4.041 4.041 0 003.24 3.96 4.034 4.034 0 01-1.823.07 4.044 4.044 0 003.774 2.805A8.106 8.106 0 010 16.185a11.437 11.437 0 006.192 1.812"
      fill="currentColor"
    />
  </Svg>
);
SvgLogoTwitter.isIcon = true;
SvgLogoTwitter.defaultProps = {
  size: 24,
};
export default SvgLogoTwitter;
