import gql from 'graphql-tag';
import JobPostFragment from './JobPostFragment';
import { JobPostProposalProtectedCreatorFragment } from './JobProposalProtectedCreatorFragment';

export const JobPostProposalFragment = gql`
  ${JobPostProposalProtectedCreatorFragment}
  ${JobPostFragment}
  fragment JobPostProposalFragment on JobPostProposal {
    id
    jobId
    conversationId
    creatorId
    creator {
      ...JobPostProposalProtectedCreatorFragment
    }
    job {
      ...JobPostFragment
    }
    jobPostProposalId
    amount
    createdAt
    introduction
    vision
    headline
    media {
      type
      publicId
    }
    reaction
    deliverables {
      quantity
      type
    }
  }
`;

export default JobPostProposalFragment;
