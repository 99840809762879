import React from 'react';
import Svg from '../Svg';
export const SvgErrorX = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.906 3.316a.745.745 0 00-.436.214L7 6 4.53 3.53a.75.75 0 10-1.06 1.06l2.47 2.47-2.48 2.48a.75.75 0 001.06 1.062L7 8.122l2.48 2.48a.75.75 0 101.06-1.061L8.06 7.06l2.47-2.47a.75.75 0 00-.624-1.275z"
      fill="currentColor"
    />
  </Svg>
);
SvgErrorX.isIcon = true;
SvgErrorX.defaultProps = {
  size: 24,
};
export default SvgErrorX;
