import React from 'react';
import styled from 'styled-components/macro';
import { background, color } from 'styled-system';
import Absolute from '../../components-core/absolute';
import Relative from '../../components-core/relative';
import TransparentButton from '../../components-core/transparent-button';
import HelpIcon from '../HelpIcon/HelpIcon';

const Caret = styled.div`
  background: #6ba8bf;
  transform: matrix(0.71, 0.79, -0.64, 0.71, 0, 0);
  width: 13.32px;
  height: 13.32px;
  ${background}
  ${color}
`;

const Trigger = styled(TransparentButton)`
  height: auto !important;

  & + .tooltip-content {
    display: none;
  }

  &:hover {
    & + .tooltip-content {
      display: block;
    }
  }
`;

const SimpleTooltip = ({ children, trigger, containerProps }: any) => (
  <Relative as="span">
    <Trigger type="button">{trigger || <HelpIcon />}</Trigger>
    <Absolute
      className="tooltip-content"
      bottom="23px"
      css={{ transform: 'translateX(-50%)' }}
      left="7px"
      minWidth="256px"
      bg="blue"
      borderRadius={2}
      boxShadow="0px 2px 14px rgba(63, 63, 63, 0.15)"
      zIndex={3}
      {...containerProps}
    >
      <Relative zIndex={1}>{children}</Relative>
      <Absolute bottom="-5px" left="50%" ml="-6px">
        <Caret {...containerProps} />
      </Absolute>
    </Absolute>
  </Relative>
);

export default SimpleTooltip;
