import React from 'react';
import Svg from '../Svg';
export const SvgLogoYoutube = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zM3 8a3 3 0 013-3h8a3 3 0 013 3v3.8a3 3 0 01-3 3H6a3 3 0 01-3-3V8zm5.1-.5l4.167 2.5L8.1 12.5v-5z"
      fill="currentColor"
    />
  </Svg>
);
SvgLogoYoutube.isIcon = true;
SvgLogoYoutube.defaultProps = {
  size: 24,
};
export default SvgLogoYoutube;
