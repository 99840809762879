import gql from 'graphql-tag';
import FileFragment from './FileFragment';
import { JobPostBudgetFragment } from './JobPostBudgetFragment';
import { JobPostGuidelinesFragment } from './JobPostGuidelinesFragment';
import { JobPostLocationFragment } from './JobPostLocationFragment';
import { JobPostScheduleFragment } from './JobPostScheduleFragment';
import MediaItemFragment from './MediaItemFragment';

export default gql`
  ${JobPostBudgetFragment}
  ${JobPostGuidelinesFragment}
  ${JobPostLocationFragment}
  ${JobPostScheduleFragment}
  ${MediaItemFragment}
  ${FileFragment}

  fragment JobPostDetailsFragment on JobPostDetails {
    budget {
      ...JobPostBudgetFragment
    }
    guidelines {
      ...JobPostGuidelinesFragment
    }
    location {
      ...JobPostLocationFragment
    }
    moodBoard {
      ...MediaItemFragment
    }
    schedule {
      ...JobPostScheduleFragment
    }
    files {
      ...FileFragment
    }
    styles
    usage {
      type
      description
    }
  }
`;
