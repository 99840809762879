import {Checkbox, Col, Form, Row, Slider} from 'antd';
import React, {useEffect, useState} from 'react';
import Box from '../../../../components-core/box';
import Divider from '../../../../components-core/divider';
import Text from '../../../../components-core/text';
import Container from '../../../../components-core/container';
import CreatorSearchTypeahead from '../../../../components/CreatorSearchTypeahead/CreatorSearchTypeahead';
import TagTypeahead from '../../../../components/TagTypeahead';
import styles from '../../../../data/styles.json';

export const VisibilityStep = ({
  payload,
  namedCreatorResults,
  stashNamedCreatorResults,
  setPayload,
  className
}: any) => {
  const [namedCreatorIds, setNamedCreatorIds] = useState<string[]>([]);
  const [minTier, setMinTier] = useState<number>(4);
  const [minRating, setMinRating] = useState<number>(3);
  const [creatorTags, setCreatorTags] = useState<string[]>([]);
  const [creatorStyles, setCreatorStyles] = useState<string[]>([]);

  const creatorTiersInOrder = [
    {
      name: 'VERIFIED',
      rank: 0
    },
    {
      name: 'RISING',
      rank: 1
    },
    {
      name: 'COMMUNITY',
      rank: 2
    },
    {
      name: 'STANDARD',
      rank: 3
    }
  ];

  // setup existing values based on existing payload
  useEffect(() => {
    if (minTier === 4 && payload.visibility?.creatorFilters?.tiers?.length > 0) {
      const matchedTier = creatorTiersInOrder
        .sort((a, b) => b.rank - a.rank)
        .find(a => payload.visibility.creatorFilters.tiers.includes(a.name));
      setMinTier(matchedTier?.rank ?? 4);
    }
    if (minRating === 3 && payload.visibility?.creatorFilters?.internalRating?.min > 0) {
      setMinRating(parseFloat((3 - payload.visibility.creatorFilters.internalRating.min).toFixed(1)));
    }
    if (creatorTags?.length === 0 && payload.visibility?.creatorFilters?.tags?.length > 0) {
      setCreatorTags(payload.visibility?.creatorFilters?.tags);
    }
    if (creatorStyles?.length === 0 && payload.visibility?.creatorFilters?.styles?.length > 0) {
      setCreatorStyles(payload.visibility?.creatorFilters?.styles);
    }
    if (namedCreatorIds?.length === 0 && payload.visibility?.creatorFilters?.creatorIds?.length > 0) {
      setNamedCreatorIds(payload.visibility?.creatorFilters?.creatorIds.map(a => a.key));
    }
  }, [creatorTiersInOrder, minTier, minRating, creatorTags, creatorStyles, namedCreatorIds, payload]);

  return (
    <Box px={[3, 3, 3, 4]}>
      <Container maxWidth={540} pb={3} className={className}>
        <Text textAlign="center" mb={4}>
          <Text fontSize="18px" bold>
            Do you want to limit this job to specific creators?
          </Text>
        </Text>

        <Text textAlign="left" mb={1}>
          Creators by Name
        </Text>
        <Form.Item>
          <Box mb={4}>
            <CreatorSearchTypeahead
              disabled={creatorTags.length > 0 || minTier !== 4 || minRating !== 3 || creatorStyles.length > 0}
              showIcon
              multiple={true}
              placeholder="Search by name"
              style={{width: '100%', height: '100%', textAlign: 'left'}}
              value={namedCreatorResults}
              onChange={result => {
                if (result && result.length > 0) {
                  const creatorIds = result.map(a => a.key);
                  setNamedCreatorIds(creatorIds);
                  stashNamedCreatorResults(result);
                  setPayload(prev => ({
                    ...prev,
                    visibility: {
                      ...prev.visibility,
                      creatorFilters: {
                        ...prev.visibility?.creatorFilters,
                        creatorIds
                      }
                    }
                  }));
                } else {
                  setNamedCreatorIds([]);
                  stashNamedCreatorResults([]);
                  setPayload(prev => ({
                    ...prev,
                    visibility: {
                      ...prev.visibility,
                      creatorFilters: {
                        ...prev.visibility?.creatorFilters,
                        creatorIds: []
                      }
                    }
                  }));
                }
              }}
            />
          </Box>
        </Form.Item>

        <Divider mt={4} mb={3} />
        <Text bold mb={3}>
          OR
        </Text>

        <Text textAlign="left" mb={1}>
          Creators by Tag
        </Text>
        <Form.Item>
          <Box mb={4}>
            <TagTypeahead
              disabled={namedCreatorIds.length > 0}
              showIcon
              placeholder="Search by tag"
              style={{width: '100%', height: '100%', textAlign: 'left'}}
              value={creatorTags}
              onChange={result => {
                setCreatorTags(result);
                setPayload(prev => ({
                  ...prev,
                  visibility: {
                    ...prev.visibility,
                    creatorFilters: {
                      ...prev.visibility?.creatorFilters,
                      tags: result
                    }
                  }
                }));
              }}
            />
          </Box>
        </Form.Item>

        <Text textAlign="left" mb={1}>
          Minimum Rating
        </Text>
        <Form.Item>
          <Box mb={5}>
            <Slider
              disabled={namedCreatorIds.length > 0}
              marks={{
                0: '3.0',
                1: '2.0',
                2: '1.0',
                3: 'N/A'
              }}
              tipFormatter={value => {
                return (value && (3 - value)?.toFixed(1)) || undefined;
              }}
              reverse
              min={0}
              max={3}
              step={0.1}
              value={minRating}
              onChange={value => {
                setMinRating(value);
                setPayload(prev => ({
                  ...prev,
                  visibility: {
                    ...prev.visibility,
                    creatorFilters: {
                      ...prev.visibility?.creatorFilters,
                      internalRating: {
                        min: parseFloat((value === 0 ? 2.9 : 3 - value).toFixed(1)),
                        max: 3
                      }
                    }
                  }
                }));
              }}
            />
          </Box>
        </Form.Item>

        <Text textAlign="left" mb={1}>
          Minimum Tier
        </Text>
        <Form.Item>
          <Box mb={5}>
            <Slider
              disabled={namedCreatorIds.length > 0}
              dots
              tooltipVisible={false}
              marks={{
                0: 'Verified',
                1: 'Rising',
                2: 'Community',
                3: 'Standard',
                4: 'N/A'
              }}
              min={0}
              max={4}
              step={1}
              reverse
              value={minTier}
              onChange={value => {
                setMinTier(value);
                setPayload(prev => ({
                  ...prev,
                  visibility: {
                    ...prev.visibility,
                    creatorFilters: {
                      ...prev.visibility?.creatorFilters,
                      tiers: value === 4 ? [] : creatorTiersInOrder.filter(a => a.rank <= value).map(a => a.name)
                    }
                  }
                }));
              }}
            />
          </Box>
        </Form.Item>

        <Text textAlign="left" mb={1}>
          Creator Styles
        </Text>
        <Form.Item>
          <Box textAlign="left">
            <Checkbox.Group
              disabled={namedCreatorIds.length > 0}
              // @ts-ignore
              value={creatorStyles}
              onChange={values => {
                // @ts-ignore
                setCreatorStyles(values);
                setPayload(prev => ({
                  ...prev,
                  visibility: {
                    ...prev.visibility,
                    creatorFilters: {
                      ...prev.visibility?.creatorFilters,
                      styles: values
                    }
                  }
                }));
              }}
            >
              <Row>
                {styles.map((style, i) => (
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} key={i}>
                    <Checkbox value={style.value}>{style.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Box>
        </Form.Item>
      </Container>
    </Box>
  );
};
