import React from 'react';
import Svg from '../Svg';
export const SvgBookmark = ({ size, ...props }) => (
  <Svg width={size} height={size} fill="currentColor" aria-hidden="true" viewBox="0 0 14 20" {...props}>
    <path
      fill="transparent"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 19.037V1h12v18.13l-6-5.627-6 5.534z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </Svg>
);
SvgBookmark.isIcon = true;
SvgBookmark.defaultProps = {
  size: 24,
};
export default SvgBookmark;
