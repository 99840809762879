import React from 'react';
import Svg from '../Svg';
export const SvgShield = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 15 15" fill="none" {...props}>
    <path
      d="M7.857 14c2 0 4.3-3 4.5-5 .2-2 0-6 0-6l-4.5-1.5h-.5M7.446 14c-2 0-4.3-3-4.5-5-.11-1.106.009-2.823.041-4.137"
      stroke="currentColor"
    />
    <path
      d="M7.357 1.5V1a.5.5 0 00-.158.026l.158.474zm-4.36 1.453L2.84 2.48l-.34.113-.002.358.5.003zM7.357 2h.5V1h-.5v1zm0 12.5h.5v-1h-.5v1zM7.2 1.026l-4.36 1.453.316.949 4.36-1.454-.316-.948zM2.497 2.95l-.04 6.047 1 .006.04-6.046-1-.007z"
      fill="currentColor"
    />
    <path d="M5.557 7l1.5 1.5 3-3" stroke="currentColor" />
  </Svg>
);
SvgShield.isIcon = true;
SvgShield.defaultProps = {
  size: 24,
};
export default SvgShield;
