import styled from 'styled-components/macro';
import { flexbox } from 'styled-system';
import Flex from '../flex';
import { FlexInlineProps } from '../types';

const FlexInline = styled(Flex)<FlexInlineProps>(
  {
    display: 'inline-flex',
  },
  flexbox
);

export default FlexInline;
