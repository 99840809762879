import React from 'react';
import Svg from '../Svg';
export const SvgSettings = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M19 10.93h-.027V9.042c0-.068-.028-.152-.124-.152l-2.487-.33a1.156 1.156 0 01-.99-.717 1.514 1.514 0 01-.11-.552c0-.262.07-.51.234-.717l1.525-2.015c.07-.096.07-.192 0-.248L15.675 2.96c-.028-.07-.096-.07-.124-.07-.027 0-.055 0-.082.042l-2.02 1.531c-.234.18-.508.276-.77.276-.659 0-1.167-.483-1.263-1.117l-.33-2.496c0-.097-.097-.125-.152-.125H9.011c-.055 0-.151.028-.151.125L8.53 3.62a1.262 1.262 0 01-1.25 1.076c-.248 0-.51-.083-.728-.235L4.545 2.931c-.055-.041-.082-.041-.123-.041-.028 0-.083.04-.11.069L2.952 4.31c-.055.056-.083.152-.028.207l1.539 2.028c.261.331.33.813.178 1.227a1.295 1.295 0 01-1.03.787l-2.487.33c-.083 0-.124.084-.124.152v1.931c0 .055.041.139.124.139l2.487.344c.453.027.81.29.989.717.082.18.124.36.124.538 0 .262-.083.51-.234.731l-1.538 2.014c-.055.083-.055.18 0 .235l1.387 1.365c.028.055.083.055.124.055.027 0 .055 0 .082-.027l2.007-1.545c.247-.18.508-.262.782-.262.66 0 1.168.483 1.264 1.117l.33 2.497c0 .082.083.11.152.11h1.91c.068 0 .15-.028.15-.11l.33-2.497a1.299 1.299 0 011.265-1.09c.247 0 .508.096.714.235l2.02 1.545c.055.027.082.027.11.027a.2.2 0 00.123-.055l1.347-1.393c.07-.056.096-.152.041-.207l-1.539-2.014c-.261-.33-.33-.813-.178-1.241a1.353 1.353 0 011.017-.786l2.5-.332c.083 0 .11-.082.11-.151z"
      stroke="currentColor"
    />
    <circle cx={10} cy={10} r={3.5} stroke="currentColor" />
  </Svg>
);
SvgSettings.isIcon = true;
SvgSettings.defaultProps = {
  size: 24,
};
export default SvgSettings;
