import React from 'react';
import Svg from '../Svg';
export const SvgDollarCircle = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <circle cx={7} cy={7} r={6.5} stroke="currentColor" />
    <path
      d="M4.473 9.183c-.158.22-.142.526.07.695.597.478 1.415.786 2.475.786 1.871 0 2.682-.988 2.682-2.142 0-1.508-1.362-1.861-2.506-2.152-.821-.208-1.528-.385-1.528-.926 0-.52.457-.863 1.175-.863.52 0 1.047.128 1.502.4.292.173.688.152.895-.116.174-.227.154-.553-.08-.718-.593-.42-1.334-.647-2.213-.647-1.518 0-2.527.884-2.527 2.027 0 1.466 1.331 1.8 2.464 2.08.843.218 1.58.405 1.58 1.03 0 .457-.394.945-1.392.945-.667 0-1.23-.212-1.672-.511-.295-.2-.716-.177-.925.112z"
      fill="currentColor"
    />
    <path d="M7 3v8" stroke="currentColor" strokeLinecap="round" />
  </Svg>
);
SvgDollarCircle.isIcon = true;
SvgDollarCircle.defaultProps = {
  size: 24,
};
export default SvgDollarCircle;
