import gql from 'graphql-tag';

export default gql`
  query CreatorMediaQuery {
    creatorSelf {
      id
      media(limit: 100) {
        items {
          type
          publicId
        }
        nextToken
      }
    }
  }
`;
