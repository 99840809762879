import { Radio, Space } from 'antd';
import React from 'react';
import { FormItem } from '../../../../components-core/form';
import Icon from '../../../../components-core/icon';
import Text from '../../../../components-core/text';
import SvgSkillsetVideographer from '../../../../components-core/icon/components/SkillsetVideographer';
import { Skill } from '../../../../graphql/types/graphql-global-types';
import merge from 'lodash.merge';

export default function SelectSkillStep({ setPayload, currentStep, form, payload, isEdit }: any) {
  return (
    <div className="mx-auto" style={{ maxWidth: 540 }}>
      <div className="text-lg font-bold">Creator skill type</div>
      <Text mt={2} mb={3} fontSize="16px" color="#6F6F6F">
        Select the type of creator you're looking for.
      </Text>
      <div className="flex justify-center">
        <FormItem>
          {form.getFieldDecorator('skill', {
            initialValue: payload?.skill,
            rules: [
              {
                required: currentStep === 0,
                message: 'Please select a creator skill.',
              },
            ],
            onChange: e => {
              setPayload(prev => merge(prev, { skill: e.target.value }));
            },
          })(
            <Radio.Group size="large">
              <Space style={{ padding: '4px' }}>
                <Radio.Button value={Skill.PHOTOGRAPHER} style={{ height: 'auto' }}>
                  <div className="pt-6 pb-4 px-3">
                    <div className="text-center">
                      <Icon name="SkillsetPhotographer" size={42} className="mx-auto inline-block" />
                    </div>
                    <span className="font-bold text-xs uppercase">Photographer</span>
                  </div>
                </Radio.Button>
              </Space>
              <Space style={{ padding: '4px' }}>
                <Radio.Button value={Skill.VIDEOGRAPHER} style={{ height: 'auto' }}>
                  <div className="pt-6 pb-4 px-3">
                    <div className="text-center">
                      <SvgSkillsetVideographer size={42} className="mx-auto" />
                    </div>
                    <span className="font-bold text-xs uppercase">Videographer</span>
                  </div>
                </Radio.Button>
              </Space>
            </Radio.Group>
          )}
        </FormItem>
      </div>
    </div>
  );
}
