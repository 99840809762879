import React, { FunctionComponent } from 'react';

const IconText: FunctionComponent<{ icon?: any; className?: string }> = ({ className, children, icon, ...rest }) => (
  <div className={`flex items-center ${className}`} {...rest}>
    {icon}
    <div className="ml-1">{children}</div>
  </div>
);

export default IconText;
