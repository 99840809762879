import { Empty } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';
import Box from '../../components-core/box';
import Divider from '../../components-core/divider';
import Flex from '../../components-core/flex';
import OutlineButton from '../../components-core/outline-button';
import Text from '../../components-core/text';
import TextButton from '../../components-core/text-button';
import { UserContext } from '../Authenticator';
import { AnnouncementContext } from './AnnouncmentContext';
import createLogger from '../../utils/createLogger';

const logger = createLogger('Announcements');

const Announcements = ({ onAction }: any) => {
  const history = useHistory();
  const user = useContext(UserContext);
  const { removeAnnouncement, announcements, loading } = useContext(AnnouncementContext);
  const transitions = useTransition(announcements, item => item?.id ?? 'key', {
    from: { transform: 'translate3d(300px,0px,0)' },
    enter: { transform: 'translate3d(0,0px,0)' },
    leave: { transform: 'translate3d(300px,0px,0)' },
    immediate: announcements.length > 10,
    // trail: 100,
  });

  return (
    <Box>
      <Box pt={2}>
        {!loading && announcements.length === 0 && (
          <Box pt={2}>
            <Empty description="No Activity" />
          </Box>
        )}
        <Text fontSize={1} p={1}>
          {/* <Flex as="header" justifyContent="space-between" pb={2}> */}
          {/* <Text bold>Announcements ({announcements.length})</Text> */}
          {/* <Link href="/messages">
            <HLink color="black">View All</HLink>
          </Link> */}
          {/* </Flex> */}
          {/* <Divider mb={3} /> */}
          {transitions.map(({ item, key, props }, index) => {
            const announcement = item;
            let heading = 'New Notification';
            const actionText = 'View';
            const eventType = announcement?.metadata?.eventType;
            switch (eventType) {
              case 'ContractCancelled':
                heading = 'Contract Cancelled';
                break;
              case 'ClientChargeRefunded':
                heading = 'Refund Issued';
                break;
              case 'ContractAssetsReady':
                heading = 'Assets ready for review';
                // actionText = 'View Assets';
                break;
              case 'ContractAssetsDelivered':
                heading = 'Assets delivered';
                // actionText = 'View Assets';
                break;
              case 'JobPostProposalCreated':
                heading = 'You received a bid';
                // actionText = 'View Bid';
                break;
              case 'ContractAccepted':
                heading = 'Contract Accepted';
                // actionText = 'View Contract';
                break;
              case 'JobPostCancelled':
                heading = 'Job Canceled';
                break;
              case 'ClientChargeCreated':
                heading = 'Payment pending';
                break;
              case 'ContractAssetsApproved':
                heading = 'Assets have been approved';
                break;
              case 'ContractCreated':
                heading = 'Contract to review';
                break;
              case 'JobPostCreatorInvited':
                heading = 'Bid Requested';
                // actionText = 'View Contract';
                break;
              case 'CreatorPayoutCreated':
                heading = user.__typename === 'Creator' ? 'You got paid!' : 'Job Completed';
                break;
              default:
                logger('[warn] No heading for: ', announcement?.metadata?.eventType);
                break;
            }

            const createdAt = announcement?.createdAt ?? null;
            const createdAtFormatted = createdAt ? moment(new Date(parseInt(createdAt, 10))).fromNow() : null;

            return (
              item && (
                <animated.div key={key} style={props}>
                  <Box>
                    <Flex mb={3} px={3} alignItems="center">
                      <Text flex={1} pr={3}>
                        {createdAtFormatted !== 'Invalid date' && (
                          <Text fontSize="13px" color="headerNavLink" mt={1}>
                            {createdAtFormatted}
                          </Text>
                        )}
                        <Text bold caps fontSize={0} color="text" mb={0}>
                          {heading}
                        </Text>
                        <Text
                          fontSize={1}
                          e
                          mb={1}
                          // fontSize="13px"
                          color="#6F6F6F"
                        >
                          {announcement?.message}
                        </Text>
                      </Text>
                      <Text textAlign="center">
                        <OutlineButton
                          size="small"
                          onClick={() => {
                            if (onAction) {
                              onAction(announcement?.metadata);
                            }
                            const slug = announcement?.metadata?.jobPostSlug;
                            if (slug) {
                              if (user.__typename === 'Client' && eventType === 'JobPostProposalCreated') {
                                history.push(
                                  `/app/my-jobs/manage/bids/${slug}?proposalId=${announcement?.metadata?.proposalId}`
                                );
                              } else {
                                history.push(
                                  user.__typename === 'Client'
                                    ? `/app/my-jobs/manage/redirect/${slug}`
                                    : `/app/${
                                        eventType === 'JobPostCreatorInvited'
                                          ? `job-board?jobSlug=`
                                          : `my-jobs/manage/redirect/`
                                      }${slug}`
                                );
                              }
                            }
                            removeAnnouncement(announcement?.id);
                          }}
                        >
                          {actionText}
                        </OutlineButton>
                        <TextButton
                          mt={2}
                          caps
                          bold
                          // color="blue"
                          fontSize={0}
                          size="small"
                          color="#9f9f9f"
                          onClick={() => {
                            removeAnnouncement(announcement?.id);
                            // onAction(notification.metadata)
                          }}
                        >
                          Remove
                        </TextButton>
                      </Text>
                    </Flex>
                    {index < announcements.length - 1 && <Divider borderColor="rgba(0,0,0,0.1)" mb={3} />}
                  </Box>
                </animated.div>
              )
            );
          })}
        </Text>
      </Box>
    </Box>
  );
};

export default Announcements;
