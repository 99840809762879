import React from 'react';
import Svg from '../Svg';
export const SvgRepeat = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.894 15.956a.587.587 0 01-.587.587H5.506a3.254 3.254 0 01-3.254-3.254V3.503l-1.25 1.25a.587.587 0 01-.83-.83l2.252-2.251c.229-.23.6-.23.83 0l2.252 2.252a.587.587 0 11-.83.83l-1.25-1.25v9.785a2.08 2.08 0 002.08 2.08h4.8c.325 0 .588.263.588.587zM7.106 2.087c0-.324.263-.587.587-.587h4.801a3.254 3.254 0 013.254 3.254v9.785l1.25-1.25a.587.587 0 01.83.83l-2.252 2.252c-.229.229-.6.229-.83 0l-2.252-2.252a.587.587 0 11.83-.83l1.25 1.25V4.754a2.08 2.08 0 00-2.08-2.08h-4.8a.587.587 0 01-.588-.587z"
      fill="currentColor"
    />
  </Svg>
);
SvgRepeat.isIcon = true;
SvgRepeat.defaultProps = {
  size: 24,
};
export default SvgRepeat;
