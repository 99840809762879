import React from 'react';
import upperFirst from 'lodash.upperfirst';
import * as icons from '@thehhub/web-icons';

const Icon: React.FC<any> = ({ name, ...props }) => {
  const Component: any = icons[name] || icons[upperFirst(name)];
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};

Icon.defaultProps = {
  size: 24,
};

Icon.displayName = 'Icon';

export default Icon;
