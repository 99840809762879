import gql from 'graphql-tag';
import ClientProfileSummaryFragment from '../fragments/ClientProfileSummaryFragment';
import CreatorProfileSummaryFragment from '../fragments/CreatorProfileSummaryFragment';

export default gql`
  ${CreatorProfileSummaryFragment}
  ${ClientProfileSummaryFragment}
  query AuthCheckQuery {
    self {
      ... on Creator {
        ...CreatorProfileSummaryFragment
      }
      ... on Client {
        ...ClientProfileSummaryFragment
      }
      ... on Company {
        id
      }
    }
  }
`;
