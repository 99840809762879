import React from 'react';
import Svg from '../Svg';
export const SvgMail = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 15 15" fill="none" {...props}>
    <path d="M1.5 12V4a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v8a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5z" stroke="currentColor" />
    <path d="M13 4L7.5 9 2 4" stroke="currentColor" strokeLinejoin="round" />
  </Svg>
);
SvgMail.isIcon = true;
SvgMail.defaultProps = {
  size: 24,
};
export default SvgMail;
