import styled from 'styled-components/macro';
import { color, space } from 'styled-system';
import theme from '../theme';
import { LinkProps } from '../types';

const Link = styled('a')<LinkProps>`
  cursor: pointer;
  text-decoration: none;
  ${color}
  ${space}
  
  &:hover {
    text-decoration: underline;
    ${color}
  }
`;

Link.displayName = 'Link';

Link.defaultProps = {
  color: 'blue',
  theme,
};

export default Link;
