import React from 'react';
import Svg from '../Svg';
export const SvgTerms = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 17 15" fill="none" {...props}>
    <path
      d="M7 6.5a.5.5 0 000-1v1zm3-3a.5.5 0 000-1v1zm-10 3h7v-1H0v1zm0-3h10v-1H0v1zM2 9.5a.5.5 0 000-1v1zm-2 0h2v-1H0v1zM4 12.5a.5.5 0 000-1v1zm-4 0h4v-1H0v1z"
      fill="currentColor"
    />
    <path d="M16 4l-7 8-3-3" stroke="currentColor" strokeLinecap="round" />
  </Svg>
);
SvgTerms.isIcon = true;
SvgTerms.defaultProps = {
  size: 24,
};
export default SvgTerms;
