import {DatePicker, Radio} from 'antd';
import merge from 'lodash.merge';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import Box from '../../../../components-core/box';
import Container from '../../../../components-core/container';
import Divider from '../../../../components-core/divider';
import Form from '../../../../components-core/form';
import Text from '../../../../components-core/text';
import BlackTooltip from '../../../../components/SimpleTooltip/BlackTooltip';
import {StyledLocationSelect, SubLabel} from './styles';
import {removeTypename} from '../../../../utils/objectUtils';

const LocationStep = ({form, setPayload, payload}) => {
  const [nearMe, setNearMe] = useState<boolean | null>(null);

  useEffect(() => {
    if (nearMe === null) {
      setNearMe(
        !!(
          payload?.details?.location?.locality ||
          payload?.details?.location?.region ||
          payload?.details?.location?.coords?.lat ||
          payload?.details?.location?.coords?.lon
        )
      );
    }
  }, [nearMe, payload]);
  return (
    <Container maxWidth={540}>
      <Text fontSize="18px" bold mb={3}>
        Select your location
      </Text>
      <SubLabel>Location of your shoot</SubLabel>
      <Form.Item>
        {form.getFieldDecorator('nearMe', {
          initialValue: nearMe ? 'a' : nearMe === false ? 'b' : null,
          onChange: e => {
            setNearMe(e.target.value === 'a');
            setPayload(prev => merge(prev, {details: {location: null}}));
          },
          rules: [{required: true, message: 'Please make a choice'}]
        })(
          <Radio.Group
          // value={nearMe ? 'a' : nearMe === false ? 'b' : null}
          >
            <Radio value="a">Specific location</Radio>

            <Radio value="b">
              Remote{' '}
              <BlackTooltip>
                <Text>
                  If location isn’t important, hire a <br />
                  remote photographer. They can shoot <br /> your project anywhere in the country!
                </Text>
              </BlackTooltip>
            </Radio>
          </Radio.Group>
        )}
      </Form.Item>

      {nearMe && (
        <Box my={3}>
          <Form.Item style={{width: '220px'}}>
            {form.getFieldDecorator('details.location', {
              initialValue: payload?.details?.location,
              onChange: value => {
                setPayload(prev => merge(prev, {details: {location: removeTypename(value)}}));
              },
              rules: [{required: nearMe, message: "Don't forget your location! "}]
            })(<StyledLocationSelect />)}
          </Form.Item>
        </Box>
      )}

      <Divider my={3} />
      <SubLabel>
        Due date <BlackTooltip>When do you need your photos by?</BlackTooltip>
      </SubLabel>
      <Form.Item>
        {form.getFieldDecorator('details.schedule.dueDate', {
          initialValue: payload?.details?.schedule?.dueDate ? moment(payload?.details?.schedule?.dueDate) : null,
          onChange: dueDate => {
            setPayload(prev => merge(prev, {details: {schedule: {dueDate}}}));
          },
          rules: [
            {
              required: true,
              message: 'Select the date of your shoot'
            }
          ]
        })(
          <DatePicker
            format="MM/DD/YYYY"
            style={{width: '220px'}}
            // @ts-ignore
            disabledDate={
              current => current && current < moment()
              // || (current && current > moment().add(90, 'days'))
            }
            showToday={false}
            // calendarProps={{
            //   disabledDate: current => current && current < moment(),
            //   showToday: false,
            // }}
            // inputProps={{
            //   id: 'due-date-calendar-input',
            //   size: 'large',
            //   onBlur: () => {
            //     // if (currentStep === 1) {
            //     //   form.validateFields(['details.schedule.dueDate'], {
            //     //     force: currentStep === 1,
            //     //   });
            //     // }
            //   },
            //   prefix: <Icon name="Calendar" size="1em" />,
            // }}
          />
        )}
      </Form.Item>
    </Container>
  );
};

export default LocationStep;
