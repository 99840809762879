/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdminLevel {
  GAME_1 = 'GAME_1',
  GAME_2 = 'GAME_2',
  GAME_3 = 'GAME_3',
  NONE = 'NONE',
  SUPER = 'SUPER',
  TAG = 'TAG',
  USER = 'USER'
}

export enum BusinessType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL'
}

export enum ClientTier {
  TIER1 = 'TIER1',
  TIER2 = 'TIER2',
  TIER3 = 'TIER3'
}

export enum ContractStatus {
  ASSETS_DELIVERED = 'ASSETS_DELIVERED',
  COMPLETE = 'COMPLETE',
  DECLINED = 'DECLINED',
  PAYOUT_PENDING = 'PAYOUT_PENDING',
  PENDING = 'PENDING',
  PROCESSING_ASSETS = 'PROCESSING_ASSETS',
  SIGNED = 'SIGNED'
}

export enum ContractsSortBy {
  AMOUNT_HIGH_TO_LOW = 'AMOUNT_HIGH_TO_LOW',
  AMOUNT_LOW_TO_HIGH = 'AMOUNT_LOW_TO_HIGH',
  RECENT = 'RECENT'
}

export enum CreatorTier {
  COMMUNITY = 'COMMUNITY',
  RISING = 'RISING',
  STANDARD = 'STANDARD',
  VERIFIED = 'VERIFIED'
}

export enum CritiqueCornerSortBy {
  RECENT = 'RECENT',
  TOP_PERFORMING = 'TOP_PERFORMING'
}

export enum Currency {
  usd = 'usd'
}

export enum DeliverableType {
  HOURS = 'HOURS',
  PHOTOS = 'PHOTOS',
  VIDEOS = 'VIDEOS'
}

export enum ExperienceLevel {
  BEGINNER = 'BEGINNER',
  EXPERIENCED = 'EXPERIENCED',
  PROFESSIONAL = 'PROFESSIONAL'
}

export enum EyeColor {
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  GRAY = 'GRAY',
  GREEN = 'GREEN',
  HAZEL = 'HAZEL'
}

export enum FinancialAccountStatus {
  ACTIVE = 'ACTIVE',
  ONBOARDING_REQUIRED = 'ONBOARDING_REQUIRED',
  UPDATE_REQUIRED = 'UPDATE_REQUIRED'
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER'
}

export enum HairColor {
  BLACK = 'BLACK',
  BLONDE = 'BLONDE',
  BRUNETTE = 'BRUNETTE',
  COLORED = 'COLORED',
  OTHER = 'OTHER',
  RED = 'RED'
}

export enum IdentityVerificationStatus {
  COMPLETE = 'COMPLETE',
  ONBOARDING_REQUIRED = 'ONBOARDING_REQUIRED',
  UPDATE_REQUIRED = 'UPDATE_REQUIRED'
}

export enum JobBoardAccess {
  ACTIVE = 'ACTIVE',
  NONE = 'NONE',
  UPDATE_REQUIRED = 'UPDATE_REQUIRED'
}

export enum JobPostCalloutType {
  HIGH_BUDGET = 'HIGH_BUDGET',
  POPULAR = 'POPULAR',
  REPEAT_CLIENT = 'REPEAT_CLIENT'
}

export enum JobPostCancelReason {
  COMPETITOR = 'COMPETITOR',
  DECIDED_NOT_TO_HIRE = 'DECIDED_NOT_TO_HIRE',
  FRIEND_RECOMMENDED = 'FRIEND_RECOMMENDED',
  NOT_SATISFIED_WITH_BIDS = 'NOT_SATISFIED_WITH_BIDS',
  OTHER = 'OTHER'
}

export enum JobPostProposalStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  OPEN = 'OPEN'
}

export enum JobPostSearchSortBy {
  BUDGET_HIGH_TO_LOW = 'BUDGET_HIGH_TO_LOW',
  BUDGET_LOW_TO_HIGH = 'BUDGET_LOW_TO_HIGH',
  PROXIMITY = 'PROXIMITY',
  RECENT = 'RECENT'
}

export enum JobPostStatus {
  ASSETS_READY = 'ASSETS_READY',
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  CONTRACT = 'CONTRACT',
  FILLED = 'FILLED',
  OPEN = 'OPEN',
  REFUNDED = 'REFUNDED',
  REOPENED = 'REOPENED'
}

export enum MediaTypeEnum {
  IMAGE = 'IMAGE',
  S3_PUBLIC_VIDEO = 'S3_PUBLIC_VIDEO',
  VIDEO = 'VIDEO',
  VIMEO = 'VIMEO',
  YOUTUBE = 'YOUTUBE'
}

export enum ModelingStyle {
  PETITE = 'PETITE',
  PLUS_SIZED = 'PLUS_SIZED'
}

export enum ModelingStyleSearchValues {
  OPEN_TO_NUDITY = 'OPEN_TO_NUDITY',
  PETITE = 'PETITE',
  PLUS_SIZED = 'PLUS_SIZED'
}

export enum OverallExperience {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE'
}

export enum PricingPaymentSearchValues {
  BEGINNER = 'BEGINNER',
  EXPERIENCED = 'EXPERIENCED',
  OPEN_TO_TEST_SHOOTS = 'OPEN_TO_TEST_SHOOTS',
  PROFESSIONAL = 'PROFESSIONAL',
  SPONSORED_FOR_PAY = 'SPONSORED_FOR_PAY',
  SPONSORED_FOR_PRODUCT = 'SPONSORED_FOR_PRODUCT'
}

export enum ProfessionalStatusSearchValues {
  PUBLISHED = 'PUBLISHED',
  SIGNED = 'SIGNED',
  UNPUBLISHED = 'UNPUBLISHED',
  UNSIGNED = 'UNSIGNED'
}

export enum ProposalReaction {
  HIDDEN = 'HIDDEN',
  LIKED = 'LIKED',
  NEUTRAL = 'NEUTRAL'
}

export enum ProposalsSortBy {
  AMOUNT_HIGH_TO_LOW = 'AMOUNT_HIGH_TO_LOW',
  PROXIMITY = 'PROXIMITY',
  RATING = 'RATING',
  RECOMMENDED = 'RECOMMENDED'
}

export enum Race {
  AFRICAN_AMERICAN = 'AFRICAN_AMERICAN',
  AMERICAN_INDIAN = 'AMERICAN_INDIAN',
  ASIAN = 'ASIAN',
  LATINO = 'LATINO',
  OTHER = 'OTHER',
  PACIFIC_ISLANDER = 'PACIFIC_ISLANDER',
  WHITE = 'WHITE'
}

export enum SentProposalsSortBy {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  OPEN = 'OPEN',
  RECENT = 'RECENT'
}

export enum Skill {
  MAKEUP_ARTIST = 'MAKEUP_ARTIST',
  MODEL = 'MODEL',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  STYLIST = 'STYLIST',
  VIDEOGRAPHER = 'VIDEOGRAPHER'
}

export enum UsageRights {
  DIGITAL = 'DIGITAL',
  OTHER = 'OTHER',
  UNLIMITED = 'UNLIMITED'
}

export interface BodyMeasurementsInput {
  bust?: number | null;
  waist?: number | null;
  hips?: number | null;
  dress?: string | null;
  pants?: number | null;
  height?: number | null;
}

export interface ClientUpdateInput {
  email?: string | null;
  givenName?: string | null;
  familyName?: string | null;
  companyName?: string | null;
  location?: LocationInput | null;
  birthdate?: string | null;
  phoneNumber?: string | null;
  settings?: SettingsInput | null;
  avatar?: ImageInput | null;
}

export interface ContractDeliverablesInput {
  type: DeliverableType;
  quantity?: number | null;
}

export interface CreatorFeedbackRatingInput {
  overall: number;
  value?: number | null;
  quality?: number | null;
  timeliness?: number | null;
  communication?: number | null;
  professionalism?: number | null;
}

export interface CreatorSearchFiltersInput {
  skills?: (CreatorSkillInput | null)[] | null;
  location?: GeolocationInput | null;
  radius?: number | null;
  styles?: (string | null)[] | null;
  followers?: RangeFilter | null;
  genders?: (Gender | null)[] | null;
  races?: (Race | null)[] | null;
  tags?: (TagInput | null)[] | null;
  internalRating?: FloatRangeFilter | null;
  country?: string | null;
}

export interface CreatorSkillInput {
  name: Skill;
  details?: (string | null)[] | null;
}

export interface CritiqueCornerCritiqueInput {
  color?: PhotoRatingInput | null;
  composition?: PhotoRatingInput | null;
  subject?: PhotoRatingInput | null;
}

export interface CritiqueCornerPhotoInput {
  image: ImageInput;
}

export interface FileInput {
  key: string;
  name: string;
  extension?: string | null;
  mimeType?: string | null;
  size?: number | null;
}

export interface FloatRangeFilter {
  min?: number | null;
  max?: number | null;
}

export interface FloatRangeInput {
  min?: number | null;
  max?: number | null;
}

export interface GeolocationInput {
  lat: number;
  lon: number;
}

export interface ImageInput {
  publicId: string;
}

export interface JobPostBudgetInput {
  range?: RangeInput | null;
  fixed?: number | null;
}

export interface JobPostCancelInput {
  reason?: string | null;
  cancelledReason?: JobPostCancelReason | null;
  willPostInFuture?: boolean | null;
}

export interface JobPostContractAcceptInput {
  ipAddress?: string | null;
  userAgent?: string | null;
  fingerprint?: string | null;
}

export interface JobPostContractAssetsInput {
  photos?: (FileInput | null)[] | null;
}

export interface JobPostContractInput {
  creatorId: string;
  conversationId?: string | null;
  jobPostProposalId?: string | null;
  amount: number;
  currency: Currency;
  dueDate: string;
  deliverables: (ContractDeliverablesInput | null)[];
  scopeOfWork: string;
  discountCode?: string | null;
  usage?: JobPostUsageRightsInput | null;
}

export interface JobPostContractUpdateInput {
  amount?: number | null;
  currency?: Currency | null;
  dueDate?: string | null;
  deliverables?: (ContractDeliverablesInput | null)[] | null;
  scopeOfWork?: string | null;
  usage?: JobPostUsageRightsInput | null;
}

export interface JobPostCreatorFeedbackInput {
  ratings?: CreatorFeedbackRatingInput | null;
  review?: string | null;
}

export interface JobPostCreatorFiltersInput {
  creatorIds?: (string | null)[] | null;
  internalRating?: FloatRangeInput | null;
  tiers?: (CreatorTier | null)[] | null;
  tags?: (string | null)[] | null;
  styles?: (string | null)[] | null;
}

export interface JobPostDetailsInput {
  styles?: (string | null)[] | null;
  budget?: JobPostBudgetInput | null;
  schedule?: JobPostScheduleInput | null;
  location?: JobPostLocationInput | null;
  guidelines?: JobPostGuidelinesInput | null;
  moodBoard?: (MediaItemInput | null)[] | null;
  sections?: (JobPostSectionInput | null)[] | null;
  files?: (FileInput | null)[] | null;
  usage?: JobPostUsageRightsInput | null;
}

export interface JobPostGuidelinesInput {
  do?: (string | null)[] | null;
  dont?: (string | null)[] | null;
}

export interface JobPostInput {
  title: string;
  description: string;
  skill: Skill;
  visibility: JobVisibilityInput;
  details?: JobPostDetailsInput | null;
}

export interface JobPostLocationInput {
  formatted?: string | null;
  streetAddress?: string | null;
  locality: string;
  region: string;
  country: string;
  postalCode?: string | null;
  coords: GeolocationInput;
}

export interface JobPostPlatformFeedbackInput {
  overall?: OverallExperience | null;
  positives?: (string | null)[] | null;
  negatives?: (string | null)[] | null;
  review?: string | null;
}

export interface JobPostProposalInput {
  conversationId?: string | null;
  amount: number;
  introduction?: string | null;
  headline?: string | null;
  vision?: string | null;
  media?: (MediaItemInput | null)[] | null;
  deliverables?: (ContractDeliverablesInput | null)[] | null;
}

export interface JobPostScheduleInput {
  dueDate?: string | null;
  shootDate?: string | null;
  eventDate?: string | null;
}

export interface JobPostSearchFiltersInput {
  skills?: (CreatorSkillInput | null)[] | null;
  styles?: (string | null)[] | null;
  location?: GeolocationInput | null;
  radius?: number | null;
  offsite?: boolean | null;
  budget?: RangeInput | null;
  keywordText?: string | null;
  wildcardText?: string | null;
}

export interface JobPostSectionInput {
  title: string;
  description?: string | null;
  media?: (MediaItemInput | null)[] | null;
  items?: (string | null)[] | null;
}

export interface JobPostUpdateInput {
  title?: string | null;
  description?: string | null;
  skill?: Skill | null;
  visibility?: JobVisibilityInput | null;
  details?: JobPostDetailsInput | null;
}

export interface JobPostUsageRightsInput {
  type: UsageRights;
  description?: string | null;
}

export interface JobVisibilityInput {
  public: boolean;
  listed: boolean;
  creatorFilters?: JobPostCreatorFiltersInput | null;
}

export interface LocationInput {
  locality?: string | null;
  region?: string | null;
  country?: string | null;
  postalCode?: string | null;
  coords?: GeolocationInput | null;
}

export interface MediaItemInput {
  publicId?: string | null;
  displayUrl?: string | null;
  thumbnailUrl?: string | null;
  type: MediaTypeEnum;
  caption?: string | null;
}

export interface NielsenAgeFilter {
  under18?: boolean | null;
  eighteenToTwenty?: boolean | null;
  twentyOneToTwentyFour?: boolean | null;
  twentyFiveToTwentyNine?: boolean | null;
  thirtyToThirtyFour?: boolean | null;
  thirtyFiveToFortyFour?: boolean | null;
  fortyFiveAndOver?: boolean | null;
}

export interface NotificationSettingsInput {
  promoEmails?: boolean | null;
  pushNotifications?: boolean | null;
}

export interface PaymentAccountInput {
  sourceToken: string;
}

export interface PaymentAccountUpdateInput {
  sourceToken?: string | null;
}

export interface PayoutAccountInput {
  businessType: BusinessType;
  accountToken: string;
  externalAccountToken: string;
  personToken?: string | null;
}

export interface PayoutAccountUpdateInput {
  accountToken?: string | null;
  externalAccountToken?: string | null;
  personToken?: string | null;
}

export interface PhotoRatingInput {
  successful?: boolean | null;
  positiveTags?: (TagInput | null)[] | null;
  negativeTags?: (TagInput | null)[] | null;
}

export interface PrivacySettingsInput {
  publicProfile?: boolean | null;
}

export interface ProfessionalInput {
  level?: ExperienceLevel | null;
  signed?: boolean | null;
  published?: boolean | null;
  testShoots?: boolean | null;
  nudity?: boolean | null;
  sponsoredPosts?: SponsoredPostsInput | null;
  modelingStyle?: ModelingStyle | null;
}

export interface RangeFilter {
  min?: number | null;
  max?: number | null;
}

export interface RangeInput {
  min?: number | null;
  max?: number | null;
}

export interface RatedTagInput {
  name: string;
  rating: number;
}

export interface SettingsInput {
  privacy?: PrivacySettingsInput | null;
  notification?: NotificationSettingsInput | null;
}

export interface SocialMediaHandlesInput {
  instagram?: string | null;
  facebook?: string | null;
  youtube?: string | null;
  snapchat?: string | null;
  twitter?: string | null;
  pinterest?: string | null;
  linkedIn?: string | null;
  vimeo?: string | null;
  tiktok?: string | null;
}

export interface SponsoredPostsInput {
  pay?: boolean | null;
  product?: boolean | null;
}

export interface TagInput {
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
