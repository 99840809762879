import { useQuery } from '@apollo/react-hooks';
import { Button, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';

import CreatorCard from '../CreatorCard/CreatorCard';
import './CreatorCardPreviewModal.less';
import PictureOutlined from '@ant-design/icons/PictureOutlined';

const CreatorCardPreviewModal = () => {
  const [visible, setVisible] = useState(false);
  const creatorCardQuery = useQuery(
    gql`
      query CreatorCardQuery {
        self {
          ... on Creator {
            id
            name
            skill {
              name
            }
            otherSkills {
              name
            }
            avatar {
              publicId
            }
            promoImages {
              publicId
            }
            location {
              region
              locality
              country
            }
          }
        }
      }
    `,
    {
      ssr: false,
      fetchPolicy: 'cache-and-network',
    }
  );
  const { data, loading } = creatorCardQuery;
  const creator = data ? data.self : null;
  if (!creator) {
    return null;
  }

  return (
    <div>
      <Button icon={<PictureOutlined />} onClick={() => setVisible(true)}>
        Preview Card
      </Button>
      <Modal visible={visible} title="My Card" footer={null} onCancel={() => setVisible(false)}>
        <div className="creator-card-preview-modal pointer-events-none">
          <CreatorCard creator={data.self} loading={loading} />
        </div>
      </Modal>
    </div>
  );
};

export default CreatorCardPreviewModal;
