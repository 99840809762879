import React from 'react';
import Svg from '../Svg';
export const SvgCash = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 15 20" fill="none" {...props}>
    <path
      d="M13.044 12.848c0-2.882-2.398-3.718-4.664-4.356V3.718c1.157.165 2.236.641 3.074 1.5.086.088.23.086.309-.01l.84-1.024a.196.196 0 00-.011-.262c-1.045-1.05-2.455-1.728-4.212-1.876V0H7.038v2.002c-2.64.176-4.576 1.782-4.576 4.07 0 2.706 2.332 3.476 4.576 4.07v5.346c-1.633-.165-2.916-.971-3.777-1.87a.206.206 0 00-.31.01l-.847 1.07a.196.196 0 00.008.254c1.101 1.19 2.757 2.058 4.926 2.186v1.958H8.38v-1.98c3.41-.242 4.664-2.288 4.664-4.268zm-8.69-6.886c0-1.298 1.1-2.222 2.684-2.31V8.14c-1.474-.418-2.684-.924-2.684-2.178zm4.026 9.504v-4.972c1.518.462 2.794 1.012 2.794 2.508 0 1.012-.682 2.288-2.794 2.464z"
      fill="currentColor"
    />
  </Svg>
);
SvgCash.isIcon = true;
SvgCash.defaultProps = {
  size: 24,
};
export default SvgCash;
