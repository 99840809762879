import React from 'react';
import Link from '../components-core/link';
import Text from '../components-core/text';

const ERROR_MAP: any = {
  SIGN_UP: {
    UserEmailAlreadyExists: (
      <>
        <Text>
          This email is already signed up. Try{' '}
          <Link href="/sign-in" title="Sign In">
            signing in
          </Link>
          .
        </Text>
      </>
    ),
  },
  SIGN_IN: {
    NotAuthorizedException: 'Either your email or password is incorrect.',
    UserLambdaValidationException: 'Your account is still under review.', // only custom one that fires for PENDING creators
    UserNotConfirmedException: 'You must confirm your email before signing in.',
    UserNotFoundException: (
      <Text as="span">
        We didn&rsquo;t recognize that login.&nbsp;
        <Link
          href="https://thehhub.com/start/"
          title="Apply"
          data-cy="apply-link"
          target="_blank"
          rel="noreferrer noopener"
          color="red"
        >
          <Text as="span" bold>
            Apply now!
          </Text>
        </Link>
      </Text>
    ),
    CATCH_ALL: (
      <>Hmm. We&rsquo;re having trouble finding your account. Try again or contact us if you need more help!</>
    ),
  },
};

// eslint-disable-next-line
export const EMAIL_PHONE_URL_HANDLE_MATCHER = /([a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]))|([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6})|(\d{2,}.{0,3}\d{2,}.{0,3}\d{2,})|((?:https\:\/\/)|(?:http\:\/\/)|(?:www\.))?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(?:\??)[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~]+)|(@[a-zA-Z0-9_]{1,})/gi;

export const CREATOR_SIGNUP_KEY = 'hub-csk';

export default ERROR_MAP;
