import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import './VideoBackground.css';

export default function VideoBackground({ children }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [show, setVisible] = useState(false);
  useEffect(() => {
    const videoEl = videoRef.current;
    if (!videoEl) {
      return;
    }
    function handleLoad() {
      setVisible(true);
    }
    videoEl.addEventListener('loadeddata', handleLoad);
    return () => {
      videoEl.removeEventListener('loadeddata', handleLoad);
    };
  }, [videoRef]);

  return (
    <>
      <div className="relative z-10">{children}</div>
      <div className="fixed w-screen h-screen top-0 left-0 video-bg">
        <video
          className={cx('transition-opacity duration-400 ease-linear', {
            'opacity-100': show,
            'opacity-0': !show,
          })}
          style={{
            //   height: '100%',
            //   width: '177.77777778vh',
            //   minWidth: '100%',
            //   minHeight: '56.25vw',
            maxWidth: 'inherit',
          }}
          // show={show}
          ref={videoRef}
          playsInline
          autoPlay
          muted
          // onLoadedData={() => alert('test')}
          loop
          poster="https://res.cloudinary.com/dfifzuyto/video/upload/q_auto/homepage/Hero-Color-2.jpg"
        >
          <source
            src="https://res.cloudinary.com/dfifzuyto/video/upload/q_auto/homepage/Hero-Color-2.mp4"
            type="video/mp4"
          />
          <source
            src="https://res.cloudinary.com/dfifzuyto/video/upload/q_auto/homepage/Hero-Color-2.ogv"
            type="video/ogg"
          />
        </video>
      </div>
    </>
  );
}
