import React, { cloneElement, Component, ReactElement } from 'react';
import styled from 'styled-components/macro';
import Box from '../box';
import Text from '../text';
import { FormItemProps } from '../types';
import { FIELD_DATA_PROP, FIELD_META_PROP } from './constants';

function intersperseSpace<T>(list: T[]): Array<T | string> {
  // @ts-ignore
  return list.reduce((current, item) => [...current, ' ', item], []).slice(1);
}

const StyledFormItem = styled(Box)<FormItemProps>``;

class FormItem extends Component<FormItemProps> {
  static defaultProps = {
    mb: 2,
    labelProps: {
      fontSize: 1,
      caps: true,
      bold: true,
    },
    errorProps: {
      color: 'red',
    },
  };

  getControls(children: React.ReactNode, recursively: boolean) {
    let controls: Array<ReactElement<any>> = [];
    const childrenArray = React.Children.toArray(children);

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < childrenArray.length; i++) {
      if (!recursively && controls.length > 0) {
        break;
      }

      const child = childrenArray[i] as ReactElement<any>;
      if (child.type && ((child.type as any) === FormItem || (child.type as any).displayName === 'FormItem')) {
        continue;
      }
      if (!child.props) {
        continue;
      }
      if (FIELD_META_PROP in child.props) {
        controls.push(child);
      } else if (child.props.children) {
        controls = controls.concat(this.getControls(child.props.children, recursively));
      }
    }

    return controls;
  }

  getOnlyControl() {
    const [child] = this.getControls(this.props.children, false);
    return child !== undefined ? child : null;
  }

  getChildProp(prop: string) {
    const child = this.getOnlyControl() as React.ReactElement<any>;
    return child && child.props && child.props[prop];
  }

  getId() {
    return this.getChildProp('id');
  }

  getMeta() {
    return this.getChildProp(FIELD_META_PROP);
  }

  getField() {
    return this.getChildProp(FIELD_DATA_PROP);
  }

  getErrorMessage() {
    if (this.getOnlyControl()) {
      const { errors } = this.getField();
      if (errors) {
        return intersperseSpace(
          errors.map((e: any, index: number) => {
            let node: React.ReactElement<any> | null = null;

            if (React.isValidElement(e)) {
              node = e;
            } else if (React.isValidElement(e.message)) {
              node = e.message;
            }

            return node ? React.cloneElement(node, { key: index }) : e.message;
          })
        );
      }
    }

    return '';
  }

  render() {
    const { label, labelProps, errorProps, color, children, ...rest } = this.props;
    const error = this.getErrorMessage();
    const onlyControl = this.getOnlyControl();
    const control = onlyControl && onlyControl.key ? onlyControl : children;
    if (!control) {
      return null;
    }
    // @ts-ignore
    const enhancedControl = cloneElement(control, {
      ...(error ? { color: 'red' } : {}),
    });

    return (
      // @ts-ignore
      <StyledFormItem {...rest}>
        {typeof label === 'string' ? (
          <Text mb={2} {...labelProps}>
            {label}
          </Text>
        ) : (
          label
        )}
        <Box>{enhancedControl}</Box>
        {error ? (
          <Text mt={1} color="red" {...errorProps}>
            {error}
          </Text>
        ) : null}
      </StyledFormItem>
    );
  }
}

export default FormItem;
