import React from 'react';
import Svg from '../Svg';
export const SvgCopy = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...props}>
    <rect x={0.5} y={2.5} width={7} height={7} rx={0.5} stroke="currentColor" />
    <path d="M4.8 2.5V1c0-.11.09-.2.2-.2h6c.11 0 .2.09.2.2v6.045a.2.2 0 01-.199.2H7.5" stroke="currentColor" />
  </Svg>
);
SvgCopy.isIcon = true;
SvgCopy.defaultProps = {
  size: 24,
};
export default SvgCopy;
