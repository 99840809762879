import React from 'react';
import Svg from '../Svg';
export const SvgCalendar = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <rect x={1.5} y={2.5} width={17} height={15} rx={1.5} stroke="currentColor" />
    <path stroke="currentColor" d="M18 7.5H2" />
    <path stroke="currentColor" strokeLinecap="round" d="M4.5 1.5v3M10 1.5v3M15.5 1.5v3" />
  </Svg>
);
SvgCalendar.isIcon = true;
SvgCalendar.defaultProps = {
  size: 24,
};
export default SvgCalendar;
