import React from 'react';
import Svg from '../Svg';
export const SvgArrowCircle = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <circle cx={10} cy={10} r={9.5} stroke="currentColor" />
    <path
      d="M5.333 10.07h8.682M10.217 14.14l4-4a.1.1 0 000-.14l-4-4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
SvgArrowCircle.isIcon = true;
SvgArrowCircle.defaultProps = {
  size: 24,
};
export default SvgArrowCircle;
