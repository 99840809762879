import gql from 'graphql-tag';

export default gql`
  query Bookmarks($limit: Int, $nextToken: String) {
    bookmarks(limit: $limit, nextToken: $nextToken) {
      nextToken
      total
      items {
        id
        name
        avatar {
          publicId
        }
        instagramProfile {
          id
          counts {
            followers
          }
        }
        location {
          locality
          region
        }
        promoImages {
          publicId
        }
        otherSkills {
          name
        }
        skill {
          name
        }
        slug
      }
    }
  }
`;
