import React from 'react';
import Svg from '../Svg';
export const SvgLogoInstagram = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
    <rect x={0.75} y={0.75} width={18.5} height={18.5} rx={5.25} stroke="currentColor" strokeWidth={1.5} />
    <circle cx={10} cy={10} r={4.25} stroke="currentColor" strokeWidth={1.5} />
    <path d="M16.1 4.63a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM15.5 4v1" stroke="currentColor" />
  </Svg>
);
SvgLogoInstagram.isIcon = true;
SvgLogoInstagram.defaultProps = {
  size: 24,
};
export default SvgLogoInstagram;
