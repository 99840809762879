import styled from 'styled-components/macro';
import { flex, layout, space, FlexProps, LayoutProps, SpaceProps } from 'styled-system';
import { bordersStyle, inputStyles } from './styles';

const formState = props => {
  const { errors } = props['data-__field'];
  if (errors) {
    return {
      borderColor: props.theme.colors.red,
    };
  }
  return {};
};

const Textarea = styled.textarea<FlexProps & LayoutProps & SpaceProps>`
  ${inputStyles};
  min-height: 100px;
  line-height: 1.4;
  ${bordersStyle}
  ${layout}
  ${space} 
  ${flex}
  ${formState}
`;

export default Textarea;
