import styled from 'styled-components/macro';
import { color } from 'styled-system';
import Button from '../button';

const TransparentButton = styled(Button)`
  padding: 0;
  background-color: transparent;
  color: inherit;
  width: auto;
  min-width: inherit;
  border-color: transparent;
  text-shadow: none;
  text-transform: none;
  font-size: inherit;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    border-color: transparent;
  }

  &:focus {
    background-color: transparent;
    border-color: transparent;
  }
  &[disabled],
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    opacity: 0.3;
  }

  &[disabled],
  &:disabled {
    cursor: default;
  }
  & > div {
    display: flex;
  }
  ${color}
`;

export default TransparentButton;
