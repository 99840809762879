import './wdyr';
import './domainCheck';
import './setupLogRocket';
import React from 'react';
import ReactDOM from 'react-dom';
import './setupFullStory';
// import PageLoadIndicator from './components/PageLoadIndicator';
import './index.scss';
import './tailwind-generated.css';
import App from './App';

// const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);
