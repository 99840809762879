import gql from 'graphql-tag';
import ClientJobPostFragment from '../fragments/ClientJobPostFragment';

export default gql`
  ${ClientJobPostFragment}
  query ClientJobsQuery($limit: Int, $nextToken: String) {
    clientSelf {
      jobs(limit: $limit, nextToken: $nextToken) {
        items {
          ...ClientJobPostFragment
        }
        total
        nextToken
      }
    }
  }
`;
