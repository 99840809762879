import gql from 'graphql-tag';

export default gql`
  fragment MediaItemFragment on MediaItem {
    caption
    createdAt
    displayUrl
    publicId
    thumbnailUrl
    type
  }
`;
