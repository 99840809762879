import gql from 'graphql-tag';

export default gql`
  query Announcements($limit: Int, $nextToken: String) {
    announcements(limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        metadata {
          eventType
          jobPostId
          jobPostSlug
          jobPostTitle
          contractId
          jobPostProposalId
          proposalId
        }
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
