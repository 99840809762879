import { useApolloClient } from '@apollo/react-hooks';
import { Col, Row } from 'antd';
import gql from 'graphql-tag';
import React from 'react';
// import CREATOR_HEAL from '../../graphql/mutations/creatorHeal.graphql';
import { buildCloudinaryImageUrl } from '../../utils/cloudinaryUtils';
import './CreatorCardCover.less';
import cloudinaryTransformations from '../../constants/cloudinaryTransformations';
// import { withApollo } from 'react-apollo';

const placeholder = 'https://res.cloudinary.com/dfifzuyto/image/upload/w_160/oubvsnmrmclldpoauned';

const healCreator = (client, creatorId) =>
  client.mutate({
    mutation: gql`
      mutation CreatorHeal($creatorId: ID!) {
        creatorHeal(creatorId: $creatorId, promoImages: true) {
          success
        }
      }
    `,
    variables: {
      creatorId,
    },
  });

const CreatorCardCover = ({ creator, loading }) => {
  const client = useApolloClient();
  let promoImageOne;
  let promoImageTwo;
  const promoTransform = cloudinaryTransformations.promoImage;
  let missingPromoImages = false;

  if (!creator) {
    promoImageOne = placeholder;
    promoImageTwo = placeholder;
  }

  if (creator && creator.promoImages === null) {
    promoImageOne = placeholder;
    promoImageTwo = placeholder;
  }

  if (creator && creator.promoImages) {
    if (!creator.promoImages[0]) {
      promoImageOne = placeholder;
      missingPromoImages = true;
    } else {
      promoImageOne = loading ? placeholder : buildCloudinaryImageUrl(creator.promoImages[0].publicId, promoTransform);
    }
    if (!creator.promoImages[1]) {
      promoImageTwo = placeholder;
      missingPromoImages = true;
    } else {
      promoImageTwo = loading ? placeholder : buildCloudinaryImageUrl(creator.promoImages[1].publicId, promoTransform);
    }
  } else {
    missingPromoImages = true;
  }

  if (creator && missingPromoImages) {
    healCreator(client, creator.id);
  }

  // const onError = () => {
  //   if (creator) {
  //     healCreator(client, creator.id);
  //   }
  // };

  return (
    <Row className="creator-card-cover" gutter={1}>
      <Col span={12}>
        <img
          key="1"
          src={promoImageOne}
          alt={creator ? creator.name : ''}
          // defaultImageSrc={placeholder}
          // onError={onError}
        />
      </Col>
      <Col span={12}>
        <img
          key="2"
          src={promoImageTwo}
          alt={creator ? creator.name : ''}
          // defaultImageSrc={placeholder}
          // onError={onError}
        />
      </Col>
    </Row>
  );
};

export default CreatorCardCover;
