import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { Helmet } from 'react-helmet';
import { css } from 'styled-components/macro';
import fineGrainImageSrc from '../../assets/images/fine-grain.jpeg';
import Box from '../../components-core/box';
import Container from '../../components-core/container';
import Icon from '../../components-core/icon';
import Text from '../../components-core/text';
import { withAuthorization } from '../../components/Authenticator';
import JobPostRepostQuery from '../../graphql/queries/JobPostRepostQuery';
import ClientInterstitial from './components/PostJobFormInterstitial/PostJobFormInterstitial';
import RePostForm from './RepostJobForm';

const RePostJobPage = ({ match: { params } }) => {
  const jobPostQuery = useQuery(JobPostRepostQuery, {
    variables: {
      id: params?.jobId,
    },
    skip: !params?.jobId,
  });

  const containerProps = {
    maxWidth: 720,
    py: [3, 3, 3, 4],
    px: [3, 3, 3, 0],
    pb: 0,
  };

  return (
    <>
      <Helmet>
        <title>Re-Post a Job &ndash; The H Hub</title>
      </Helmet>
      <ClientInterstitial />

      <Box
        minHeight="100vh"
        // @ts-ignore
        css={css`
          background: radial-gradient(
              261.59% 111.01% at 50% 19.93%,
              rgba(109, 81, 212, 0.6) 0%,
              rgba(218, 206, 255, 0.6) 100%
            ),
            url('https://res.cloudinary.com/dfifzuyto/image/upload/w_2080,q_auto/post-a-job/bg.jpg');
          background-size: cover;
          position: relative;
          z-index: 2;

          &:before {
            content: ' ';
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: url(${fineGrainImageSrc}) fixed;
            background-blend-mode: color-burn;
            opacity: 0.1;
          }
        `}
      >
        <Box position="relative" zIndex={9}>
          <Container {...containerProps}>
            <Text color="white">
              <Text
                fontSize={6}
                color="white"
                bold
                // mb={3}
                textAlign="center"
                // @ts-ignore
                css={css`
                  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
                `}
              >
                <Icon name={'Repeat'} /> Repost Job
              </Text>
              <Box mt={2} mb={4} mx="auto" maxWidth={396}>
                <Text
                  fontSize="18px"
                  textAlign="center"
                  // @ts-ignore
                  css={css`
                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
                  `}
                >
                  Your job is ready to be posted again! Edit any details here or in the job manger.
                </Text>
              </Box>
            </Text>
            <Box px={3}>
              <RePostForm
                // @ts-ignore
                jobPost={jobPostQuery.data?.jobPost}
              />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default withAuthorization('Client')(RePostJobPage);
