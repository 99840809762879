import React from 'react';
import Svg from '../Svg';
export const SvgEyeCircle = ({ size, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 26 26" fill="none" {...props}>
    <g stroke="currentColor">
      <circle cx={13} cy={13} r={12.5} transform="rotate(-180 13 13)" />
      <path d="M21 11s-3.582 4-8 4-8-4-8-4" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7 13l-2 2M9.751 14.59l-1.195 2.564M15.684 14.718l1.195 2.564M13 17.5v-2M19 13l2 2"
        strokeLinecap="round"
      />
    </g>
  </Svg>
);
SvgEyeCircle.isIcon = true;
SvgEyeCircle.defaultProps = {
  size: 24,
};
export default SvgEyeCircle;
