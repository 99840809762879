import { Button } from 'antd';
import React, { useState } from 'react';
import { MediaTypeEnum } from '../../graphql/types/graphql-global-types';
import PortfolioMediaItem from '../../PortfolioMediaItem';
import { removeTypename } from '../../utils/objectUtils';
import CreatorPortfolioManagement from '../CreatorPortfolioManagement';
// import { default as HInput } from '../components-core/input';

const InactiveTick = props => (
  <svg width={26} height={26} fill="none" {...props}>
    <circle cx={13} cy={13} r={12.5} fill="#3F3F3F" fillOpacity={0.45} stroke="#FEFEFE" />
  </svg>
);
const ActiveTick = props => (
  <svg width={26} height={26} fill="none" {...props}>
    <circle cx={13} cy={13} r={12.5} fill="#6BA8BF" stroke="#FEFEFE" />
    <path d="M18 9l-7 8-3-3" stroke="#FEFEFE" strokeLinecap="round" />
  </svg>
);

const CreatorPortfolioSelection = ({
  portfolio,
  onCancel,
  onAdd,
  isSingleSelect = false,
  imagesOnly = false,
  description = 'Select the media you would like to add to your bid',
  actionText = 'Add',
}) => {
  const [selected, setSelected] = useState<any[]>([]);

  // if (creatorMediaQuery.loading) {
  //   return null;
  // }

  return (
    <>
      <div
        className="flex items-center justify-between bg-white px-6 relative z-10 border-b border-borderGray"
        style={{ height: 88 }}
      >
        {/*
      <Flex
        alignItems="center"
        justifyContent="space-between"
        bg="white"
        height="88px"
        px={[3, 3, 3, 3]}
        position="relative"
        zIndex={99}
      > */}
        <div className="font-bold text-text text-xl flex">
          <span className="pr-2">Your portfolio </span>
          <CreatorPortfolioManagement
            media={portfolio}
            trigger={
              <Button size="small">
                <span className="font-bold uppercase text-gray-900 text-opacity-75 text-xs">Manage</span>
              </Button>
            }
          />
        </div>

        <div className="flex">
          <Button type="link" onClick={onCancel} className="mr-3">
            Cancel
          </Button>
          <Button
            type="primary"
            className="uppercase font-bold"
            disabled={selected.length === 0}
            // width={120}
            onClick={() => {
              if (onAdd) {
                const value = removeTypename(selected);
                const [single] = value;
                onAdd(isSingleSelect ? single : value);
                setSelected([]);
              }
            }}
            // disabled={selected.length < 3}
          >
            <span className="uppercase font-bold">{actionText}</span>
          </Button>
        </div>
      </div>
      <div className="p-3 md:p-6 overflow-y-auto" style={{ height: 'calc(60vh - 44px)' }}>
        <div className="text-lg mb-6">{description}</div>
        <div className="grid grid-cols-3 gap-4">
          {portfolio
            .filter(mediaItem => {
              if (imagesOnly) {
                return mediaItem.type === MediaTypeEnum.IMAGE;
              }
              return true;
            })
            .map((mediaItem, i) => {
              const { publicId, type } = mediaItem;
              return (
                <button
                  onClick={() => {
                    if (isSingleSelect) {
                      setSelected([mediaItem]);
                    } else {
                      if (selected.filter(selection => selection.publicId === publicId).length > 0) {
                        setSelected(prev => prev.filter(mediaItem => mediaItem.publicId !== publicId));
                      } else {
                        // @ts-ignore
                        setSelected(prev => prev.concat([mediaItem]));
                      }
                    }
                  }}
                  className="relative"
                  key={i}
                >
                  <div className="absolute top-0 right-0 mr-2 mt-2 z-10">
                    {
                      // @ts-ignore
                      // @ts-ignore
                      selected.findIndex(item => item.publicId === publicId) !== -1 ? <ActiveTick /> : <InactiveTick />
                    }
                  </div>

                  {/* eslint-disable-next-line react/jsx-no-undef */}
                  <PortfolioMediaItem type={type} publicId={publicId} />
                </button>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default CreatorPortfolioSelection;
