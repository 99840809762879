import gql from 'graphql-tag';
import ClientJobPostSummaryFragment from '../fragments/ClientJobPostSummaryFragment';

export default gql`
  ${ClientJobPostSummaryFragment}
  query Self {
    self {
      ... on Creator {
        id
        adminLevel
        layerId
        jobBoardAccess
        name
        avatar {
          publicId
        }
        bio
        slug
        social {
          instagram
          facebook
          youtube
          snapchat
          twitter
          pinterest
          linkedIn
          vimeo
        }
        website
        # settings
        promoImages {
          publicId
        }
        media(limit: 100) {
          items {
            id
            type
            publicId
            displayUrl
            thumbnailUrl
            caption
            createdAt
          }
          nextToken
        }
        bookmarks {
          items {
            id
            layerId
            name
            slug
            website
            promoImages {
              publicId
            }
            otherSkills {
              details
            }
            styles {
              name
            }
          }
          nextToken
          total
        }
        recommendations {
          items {
            ... on CreatorSummary {
              id
              layerId
              name
              slug
              website
              avatar {
                publicId
              }
              styles {
                name
              }
              skill {
                name
              }
              otherSkills {
                name
              }
            }
          }
          nextToken
          total
        }
        skill {
          name
          details
        }
        otherSkills {
          name
          details
        }
        styles {
          name
          rating
        }
        gear
        pro {
          level
          signed
          published
          testShoots
          nudity
          sponsoredPosts {
            pay
            product
          }
          modelingStyle
        }
        rating {
          overall
          internal
          tier
        }
        location {
          locality
          region
          country
          postalCode
        }
        updateRequired
        createdAt
        updatedAt
        segmentAnonymousId
        segmentDeviceId
        user {
          id
          givenName
          familyName
          email
          birthdate
          gender
          phoneNumber
        }
      }
      ... on Client {
        avatar {
          publicId
        }
        jobsCount
        jobs(limit: 100) {
          items {
            ...ClientJobPostSummaryFragment
          }
          nextToken
        }
        location {
          locality
          region
          country
          postalCode
        }
        financial {
          payment {
            status
          }
        }
        id
        layerId
        name
        user {
          id
          givenName
          familyName
          email
          birthdate
          gender
          phoneNumber
        }
        premiumFeatures
      }
    }
  }
`;
